import React, { useCallback, useEffect } from 'react';
import { IconButton, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { SupportedTheme } from 'src/globals/constants';
import { AppAction, RootReducerState } from 'src/redux';
import { Colors } from 'src/shared';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

export interface Props {}

const LanguageSelector: React.FC<Props> = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useDispatch();
  const selectedTheme = useSelector((state: RootReducerState) => state.app.selectedTheme);
  const borderColor = useColorModeValue(Colors.light.text, Colors.dark.text);

  useEffect(() => {
    if (colorMode !== selectedTheme) {
      toggleColorMode();
    }
  }, [colorMode, selectedTheme, toggleColorMode]);

  const onThemeChange = useCallback(() => {
    toggleColorMode();
    dispatch(
      AppAction.setAppTheme(
        selectedTheme === SupportedTheme.DARK ? SupportedTheme.LIGHT : SupportedTheme.DARK
      )
    );
  }, [selectedTheme, toggleColorMode, dispatch]);

  return (
    <div>
      <IconButton
        onClick={onThemeChange}
        variant="outline"
        colorScheme={borderColor}
        aria-label="change theme"
        icon={selectedTheme === SupportedTheme.LIGHT ? <MoonIcon /> : <SunIcon />}
      />
    </div>
  );
};

export default LanguageSelector;
