import React from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import { EmptyView, Spinner } from 'src/components';
import { ISaleInfoFromMetaMask } from 'src/metamask-provider/type';
import OpenSaleOrderItem from './OpenSaleOrderItem';

interface NFTDetailProps {
  nftType: TNFTType;
  tokenId: number;
  showTitle?: boolean;
  titleStyle?: TextProps;
  isLoading?: boolean;
  listOfOpenSaleOrders: Array<ISaleInfoFromMetaMask>;
}

const OpenSaleOrderList = (props: NFTDetailProps) => {
  const { t } = useTranslation();

  const {
    nftType,
    showTitle = true,
    titleStyle = {},
    listOfOpenSaleOrders = [],
    isLoading = false,
  } = props;

  const titleSectionStyle = { ...styles.titleSection, ...titleStyle };
  const emptyDescription = t('Component:OpenSaleOrderList.EmptyList');

  return (
    <>
      {showTitle && (
        <Text {...titleSectionStyle}>{t('Component:NFTDetail.Section.OpenOrders')}</Text>
      )}
      <Flex flex={{ base: 1 }} flexDirection="column" pos="relative">
        {!isEmpty(listOfOpenSaleOrders) && (
          <Flex flexDirection="column" justifyContent="center">
            {listOfOpenSaleOrders.map((item: ISaleInfoFromMetaMask) => (
              <OpenSaleOrderItem
                key={item.saleId}
                item={item}
                rootPath={coreUtils.getPathByNFTType(nftType)}
              />
            ))}
          </Flex>
        )}
        {!isLoading && isEmpty(listOfOpenSaleOrders) && (
          <EmptyView showImage={false} description={emptyDescription} />
        )}
        {isLoading && <Spinner size="sm" top={2} />}
      </Flex>
    </>
  );
};

export default OpenSaleOrderList;

const styles = {
  titleSection: {
    fontSize: 'lg',
    pt: 4,
    pb: 2,
    fontWeight: 'bold' as 'bold',
  },
};
