import { gql } from '@apollo/client';
import {
  getMarketPriceFragment,
  getUserMetaFragment,
  MARKET_PRICE_KEY,
  USER_META_KEY,
} from '../fragments';
import { TNFTType } from '../types';
import {
  FIXED_PRICE_SALE_ITEM_KEY,
  getFixedPriceSaleItemFragment,
} from '../fragments/fixedPriceSaleItem';

export const GET_FIXED_PRICE_SALE_KEY = 'getFixedPriceSales';
export const GET_FIXED_PRICE_SALE_DETAIL_KEY = 'getFixedPriceSaleDetails';

export const getFixedPriceSalesQuery = (nftType: TNFTType) => {
  return gql`
  ${getUserMetaFragment()}
  ${getFixedPriceSaleItemFragment(nftType)}
  ${getMarketPriceFragment()}
  query GetMiningTools(
    $pagination: PaginationInput!
    $orderBy: GetFixedPriceSalesOrderByInput
    $filter: GetFixedPriceSalesFilterInput
    $status: FixedPriceSaleStatus
    $type: NFTType!
    $tokenId: Int
  ) {
    ${GET_FIXED_PRICE_SALE_KEY}(pagination: $pagination, orderBy: $orderBy, filter: $filter, status: $status, type: $type, tokenId: $tokenId) {
      total
      records {
        id
        tokenId
        status
        amount
        seller {
          ...${USER_META_KEY}
        }
        sellPrice {
          ...${MARKET_PRICE_KEY}
        }
        sellTime
        buyer {
          ...${USER_META_KEY}
        }
        buyPrice {
          ...${MARKET_PRICE_KEY}
        }
        buyTime
        item {
          ...${FIXED_PRICE_SALE_ITEM_KEY}
        }
      }
    }
  }
`;
};

export const getFixedPriceSaleDetailQuery = (nftType: TNFTType) => gql`
  ${getUserMetaFragment()}
  ${getFixedPriceSaleItemFragment(nftType)}
  ${getMarketPriceFragment()}
  query GetFixedPriceSaleDetails(
    $saleId: String!,
    $type: NFTType!
  ) {
    ${GET_FIXED_PRICE_SALE_DETAIL_KEY}(saleId: $saleId, type: $type) {
        id
        tokenId
        status
        amount
        seller {
          ...${USER_META_KEY}
        }
        sellPrice {
          ...${MARKET_PRICE_KEY}
        }
        sellTime
        buyer {
          ...${USER_META_KEY}
        }
        buyPrice {
          ...${MARKET_PRICE_KEY}
        }
        buyTime
        item {
          ...${FIXED_PRICE_SALE_ITEM_KEY}
        }
    }
  }
`;
