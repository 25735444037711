import React, { memo, useEffect, useMemo } from 'react';
import { Box, Heading, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Colors } from 'src/shared';
import { appUtils, coreUtils } from 'src/common';
import { IGeneMetadata, INFT, ISlimeMetadata, TNFTType } from 'src/gql/types';
import { SaleQuantityLabel } from 'src/components';
import { useTranslation } from 'react-i18next';
import useCurrentMinningToolIdsQuery from 'src/hooks/useCurrentMinningToolIdsQuery';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import { NFTType, RarityType, SLIME_FRAME_BY_RARITY } from '../../../globals/constants';
import useNftName from '../../../hooks/useNftName';

export interface NFTItemProps {
  item: INFT;
  nftType: TNFTType;
  rootPath: string;
}

const NFTItem = (props: NFTItemProps) => {
  const { item, nftType, rootPath } = props;
  const { t } = useTranslation();
  const [isMining, setIsMining] = React.useState<boolean>(false);
  const borderColor = useColorModeValue(Colors.light.divider, Colors.dark.divider);
  const bg = useColorModeValue(Colors.light.cardBg, Colors.dark.cardBg);
  const hoverColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const backgroundColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  const nftTitle = useNftName({ item, nftType, includeIndex: true });
  const metaMaskWalletAddress = useCurrentAccount();

  const { data: miningToolsId } = useCurrentMinningToolIdsQuery(metaMaskWalletAddress);

  const isLocking = useMemo(() => Number(item?.onLockAmount) > 0, [item]);

  const nftItemImage = useMemo(() => {
    return coreUtils.getNftImage(
      nftType,
      item.tokenId,
      item.templateId,
      !!(item?.metadata as IGeneMetadata)?.extractedData,
      (item?.metadata as IGeneMetadata)?.extractedData?.variant,
      item.updatedAt
    );
  }, [nftType, item]);

  const slimeBackgroundImage = useMemo(() => {
    if (nftType === NFTType.SLIME && item) {
      const slimeMetadata = item.metadata as ISlimeMetadata;
      return SLIME_FRAME_BY_RARITY[slimeMetadata.faceDetails.rarity as RarityType] || null;
    }
    return null;
  }, [nftType, item]);

  const linkTo = appUtils.getNFTDetailUrl(item.tokenId, rootPath);

  const disableLabelStyle = {
    fontSize: 'xs',
    fontWeight: 700,
    fontFamily: 'body',
    backgroundColor: backgroundColor,
    borderRadius: 'full',
    padding: '4px 7px',
  };

  useEffect(() => {
    if (nftType === NFTType.MINING_TOOL) {
      setIsMining(miningToolsId?.includes(item.tokenId) || false);
    }
  }, [nftType, item.tokenId, miningToolsId]);

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as 'contain',
    borderRadius: nftType === NFTType.LAND ? '4px' : '0px',
    border: nftType === NFTType.LAND ? '1px solid white' : 'none',
    filter: isMining || isLocking ? 'grayscale(100%)' : 'none',
  };
  const boxImageStyle = {
    backgroundImage: nftType === NFTType.SLIME ? `url(${slimeBackgroundImage})` : '',
    backgroundSize: nftType === NFTType.SLIME ? 'cover' : '',
    backgroundColor: nftType === NFTType.SLIME ? 'rgba(0, 0, 0, 0.5)' : '',
    borderRadius: nftType === NFTType.SLIME ? '20%' : '0px',
  };

  return (
    <Box
      maxW={nftType === NFTType.LAND ? '350px' : '260px'}
      w={'full'}
      p={[2, 4]}
      overflow={'hidden'}
    >
      <Link to={{ pathname: linkTo, state: { currentNFT: item } }}>
        <Box
          w={'full'}
          bg={bg}
          p={[2, 4]}
          rounded={'borderItem'}
          overflow={'hidden'}
          border={1}
          borderStyle={'solid'}
          borderColor={borderColor}
          _hover={{
            borderColor: hoverColor,
          }}
        >
          <Box mb={[2, 4]}>
            <Box {...boxImageStyle}>
              <Image {...imageStyle} src={nftItemImage} />
            </Box>
          </Box>

          <Box pt={2}>
            <Stack spacing={0} align={'left'} mb={2}>
              <Heading fontSize={'l'} fontWeight={700} fontFamily={'body'} isTruncated>
                {nftTitle}
              </Heading>
            </Stack>

            <Stack direction="row" justify="flex-end" alignItems="center" spacing={6}>
              {isMining && (
                <Box>
                  <Text {...disableLabelStyle}>{`${t('Common:IsMining')}`}</Text>
                </Box>
              )}
              {isLocking && (
                <Box>
                  <Text {...disableLabelStyle}>{`${t('Common:OnSlimeGame')}`}</Text>
                </Box>
              )}
              <SaleQuantityLabel
                saleQuantity={item?.onSaleAmount}
                totalQuantity={item?.totalAmount}
              />
            </Stack>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default memo(NFTItem);
