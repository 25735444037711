import { TNFTType } from 'src/gql/types';
import * as GQLApi from 'src/gql';
import { QueryKeys } from 'src/globals/constants';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash/isNil';

const useSaleHistoryiesByTokenId = (props: { tokenType: TNFTType; tokenId?: number }) => {
  const saleHistoryiesByTokenIdQuery = useQuery(
    [QueryKeys.myNftDetails, props.tokenType, props.tokenId],
    () => {
      if (isNil(props.tokenId)) {
        throw new Error('tokenId is required');
      }
      return GQLApi.getSaleHistories(props.tokenType, props.tokenId);
    },
    {
      enabled: !isNil(props.tokenId),
    }
  );
  return {
    saleHistoryiesByTokenIdQuery,
  };
};

export default useSaleHistoryiesByTokenId;
