import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { coreUtils } from 'src/common';
import { IFixedPriceSaleNFT, TNFTType } from 'src/gql/types';
import { SEO } from 'src/seo';
import useAvailableFixedPriceSaleNFTsForSale from 'src/hooks/useAvailableFixedPriceSaleNFTsForSale';
import { getEmptyMessageByNFTType } from '../common';
import Listing from './Listing';
import FixedPriceSaleNFTItem from './FixedPriceSaleNFTItem';

interface NFTListProps {
  nftType: TNFTType;
}

const FixedPriceSaleNFTList = (props: NFTListProps) => {
  const { nftType } = props;
  const { t } = useTranslation();

  const { availableFixedPriceSaleNFTsForSaleQuery } = useAvailableFixedPriceSaleNFTsForSale({
    nftType,
  });

  const listOfNFTs = useMemo(() => {
    return availableFixedPriceSaleNFTsForSaleQuery.data?.records || [];
  }, [availableFixedPriceSaleNFTsForSaleQuery.data]);

  const totalOfNFTs = availableFixedPriceSaleNFTsForSaleQuery?.data?.total || 0;
  const isGettingNFTs =
    availableFixedPriceSaleNFTsForSaleQuery.isLoading ||
    availableFixedPriceSaleNFTsForSaleQuery.isFetching;

  return (
    <>
      <SEO title={t(coreUtils.getNFTTypeNameByNftType(nftType))} />
      <Listing
        emptyDescription={t(getEmptyMessageByNFTType(nftType))}
        loading={isGettingNFTs}
        data={listOfNFTs}
        total={totalOfNFTs}
        renderItem={({ item }) => (
          <FixedPriceSaleNFTItem
            key={(item as IFixedPriceSaleNFT).id}
            item={item as IFixedPriceSaleNFT}
            rootPath={coreUtils.getPathByNFTType(nftType)}
          />
        )}
      />
    </>
  );
};

export default FixedPriceSaleNFTList;
