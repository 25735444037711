import React, { memo, useMemo } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { IFixedPriceSaleNFT, INFT, TNFTType } from 'src/gql/types';
import { EmptyView, Spinner } from 'src/components';
import { coreUtils } from 'src/common';
import isEqual from 'lodash/isEqual';
import useSaleHistoryiesByTokenId from 'src/hooks/useSaleHistoryiesByTokenId';
import SaleHistoryItem from './SaleHistoryItem';

interface NFTDetailProps {
  nftType: TNFTType;
  tokenId: number;
  showTitle?: boolean;
  titleStyle?: TextProps;
}

const SaleHistoryList = (props: NFTDetailProps) => {
  const { t } = useTranslation();
  const { nftType, tokenId, showTitle = true, titleStyle = {} } = props;

  const { saleHistoryiesByTokenIdQuery } = useSaleHistoryiesByTokenId({
    tokenType: nftType,
    tokenId,
  });

  const listOfSaleHistories = useMemo(
    () => saleHistoryiesByTokenIdQuery?.data?.records || [],
    [saleHistoryiesByTokenIdQuery?.data?.records]
  );

  const isGettingSaleHistories = saleHistoryiesByTokenIdQuery.isLoading;

  const titleSectionStyle = { ...styles.titleSection, ...titleStyle };
  const emptyDescription = t('Component:SaleHistoryList.EmptyList');
  return (
    <>
      {showTitle && (
        <Text {...titleSectionStyle}>{t('Component:NFTDetail.Section.SaleHistories')}</Text>
      )}
      <Flex flex={{ base: 1 }} flexDirection={'column'} pos={'relative'}>
        {!isEmpty(listOfSaleHistories) && (
          <Flex direction={'row'} flexWrap={'wrap'} justifyContent={'center'}>
            {listOfSaleHistories.map((item: INFT | IFixedPriceSaleNFT) => (
              <SaleHistoryItem
                key={(item as IFixedPriceSaleNFT).id}
                item={item as IFixedPriceSaleNFT}
                rootPath={coreUtils.getPathByNFTType(nftType)}
              />
            ))}
          </Flex>
        )}
        {!isGettingSaleHistories && isEmpty(listOfSaleHistories) && (
          <EmptyView showImage={false} description={emptyDescription} />
        )}
        {isGettingSaleHistories && <Spinner size="sm" top={2} />}
      </Flex>
    </>
  );
};

const compareProps = (prevProps: NFTDetailProps, nextProps: NFTDetailProps) => {
  // if this function returns true, this component does not need to re-render
  return !(
    !isEqual(prevProps.nftType, nextProps.nftType) ||
    !isEqual(prevProps.tokenId, nextProps.tokenId) ||
    !isEqual(prevProps.showTitle, nextProps.showTitle) ||
    !isEqual(prevProps.titleStyle, nextProps.titleStyle)
  );
};

export default memo(SaleHistoryList, compareProps);

const styles = {
  titleSection: {
    fontSize: 'lg',
    pt: 4,
    pb: 2,
    fontWeight: 'bold' as 'bold',
  },
};
