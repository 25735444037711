import { gql } from '@apollo/client';

export const getMarketStatistics = () => {
  return gql`
    query GetMarketStatistics($timeFrame: TimeFrame!) {
      getMarketStatistics(timeFrame: $timeFrame) {
        totalSale
        totalVolume {
          btc
          origin
          originalCurrency
          usdt
          bnb
          eth
        }
        totalTransaction
      }
    }
  `;
};
