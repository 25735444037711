import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from '@chakra-ui/react';
import { Colors } from 'src/shared';
import { Spinner } from 'src/components';
import useMarketplaceSummaries from 'src/hooks/useMarketplaceSummaries';
import DashboardOverviewItem from './DashboardOverviewItem';

interface DashboardOverviewProps {}

const DashboardOverview = (props: DashboardOverviewProps) => {
  const { t } = useTranslation();

  const { marketplaceSummariesQuery } = useMarketplaceSummaries();

  const marketplaceSummaries = useMemo(
    () => marketplaceSummariesQuery.data || null,
    [marketplaceSummariesQuery.data]
  );

  const isGettingMarketplaceSummaries =
    marketplaceSummariesQuery.isLoading || marketplaceSummariesQuery.isFetching;

  const descriptionColor = useColorModeValue(Colors.dark.description, Colors.dark.description);
  const primaryColor = useColorModeValue(Colors.dark.primary, Colors.dark.primary);
  const borderColor = useColorModeValue(Colors.dark.divider, Colors.dark.divider);
  const tabStyle = {
    ...styles.tab,
    color: descriptionColor,
    _selected: {
      color: primaryColor,
      bg: 'transparent',
      borderBottomColor: primaryColor,
    },
  };
  if (isGettingMarketplaceSummaries)
    return (
      <Flex {...styles.container} justifyContent="center" alignItems="center">
        <Spinner size="md" top={2} />
      </Flex>
    );
  return (
    <Tabs
      {...styles.container}
      borderRadius="lg"
      borderWidth={1}
      borderStyle="solid"
      borderColor={borderColor}
    >
      <TabList overflowX="auto" overflowY="hidden">
        {!!marketplaceSummaries?.last24Hours && (
          <Tab {...tabStyle}>{t('Component:Overview.Tabs.Last24Hours')}</Tab>
        )}
        {!!marketplaceSummaries?.last7Days && (
          <Tab {...tabStyle}>{t('Component:Overview.Tabs.Last7days')}</Tab>
        )}
        {!!marketplaceSummaries?.last30Days && (
          <Tab {...tabStyle}>{t('Component:Overview.Tabs.Last30days')}</Tab>
        )}
      </TabList>

      <TabPanels>
        {!!marketplaceSummaries?.last24Hours && (
          <TabPanel>
            <DashboardOverviewItem data={marketplaceSummaries?.last24Hours} />
          </TabPanel>
        )}
        {!!marketplaceSummaries?.last7Days && (
          <TabPanel>
            <DashboardOverviewItem data={marketplaceSummaries?.last7Days} />
          </TabPanel>
        )}
        {!!marketplaceSummaries?.last30Days && (
          <TabPanel>
            <DashboardOverviewItem data={marketplaceSummaries?.last30Days} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default memo(DashboardOverview);

const styles = {
  container: {
    marginTop: 4,
    w: 'full',
    minHeight: '150px',
    position: 'relative' as 'relative',
  },
  tab: {
    minWidth: '120px',
    p: 2,
    minHeight: '50px',
    fontWeight: 700,
    fontSize: 'md',
    borderBottom: '4px solid',
    _focus: {
      boxShadow: 'none' as 'none',
    },
  },
};
