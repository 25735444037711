import { useCallback, useState } from 'react';
import { ISaleInfoFromMetaMask, TokenFormat } from 'src/metamask-provider/type';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import * as marketService from 'src/services/market';
import useCurrentAccount from 'src/hooks/useWalletAddress';

const useGetFixedPriceSaleInfoByTokenId = (nftType: TNFTType) => {
  const [isGettingSaleInfo, setIsGettingSaleInfo] = useState<boolean | undefined>();
  const [saleInfo, setSaleInfo] = useState<
    ISaleInfoFromMetaMask | ISaleInfoFromMetaMask[] | undefined
  >();
  const currentAccount = useCurrentAccount();
  const [currentTokenId, setCurrentTokenId] = useState<number | undefined>();
  const getFixedPriceSaleInfoByTokenId = useCallback(
    (tokenId?: number) => {
      setCurrentTokenId(tokenId);
      if (tokenId) {
        setIsGettingSaleInfo(true);
        const tokenFormat = coreUtils.getTokenFormatByNftType(nftType);
        const loadSaleInfo = async () => {
          if (tokenFormat === TokenFormat.ERC721) {
            return marketService.getFixedPriceSaleInfoByTokenId721(
              coreUtils.getMarketContractByNftType(nftType),
              tokenId
            );
          }
          if (tokenFormat === TokenFormat.ERC1155 && currentAccount) {
            return marketService.getFixedPriceSaleInfoByTokenId1155(
              coreUtils.getMarketContractByNftType(nftType),
              tokenId,
              currentAccount
            );
          }
        };
        loadSaleInfo()
          .then((saleInfo) => {
            return setSaleInfo(saleInfo);
          })
          .catch(console.error)
          .finally(() => setIsGettingSaleInfo(false));
      } else {
        setSaleInfo(undefined);
      }
    },
    [currentAccount, nftType]
  );

  return [isGettingSaleInfo, currentTokenId ? saleInfo : undefined, getFixedPriceSaleInfoByTokenId];
};

export default useGetFixedPriceSaleInfoByTokenId;
