import { Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ProfileLayout } from 'src/layouts';
import { UserActivityList } from 'src/features/userActivity';

const UserActivity = () => {
  const { t } = useTranslation();

  return (
    <ProfileLayout>
      <Flex flex={{ base: 1 }} p={{ base: 2, md: 4 }} flexDirection={'column'}>
        <Heading as="h1" color="heading">
          {t('Term:UserActivity')}
        </Heading>
        <UserActivityList />
      </Flex>
    </ProfileLayout>
  );
};

export default UserActivity;
