import apolloClient from '../apolloClient';
import { IGetUserActivitiesResult, IGetUserActivitiesVariable } from './types';
import { GetUserActivitiesQuery } from './queries';

export const getUserActivities = async (variables: IGetUserActivitiesVariable) => {
  try {
    const response = await apolloClient.query({
      query: GetUserActivitiesQuery,
      variables,
    });
    const result: IGetUserActivitiesResult = response?.data?.getUserActivities || {
      records: [],
      total: 0,
    };
    return result;
  } catch (error) {
    throw error;
  }
};
