import React from 'react';
import { BoxProps, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { Colors } from 'src/shared';
import { appUtils } from 'src/common';

export interface PriceData {
  priceCurrency: string | undefined;
  price: string;
}

interface FixedPriceSaleLabelProps extends BoxProps {
  data: PriceData;
}

const FixedPriceSaleLabel = (props: FixedPriceSaleLabelProps) => {
  const { data, ...remainingProps } = props;
  const priceColor = useColorModeValue(Colors.dark.price, Colors.dark.price);
  const priceText = appUtils.displayPriceWithCurrencyFromNumberWithDecimal(
    data.price,
    data.priceCurrency
  );
  const priceHint = appUtils.displayPriceWithCurrencyFromNumberWithDecimal(
    data.price,
    data.priceCurrency,
    0
  );
  return (
    <Tooltip label={priceHint} hasArrow>
      <Text color={priceColor} fontWeight={700} {...remainingProps}>
        {priceText || ' '}
      </Text>
    </Tooltip>
  );
};

export default FixedPriceSaleLabel;
