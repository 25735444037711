import React from 'react';
import { Flex, HStack, Text, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import { IMarketPrice } from 'src/gql/types';
import { FixedPriceSaleLabel } from 'src/components';
import { appUtils } from 'src/common';
import * as common from '../common';
import InfoItem from './InfoItem';

interface FixedPriceSaleNFTSaleInfoProps extends FlexProps {
  priceInfo: IMarketPrice;
  quantity?: string;
  salePrice?: string;
}

const FixedPriceSaleNFTSaleInfo = (props: FixedPriceSaleNFTSaleInfoProps) => {
  const { t } = useTranslation();
  const { quantity, priceInfo, salePrice, ...remainingProps } = props;
  const { priceCurrency, price } = common.getCurrentPrice(priceInfo, salePrice);
  const bgColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  const textStyle = { as: 'span' as 'span', color: primaryColor, fontWeight: 700, fontSize: '3xl' };
  const fixedPrice = common.getFixedPrice(price, quantity);

  const fixedPriceText = appUtils.displayPriceWithCurrency(fixedPrice, priceInfo?.originalCurrency);
  return (
    <Flex
      flexDirection="column"
      alignItems="inherit"
      borderRadius={8}
      p={4}
      bg={bgColor}
      {...remainingProps}
    >
      <HStack pb={2}>
        <InfoItem title={t('Component:FixedPriceSaleNFTSaleInfo.CurrentPrice')}>
          <FixedPriceSaleLabel
            fontSize="3xl"
            data={{
              price,
              priceCurrency,
            }}
          />
        </InfoItem>
        <InfoItem title={t('Component:FixedPriceSaleNFTSaleInfo.Quantity')}>
          <Text {...textStyle}>{quantity}</Text>
        </InfoItem>
      </HStack>
      <Text>
        {t('Component:FixedPriceSaleNFTSaleInfo.FixedPrice', { fixedPrice: fixedPriceText })}
      </Text>
    </Flex>
  );
};

export default React.memo(FixedPriceSaleNFTSaleInfo);
