import { useCallback, useState } from 'react';
import { sleep } from 'src/common/utils';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import * as marketService from 'src/services/market';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';

const useUpdateNFTPriceOnFixedPriceSaleMarket = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [isUpdateNFTPriceOnFixedPriceSaleMarket, setIsUpdateNFTPriceOnFixedPriceSaleMarket] =
    useState<boolean>(false);
  const queryClient = useQueryClient();
  const onUpdateNFTPriceOnFixedPriceSaleMarket = useCallback(
    (saleId: string, newPrice: string, onUpdatePriceSuccess?: (txid: string) => void) => {
      setError(undefined);
      setIsUpdateNFTPriceOnFixedPriceSaleMarket(true);
      marketService
        .updateSalePriceOfNFTFromFixedSaleMarket(
          coreUtils.getMarketContractByNftType(nftType),
          saleId,
          newPrice
        )
        .then(async (hash) => {
          await sleep(5000);
          if (onUpdatePriceSuccess) {
            onUpdatePriceSuccess(hash);
          }
          queryClient.invalidateQueries([QueryKeys.availableNftForSale]);
          queryClient.invalidateQueries([QueryKeys.currentSellingNft]);
        }) // For delay confirmation
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsUpdateNFTPriceOnFixedPriceSaleMarket(false);
        });
    },
    [nftType, setIsUpdateNFTPriceOnFixedPriceSaleMarket]
  );

  return [isUpdateNFTPriceOnFixedPriceSaleMarket, onUpdateNFTPriceOnFixedPriceSaleMarket, error];
};

export default useUpdateNFTPriceOnFixedPriceSaleMarket;
