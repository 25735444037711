import React, { useCallback, useEffect } from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AppAction, RootReducerState } from 'src/redux';
import FilterButton from 'src/components/FilterButton';

const ToggleFilterButton = (props: ButtonProps) => {
  const { children, ...remainingProps } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AppAction.toggleInventoryFilter(false));
  }, [dispatch]);
  const inventoryFilterVisible = useSelector(
    (state: RootReducerState) => state.app.inventoryFilterVisible
  );
  const onToggle = useCallback(() => {
    dispatch(AppAction.toggleInventoryFilter(!inventoryFilterVisible));
  }, [inventoryFilterVisible, dispatch]);
  return <FilterButton {...remainingProps} onClick={onToggle} isActive={inventoryFilterVisible} />;
};

export default ToggleFilterButton;
