import { useCallback, useState } from 'react';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import BN from 'bn.js';
import { useTranslation } from 'react-i18next';
import * as marketService from 'src/services/market';
import useFTCBalanceQuery from 'src/hooks/useFTCBalanceQuery';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';

const useBuyNFTFromFixedPriceSaleMarket = (nftType: TNFTType, currentPrice?: string) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const [isBuyingNFTFromFixedPriceSaleMarket, setIsBuyingNFTFromFixedPriceSaleMarket] = useState<
    boolean | undefined
  >();
  const ftcBalance = useFTCBalanceQuery();
  const queryClient = useQueryClient();

  const onBuyNFTFromFixedPriceSaleMarket = useCallback(
    async (saleId: string, onBuySuccess?: (txid: string) => void) => {
      try {
        setError(undefined);
        setIsBuyingNFTFromFixedPriceSaleMarket(true);
        //Check enough ftc to process buy
        if (currentPrice && ftcBalance.data) {
          const ftcBalanceBN = ftcBalance.data;
          const currentPriceBN = new BN(currentPrice);
          if (currentPriceBN.gt(ftcBalanceBN)) {
            // Move setError to the next tick
            setTimeout(() => setError(t('Component:BuyButton:NotEnoughFTCMessage')));
            return;
          }
        }
        const hash = await marketService.buyNFTFromFixedSaleMarket(
          coreUtils.getMarketContractByNftType(nftType),
          saleId
        );
        if (onBuySuccess) {
          onBuySuccess(hash);
        }
        queryClient.invalidateQueries([QueryKeys.availableNftForSale]);
        queryClient.invalidateQueries([QueryKeys.myNfts]);
        queryClient.invalidateQueries([QueryKeys.myNftDetails]);
        queryClient.invalidateQueries([QueryKeys.userActivities]);
      } catch (error: any) {
        setError(error.message);
        console.error(error);
      } finally {
        setIsBuyingNFTFromFixedPriceSaleMarket(false);
      }
    },
    [currentPrice, ftcBalance.data, nftType, queryClient, t]
  );

  return [isBuyingNFTFromFixedPriceSaleMarket, onBuyNFTFromFixedPriceSaleMarket, error];
};

export default useBuyNFTFromFixedPriceSaleMarket;
