export const styles = {
  column: {
    p: {
      base: 2,
      md: 4,
    },
    minWidth: {
      base: '100px',
    },
  },
};
