import React, { ChangeEvent, useCallback } from 'react';
import { Switch, useColorModeValue, Text, Center, HStack, Spinner } from '@chakra-ui/react';
import { Colors } from 'src/shared';

interface ApprovalSettingsStatusProps {
  isLoading?: boolean;
  title: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

const ApprovalSettingsStatus = ({
  isLoading,
  title,
  checked,
  onChange,
}: ApprovalSettingsStatusProps) => {
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);

  const onChangeChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event.target.checked);
    },
    [onChange]
  );

  return (
    <HStack ml={5} mt={8}>
      <Center minWidth={50}>
        {!isLoading && <Switch isChecked={checked} onChange={onChangeChecked} />}
        {isLoading && <Spinner size="sm" />}
      </Center>
      <Text color={textColor}>{title}</Text>
    </HStack>
  );
};
export default ApprovalSettingsStatus;
