import { Box, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import OutlineButton from 'src/components/Button/OutlineButton';
import { Colors } from 'src/shared';

const NotFound = () => {
  const { t } = useTranslation();
  const color = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  return (
    <Flex height="calc(100vh - 75px)" alignItems="center" justifyContent="center">
      <Box textAlign="center" py={12}>
        <Heading as="h1" size="2xl" mb={4}>
          {t('Component:NotFound:Title')}
        </Heading>
        <Text fontSize="xl" mb={8}>
          {t('Component:NotFound:Description')}
        </Text>
        <OutlineButton as={Link} to="/" color={color} size="lg">
          {t('GoHomeBtn')}
        </OutlineButton>
      </Box>
    </Flex>
  );
};

export default NotFound;
