import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAvailableFixedPriceSaleNFTsForSale from 'src/hooks/useAvailableFixedPriceSaleNFTsForSale';
import {
  DefaultLimit,
  GeneSaleSortTypes,
  LandSaleSortTypes,
  MiningToolSaleSortTypes,
  NFTType,
  SlimeSaleSortTypes,
  StandardSaleSortTypes,
} from '../globals/constants';
import Colors from '../shared/foundations/colors';
import { SortingSelector } from '../features/sorting';
import { Pagination } from './Pagination';

interface MarketHeaderProps {
  nftType: NFTType;
}

const MarketHeader = (props: MarketHeaderProps) => {
  const { nftType } = props;
  const { t } = useTranslation();
  const [totalOfNfts, setTotalOfNfts] = React.useState(0);
  const [totalOfPages, setTotalOfPages] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [sortType, setSortType] = React.useState(StandardSaleSortTypes);

  const defaultBg = useColorModeValue(Colors.light.secondaryBg, Colors.dark.secondaryBg);

  const { availableFixedPriceSaleNFTsForSaleQuery } = useAvailableFixedPriceSaleNFTsForSale({
    nftType,
  });

  const totalNft = availableFixedPriceSaleNFTsForSaleQuery.data?.total || 0;

  useEffect(() => {
    setTotalOfNfts(totalNft);
    setTotalOfPages(Math.ceil(totalNft / DefaultLimit));
    switch (nftType) {
      case NFTType.MINING_TOOL:
        setTitle(t('Term:MiningTool'));
        setSortType(MiningToolSaleSortTypes);
        break;
      case NFTType.TOOL_MATERIAL:
        setTitle(t('Term:ToolMaterial'));
        setSortType(StandardSaleSortTypes);
        break;
      case NFTType.LAND:
        setTitle(t('Term:Land'));
        setSortType(LandSaleSortTypes);
        break;
      case NFTType.GACHA:
        setTitle(t('Term:Gacha'));
        setSortType(StandardSaleSortTypes);
        break;
      case NFTType.GENE:
        setTitle(t('Term:Gene'));
        setSortType(GeneSaleSortTypes);
        break;
      case NFTType.SLIME:
        setTitle(t('Term:Slime'));
        setSortType(SlimeSaleSortTypes);
        break;
    }
  }, [nftType, t, totalNft]);

  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      justifyContent={{ base: 'center', md: 'space-between' }}
      alignItems="center"
      position="sticky"
      zIndex={8}
      background={defaultBg}
      padding={{ base: 5, lg: 0 }}
      top={{ base: 140, lg: 126.5 }}
    >
      <Box as="span">
        <Heading as="h1" color="heading" flex={1} order={{ base: 1, md: 0 }}>
          {t(title)} ({totalOfNfts.toLocaleString('en')})
        </Heading>
      </Box>
      <Pagination pageTotal={totalOfPages} />
      <Flex flexWrap="wrap" order={{ base: 0, md: 1 }}>
        <SortingSelector marginRight={2} marginBottom={2} options={sortType} />
      </Flex>
    </Flex>
  );
};

export default MarketHeader;
