import { useCallback } from 'react';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import useApprovalSetting from 'src/features/approvalSettings/hooks/useApprovalSetting';

const useApproveFTCTokenToFixedPriceSaleMarket = (nftType: TNFTType) => {
  const currentAccount = useCurrentAccount();

  const { tokenApprovalKey } = coreUtils.getPairOfContractsByNftType(nftType);
  const ftcToNftMarket = useApprovalSetting(tokenApprovalKey, currentAccount);

  const isGettingApprovedFTCTokenStatus = ftcToNftMarket.approvalStatus.isLoading;
  const isApprovingFTCToken = ftcToNftMarket.approveMutation.isLoading;
  const isApprovedFTCTokenForSale = ftcToNftMarket.approvalStatus.data;
  const error =
    ftcToNftMarket.approvalStatus.error?.toString() ||
    ftcToNftMarket.approveMutation.error?.toString();

  // Only call approve if the tokenId is set and the token is not approved
  const onApprovedFTCTokenToFixedPriceSale = useCallback(() => {
    if (currentAccount) {
      ftcToNftMarket.approveMutation.mutate(true);
    }
  }, [currentAccount, ftcToNftMarket.approveMutation]);

  return [
    onApprovedFTCTokenToFixedPriceSale,
    isGettingApprovedFTCTokenStatus,
    isApprovingFTCToken,
    isApprovedFTCTokenForSale,
    error,
  ];
};

export default useApproveFTCTokenToFixedPriceSaleMarket;
