import React, { useCallback } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getConfig } from 'src/globals/env-config';
import { AppSizes, Colors } from 'src/shared';
import { INFT } from '../../../gql/types';

interface IUpdatePriceModalProps {
  currentNFT?: INFT | null;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: (price: string) => void;
  defaultPrice?: string;
  defaultAmount?: string;
}

const UpdatePriceModal = ({
  currentNFT,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  defaultPrice = '',
  defaultAmount = '',
}: IUpdatePriceModalProps) => {
  const { t } = useTranslation();

  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );

  const defaultBg = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  const onSubmit = useCallback(
    (data: any) => {
      const price = data.priceInput;
      if (price) {
        onConfirm(price);
      }
    },
    [onConfirm]
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ priceInput: string; amountInput: string }>();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ModalContent backgroundColor={modalBackgroundColor}>
          <ModalHeader textAlign={'center'}>
            {t('Component:UpdateSellPriceButton.UpdatePrice')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody as={VStack} pb={6} spacing={4}>
            <FormControl>
              <FormLabel>{t('Component:PlaceOrderModal.Input.Token')}:</FormLabel>
              <Input
                placeholder={t('Component:PlaceOrderModal.Holder.Token')}
                value={(currentNFT?.metadata as any)?.name}
                disabled
              />
            </FormControl>
            <HStack spacing={4} alignItems="flex-start">
              <FormControl isInvalid={!!errors.priceInput} flex={0.6}>
                <FormLabel htmlFor="priceInput">
                  {t('Component:PlaceOrderModal.Input.Price')}:
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon children="FTC" />
                  <Input
                    defaultValue={defaultPrice}
                    disabled={isLoading}
                    placeholder={t('Component:PlaceOrderModal.Holder.Price')}
                    type="number"
                    {...register('priceInput', {
                      required: {
                        value: true,
                        message: t('Component:PlaceOrderModal.Error.RequiredPrice'),
                      },
                      min: {
                        value: getConfig('REACT_APP_MIN_PRICE_SUPPORTED'),
                        message: t('Component:PlaceOrderModal.Error.MinPrice', {
                          minPrice: getConfig('REACT_APP_MIN_PRICE_SUPPORTED'),
                        }),
                      },
                      max: {
                        value: getConfig('REACT_APP_MAX_PRICE_SUPPORTED'),
                        message: t('Component:PlaceOrderModal.Error.MaxPrice', {
                          maxPrice: getConfig('REACT_APP_MAX_PRICE_SUPPORTED'),
                        }),
                      },
                    })}
                  />
                </InputGroup>
                {errors.priceInput && (
                  <FormErrorMessage>{errors.priceInput.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl flex={0.4}>
                <FormLabel htmlFor="amountInput">
                  {t('Component:PlaceOrderModal.Input.Amount')}:
                </FormLabel>
                <Input
                  defaultValue={defaultAmount}
                  disabled
                  placeholder={t('Component:PlaceOrderModal.Holder.Amount')}
                  type="number"
                />
              </FormControl>
            </HStack>
          </ModalBody>

          <ModalFooter mx={'auto'}>
            <Button
              mr={3}
              type="submit"
              isLoading={isLoading}
              borderRadius={'full'}
              bg={defaultBg}
              minWidth={AppSizes.ButtonMinWidth}
            >
              {t('Component:PlaceOrderModal.Button.Confirm')}
            </Button>
            <Button borderRadius={'full'} minWidth={AppSizes.ButtonMinWidth} onClick={onClose}>
              {t('Component:PlaceOrderModal.Button.Cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default UpdatePriceModal;
