import BN from 'bn.js';
import Web3 from 'web3';

export const METAMASK_NETWORK: Record<
  string,
  {
    chainId: string;
    chainHex: string;
    rpcUrl: string;
    name: string;
    currencyName: string;
    currencySymbol: string;
    explorerUrl: string;
  }
> = {
  test: {
    chainId: '80002',
    chainHex: '0x13882',
    rpcUrl: 'http://34.87.96.214:7545',
    name: 'Self-host ganache',
    currencyName: 'T BNB',
    currencySymbol: 'tBNB',
    explorerUrl: '',
  },
  development: {
    chainId: '80001',
    chainHex: '0x13881',
    rpcUrl: 'https://endpoints.omniatech.io/v1/matic/mumbai/public',
    name: 'Polygon Testnet Mumbai',
    currencyName: 'Polygon Testnet Mumbai',
    currencySymbol: 'tMATIC',
    explorerUrl: 'https://mumbai.polygonscan.com/',
  },
  uat: {
    chainId: '97',
    chainHex: '0x61',
    rpcUrl: 'https://data-seed-prebsc-1-s3.binance.org:8545',
    name: 'Binance Smart Chain Testnet',
    currencyName: 'Binance Testnet',
    currencySymbol: 'tBNB',
    explorerUrl: 'https://testnet.bscscan.com/',
  },
  production: {
    chainId: '56',
    chainHex: '0x38',
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    name: 'Binance Smart Chain Mainnet',
    currencyName: 'Binance',
    currencySymbol: 'BNB',
    explorerUrl: 'https://bscscan.com',
  },
};

export const MAX_TOKEN_SUPPLY = Web3.utils.toWei(new BN('1000000000'), 'ether');
export const HALF_MAX_TOKEN_SUPPLY = MAX_TOKEN_SUPPLY.div(new BN('2'));
