import { gql } from '@apollo/client';
import { TNFTType } from '../types';
import { getNFTFragment, NFT_KEY } from '../fragments';

export const GET_MY_NFTS_KEY = 'getMyNFTs';
export const GET_MY_NFT_DETAIL_KEY = 'getMyNFTDetail';

export const getMyNFTsQuery = (nftType: TNFTType) => {
  return gql`
  ${getNFTFragment(nftType)}
  query GetMyNFTs(
    $pagination: PaginationInput!
    $orderBy: GetMyNFTsOrderByInput
    $filter: GetMyNFTsFilterInput
    $type: NFTType!
    $owner: String!
  ) {
    ${GET_MY_NFTS_KEY}(pagination: $pagination, orderBy: $orderBy, filter: $filter, type: $type, owner: $owner) {
      total
      records {
        ...${NFT_KEY}
      }
    }
  }
`;
};

export const getMyNFTDetailQuery = (nftType: TNFTType) => gql`
  ${getNFTFragment(nftType)}
  query GetMyNFTDetail(
    $tokenId: Int!
    $owner: String!
    $type: NFTType!
  ) {
    ${GET_MY_NFT_DETAIL_KEY}(tokenId: $tokenId, owner: $owner, type: $type) {
      ...${NFT_KEY}
    }
  }
`;
