import React, { SVGProps } from 'react';

const LuckIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color = '#00AB37', ...remainingProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...remainingProps}>
      <path
        d="M33.45 20.15a22 22 0 0 0-3-1 23.49 23.49 0 0 0 3.12-.43c8.18-1.71 5-10 .26-8.38 2.51-4.19-5.13-9.12-8.3-1.21a22.31 22.31 0 0 0-1 3 23.58 23.58 0 0 0-.43-3.11c-1.71-8.18-10-5-8.38-.26-4.19-2.51-9.12 5.13-1.21 8.3a22 22 0 0 0 3 1 23.49 23.49 0 0 0-3.12.43c-8.18 1.71-5 10-.26 8.38-2.38 4 4.34 8.59 7.76 2.36-.36 1.07-.74 2.12-1.17 3.17s-.92 2.18-1.39 3.26-1 2.14-1.54 3.21L16.17 42s-.93 1.49-.18 1.86c1.11.62 1.74-1.06 1.74-1.06l1.52-3.27c.46-1.12.94-2.22 1.39-3.34s.87-2.25 1.23-3.4a51.72 51.72 0 0 0 1.71-6.23c.07.53.16 1.07.28 1.63 1.71 8.18 10 5 8.38.26 4.19 2.55 9.12-5.13 1.21-8.3Z"
        fill={color}
      />
    </svg>
  );
};

export default LuckIcon;
