import { createRef, useEffect, useRef, useState } from 'react';
import useWalletInfoQuery from './useWalletInfoQuery';

const autoLoginCheckedRef = createRef<boolean>();

const useAuth = () => {
  const refOfIsCheckingConnectedWallet = useRef(false);
  const [autoLoginChecked, setAutoLoginChecked] = useState(autoLoginCheckedRef.current);

  const {
    isValidConnected: signedIn,
    isCheckingConnectedWallet,
    isWalletConnected,
    isValidChainId,
  } = useWalletInfoQuery();

  useEffect(() => {
    if (!isCheckingConnectedWallet && refOfIsCheckingConnectedWallet.current) {
      // @ts-ignore
      autoLoginCheckedRef.current = true;
      setAutoLoginChecked(true);
    }
    refOfIsCheckingConnectedWallet.current = isCheckingConnectedWallet;
  }, [isCheckingConnectedWallet]);

  return [signedIn, autoLoginChecked, isWalletConnected, isValidChainId];
};

export default useAuth;
