import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import useAccountInformation from '../hooks/useAccountInformation';
import useBindToAccountByWallet from '../hooks/useBindToAccountByWallet';
import { BindingType } from '../../../types/AccountServiceClient';
import BaseFieldSetting from './BaseFieldSetting';

interface EmailSettingProps {}

const EmailSetting = (props: EmailSettingProps) => {
  const { t } = useTranslation();
  const { isAuthenticated, accountInfo } = useAccountInformation();
  const metaMaskWalletAddress = useCurrentAccount();
  const { isBinding, bind } = useBindToAccountByWallet(BindingType.email, metaMaskWalletAddress);

  const onConfirmChange = useCallback(
    async (value: string) => {
      await bind({ email: value });
    },
    [bind]
  );

  return (
    <BaseFieldSetting
      type="email"
      disabled={!isAuthenticated}
      label={t('Common:Email')}
      currentValue={accountInfo?.email || ''}
      onConfirmChange={onConfirmChange}
      placeholder={t('Component:AccountSettings.EmailPlaceholder')}
      loading={isBinding}
    />
  );
};

export default EmailSetting;
