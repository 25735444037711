import { gql } from '@apollo/client';

export const LAND_METADATA_KEY = 'landMetadata';
export const getLandMetadataFragment = (key = LAND_METADATA_KEY) => gql`
  fragment ${key} on LandMetadata {
    templateId
    name
    supply
    weight
    cd
    mp
    luck
  }
`;
