import React from 'react';
import { ProfileLayout } from 'src/layouts';
import { Redirect } from 'react-router-dom';
import { RoutePath } from 'src/globals/constants';
const Profile = () => {
  return (
    <ProfileLayout>
      <Redirect to={RoutePath.INVENTORY_SLIME} />
    </ProfileLayout>
  );
};

export default Profile;
