import { Box, HStack, VStack, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import { SlimeInfoMetadata } from 'src/common/slimeStatCalculation';
import InfoItem from '../InfoItem';
import { GeneClassLabel, RarityLabel } from '../../../../components';
import { ISlimeMetadata } from '../../../../gql/types';

interface StatPropsType {
  metadata: ISlimeMetadata;
}

const Attributes = ({ metadata }: StatPropsType) => {
  const { t } = useTranslation();

  const evenBackground = useColorModeValue(Colors.light.evenRow, Colors.dark.evenRow);
  const oddBackground = useColorModeValue(Colors.light.oddRow, Colors.dark.oddRow);

  const fullStatGroup = useMemo(() => {
    return new SlimeInfoMetadata(metadata).fullStatGroup;
  }, [metadata]);

  const attributes = useMemo(() => {
    return [
      {
        label: 'HP',
        value: fullStatGroup.hp.points.toFixed(1),
      },
      {
        label: 'ATK',
        value: fullStatGroup.atk.points.toFixed(1),
      },
      {
        label: 'DEF',
        value: fullStatGroup.def.points.toFixed(1),
      },
      {
        label: 'Skill Damage Reduction',
        value: fullStatGroup.skillDmgReduction.points.toFixed(1) + '%',
      },
      { label: 'Critical Rate', value: fullStatGroup.crit.points.toFixed(1) + '%' },
      {
        label: 'Critical Dmg',
        value: fullStatGroup.critdmg.points.toFixed(1) + '%',
      },
      {
        label: 'Block Rate',
        value: fullStatGroup.block.points.toFixed(1) + '%',
      },
      {
        label: 'Evasion',
        value: fullStatGroup.evade.points.toFixed(1) + '%',
      },
    ];
  }, [fullStatGroup]);

  return (
    <VStack alignItems="start">
      <HStack alignItems={'inherit'}>
        <InfoItem title={t('Term:Rarity')}>
          <RarityLabel rarity={metadata.faceDetails.rarity} />
        </InfoItem>
        <InfoItem title={t('Term:GeneClass')}>
          <GeneClassLabel geneClass={metadata.rightArmDetails.class} />
        </InfoItem>
      </HStack>
      <Box w="100%" style={{ marginTop: 20 }}>
        <Box p={2} shadow="md" borderWidth="1px" rounded={5} width="100%">
          {attributes.map((attribute, index) => (
            <Box
              key={attribute.label}
              borderWidth="1px"
              rounded={5}
              p={3}
              mt={index !== 0 ? 2 : 0}
              backgroundColor={index % 2 !== 0 ? oddBackground : evenBackground}
            >
              <HStack key={attribute.label} justifyContent="space-between">
                <Text>{attribute.label}</Text>
                <Text>{attribute.value}</Text>
              </HStack>
            </Box>
          ))}
        </Box>
      </Box>
    </VStack>
  );
};

export default Attributes;
