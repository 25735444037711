import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { QueryKeys } from 'src/globals/constants';
import * as wagmiAuthService from 'src/services/wagmiAuth';

const useDisconnectWagmi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const doDisconnectWagmi = async () => {
    setIsLoading(true);
    try {
      await wagmiAuthService.disconnectedWagmi();
      queryClient.invalidateQueries([QueryKeys.wagmiAccountQueryKey]);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, doDisconnectWagmi };
};

export default useDisconnectWagmi;
