import { useCallback, useState } from 'react';
import { ICustomerInformationInput } from 'src/gql/types';
import * as GQLApi from 'src/gql';

const useUpdateCustomerInfo = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [hasUpdatedCustomerInfo, setHasUpdatedCustomerInfo] = useState<boolean>(false);
  const updateCustomerInfo = useCallback(
    async (walletAddress: string, customerInfo: ICustomerInformationInput) => {
      setIsUpdating(true);
      try {
        await GQLApi.updateCustomer({ walletAddress, customerInfo });
        setHasUpdatedCustomerInfo(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsUpdating(false);
      }
    },
    []
  );
  return [hasUpdatedCustomerInfo, isUpdating, updateCustomerInfo];
};

export default useUpdateCustomerInfo;
