import React from 'react';
import { Grid, GridItem, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import { approvalRelation } from 'src/globals/constants';
import useIsMobile from '../../../hooks/useIsMobile';
import useApprovalSetting from '../hooks/useApprovalSetting';
import ApprovalSettingsSection from './ApprovalSettingsSection';
import ApprovalSettingsStatus from './ApprovalSettingsStatus';

interface ApprovalSettingsPanelProps {}

const ApprovalSettingsPanel = (props: ApprovalSettingsPanelProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const currentAccount = useCurrentAccount();

  // Mining Tool
  // const ftcToMiningToolMarket = useApprovalSetting(
  //   approvalRelation.FTCToMiningToolMarket.key,
  //   currentAccount
  // );
  // const miningToolToMiningToolMarket = useApprovalSetting(
  //   approvalRelation.MiningToolToMiningToolMarket.key,
  //   currentAccount
  // );

  // Tool Material
  // const ftcToToolMaterialMarket = useApprovalSetting(
  //   approvalRelation.FTCToToolMaterialMarket.key,
  //   currentAccount
  // );
  // const toolMaterialToToolMaterialMarket = useApprovalSetting(
  //   approvalRelation.ToolMaterialToToolMaterialMarket.key,
  //   currentAccount
  // );

  // Gene
  // const ftcToGeneMarket = useApprovalSetting(approvalRelation.FTCToGeneMarket.key, currentAccount);
  // const geneToGeneMarket = useApprovalSetting(
  //   approvalRelation.GeneToGeneMarket.key,
  //   currentAccount
  // );

  // Slime
  const ftcToSlimeMarket = useApprovalSetting(
    approvalRelation.FTCToSlimeMarket.key,
    currentAccount
  );
  const slimeToSlimeMarket = useApprovalSetting(
    approvalRelation.SlimeToSlimeMarket.key,
    currentAccount
  );

  // Land
  // const ftcToLandMarket = useApprovalSetting(approvalRelation.FTCToLandMarket.key, currentAccount);
  // const landToLandMarket = useApprovalSetting(
  //   approvalRelation.LandToLandMarket.key,
  //   currentAccount
  // );

  // Gacha Box
  // const ftcToGachaBoxMarket = useApprovalSetting(
  //   approvalRelation.FTCToGachaBoxMarket.key,
  //   currentAccount
  // );
  // const gachaBoxToGachaBoxMarket = useApprovalSetting(
  //   approvalRelation.GachaBoxToGachaBoxMarket.key,
  //   currentAccount
  // );

  return (
    <Center>
      <Grid templateColumns={`repeat(${isMobile ? 1 : 2}, 1fr)`} maxWidth="1024px">
        {/* <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:MiningToolMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToMiningToolMarketDescription')}
              isLoading={
                ftcToMiningToolMarket.approvalStatus.isLoading ||
                ftcToMiningToolMarket.approveMutation.isLoading
              }
              checked={ftcToMiningToolMarket.approvalStatus.data}
              onChange={ftcToMiningToolMarket.approveMutation.mutate}
            />
            <ApprovalSettingsStatus
              title={t(
                'Component:ApprovalSettings:ApprovalMiningToolToMiningToolMarketDescription'
              )}
              isLoading={
                miningToolToMiningToolMarket.approvalStatus.isLoading ||
                miningToolToMiningToolMarket.approveMutation.isLoading
              }
              checked={miningToolToMiningToolMarket.approvalStatus.data}
              onChange={miningToolToMiningToolMarket.approveMutation.mutate}
            />
          </ApprovalSettingsSection>
        </GridItem> */}
        {/* <GridItem>
          <ApprovalSettingsSection
            sectionTitle={t('Component:ApprovalSettings:ToolMaterialMarket')}
          >
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToToolMaterialMarketDescription')}
              isLoading={
                ftcToToolMaterialMarket.approvalStatus.isLoading ||
                ftcToToolMaterialMarket.approveMutation.isLoading
              }
              checked={ftcToToolMaterialMarket.approvalStatus.data}
              onChange={ftcToToolMaterialMarket.approveMutation.mutate}
            />
            <ApprovalSettingsStatus
              title={t(
                'Component:ApprovalSettings:ApprovalToolMaterialToMaterialToolMarketDescription'
              )}
              isLoading={
                toolMaterialToToolMaterialMarket.approvalStatus.isLoading ||
                toolMaterialToToolMaterialMarket.approveMutation.isLoading
              }
              checked={toolMaterialToToolMaterialMarket.approvalStatus.data}
              onChange={toolMaterialToToolMaterialMarket.approveMutation.mutate}
            />
          </ApprovalSettingsSection>
        </GridItem> */}
        {/* <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:GeneMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToGeneMarketDescription')}
              isLoading={
                ftcToGeneMarket.approvalStatus.isLoading ||
                ftcToGeneMarket.approveMutation.isLoading
              }
              checked={ftcToGeneMarket.approvalStatus.data}
              onChange={ftcToGeneMarket.approveMutation.mutate}
            />
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalGeneToGeneMarketDescription')}
              isLoading={
                geneToGeneMarket.approvalStatus.isLoading ||
                geneToGeneMarket.approveMutation.isLoading
              }
              checked={geneToGeneMarket.approvalStatus.data}
              onChange={geneToGeneMarket.approveMutation.mutate}
            />
          </ApprovalSettingsSection>
        </GridItem> */}
        <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:SlimeMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToSlimeMarketDescription')}
              isLoading={
                ftcToSlimeMarket.approvalStatus.isLoading ||
                ftcToSlimeMarket.approveMutation.isLoading
              }
              checked={ftcToSlimeMarket.approvalStatus.data}
              onChange={ftcToSlimeMarket.approveMutation.mutate}
            />
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalSlimeToSlimeMarketDescription')}
              isLoading={
                slimeToSlimeMarket.approvalStatus.isLoading ||
                slimeToSlimeMarket.approveMutation.isLoading
              }
              checked={slimeToSlimeMarket.approvalStatus.data}
              onChange={slimeToSlimeMarket.approveMutation.mutate}
            />
          </ApprovalSettingsSection>
        </GridItem>
        {/* <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:LandMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToLandMarketDescription')}
              isLoading={
                ftcToLandMarket.approvalStatus.isLoading ||
                ftcToLandMarket.approveMutation.isLoading
              }
              checked={ftcToLandMarket.approvalStatus.data}
              onChange={ftcToLandMarket.approveMutation.mutate}
            />
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalLandToLandMarketDescription')}
              isLoading={
                landToLandMarket.approvalStatus.isLoading ||
                landToLandMarket.approveMutation.isLoading
              }
              checked={landToLandMarket.approvalStatus.data}
              onChange={landToLandMarket.approveMutation.mutate}
            />
          </ApprovalSettingsSection>
        </GridItem> */}
        {/* <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:GachaBoxMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToGachaBoxMarketDescription')}
              isLoading={
                ftcToGachaBoxMarket.approvalStatus.isLoading ||
                ftcToGachaBoxMarket.approveMutation.isLoading
              }
              checked={ftcToGachaBoxMarket.approvalStatus.data}
              onChange={ftcToGachaBoxMarket.approveMutation.mutate}
            />
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalGachaBoxToGachaBoxMarketDescription')}
              isLoading={
                gachaBoxToGachaBoxMarket.approvalStatus.isLoading ||
                gachaBoxToGachaBoxMarket.approveMutation.isLoading
              }
              checked={gachaBoxToGachaBoxMarket.approvalStatus.data}
              onChange={gachaBoxToGachaBoxMarket.approveMutation.mutate}
            />
          </ApprovalSettingsSection>
        </GridItem> */}
      </Grid>
    </Center>
  );
};

export default ApprovalSettingsPanel;
