import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { setToken } from '../../../clients/accountServiceClient';
import * as accountServiceService from '../../../services/accountServices';
import MetamaskProvider from '../../../metamask-provider';

const useAuthenticatedWithAccountServiceByWalletAddress = (walletAddress: string) => {
  const queryClient = useQueryClient();
  const { isLoading: isLoggingWithWallet, mutate } = useMutation({
    mutationFn: async () => {
      const { nonce } = await accountServiceService.getWalletNonce(walletAddress);
      const signature = await MetamaskProvider.signMessage(nonce, walletAddress);
      const tokenRes = await accountServiceService.loginByWallet(walletAddress, signature);
      setToken(tokenRes.token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['accountInformation']);
    },
  });

  const loginWithWallet = useCallback(() => mutate(), [mutate]);
  return { isLoggingWithWallet, loginWithWallet };
};

export default useAuthenticatedWithAccountServiceByWalletAddress;
