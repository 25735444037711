import { useCallback, useState } from 'react';
import { ISaleInfoFromMetaMask } from 'src/metamask-provider/type';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import * as marketService from 'src/services/market';

const useGetFixedPriceSaleInfoBySaleId = (nftType: TNFTType) => {
  const [isGettingSaleInfoBySaleId, setIsGettingSaleInfoBySaleId] = useState<boolean | undefined>();
  const [saleInfo, setSaleInfo] = useState<ISaleInfoFromMetaMask | undefined>();
  const [currentSaleId, setCurrentSaleId] = useState<string | undefined>();
  const getFixedPriceSaleInfoBySaleId = useCallback(
    (saleId?: string) => {
      setCurrentSaleId(saleId);
      if (saleId) {
        setIsGettingSaleInfoBySaleId(true);
        marketService
          .getSaleInfoBySaleId(
            coreUtils.getMarketContractByNftType(nftType),
            saleId,
            coreUtils.getTokenFormatByNftType(nftType)
          )
          .then((saleInfo) => {
            return setSaleInfo(saleInfo);
          })
          .catch(console.error)
          .finally(() => setIsGettingSaleInfoBySaleId(false));
      } else {
        setSaleInfo(undefined);
      }
    },
    [nftType, setIsGettingSaleInfoBySaleId]
  );

  return [
    isGettingSaleInfoBySaleId,
    currentSaleId ? saleInfo : undefined,
    getFixedPriceSaleInfoBySaleId,
  ];
};

export default useGetFixedPriceSaleInfoBySaleId;
