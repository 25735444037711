import React, { useCallback, useEffect } from 'react';
import { CloseButton, Flex, Heading, Link, useColorModeValue, VStack } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';
import { QueryString } from 'src/globals/constants';
import { Colors } from 'src/shared';
import { coreUtils } from 'src/common';
import { IRequestFilterBase } from 'src/globals/types';
import { CategoryLabel, GeneClassLabel, GenePartLabel, RarityLabel } from 'src/components';
import useFilterData from '../hooks/useFilterData';
import RangeSelection from './RangeSelection';
import SearchText from './SearchText';
import Selection from './Selection';

interface FilterProps {
  filterBase?: IRequestFilterBase;
  onBack?: () => any;
  showBackButton?: boolean;
}

const Filter = (props: FilterProps) => {
  const { filterBase, onBack, showBackButton = true } = props;
  const [searchQuery, hasSearchQuery, setSearchQuery, resetSearchQuery] = useFilterData<string>(
    '',
    filterBase as IRequestFilterBase,
    QueryString.SEARCH_QUERY
  );
  const [rangeMiningPower, hasMiningPowerQuery, setRangeMiningPower, resetRangeMiningPower] =
    useFilterData<number[] | undefined>(
      filterBase?.mp?.value,
      filterBase as IRequestFilterBase,
      QueryString.MINING_POWER
    );
  const [category, hasCategoryQuery, setCategory, resetCategory] = useFilterData<number[]>(
    [],
    filterBase as IRequestFilterBase,
    QueryString.CATEGORY
  );
  const [rarity, hasRarityQuery, setRarity, resetRarity] = useFilterData<number[]>(
    [],
    filterBase as IRequestFilterBase,
    QueryString.RARITY
  );
  const [part, hasPartQuery, setPart, resetPart] = useFilterData<number[]>(
    [],
    filterBase as IRequestFilterBase,
    QueryString.PART
  );
  const [geneClass, hasGeneClassQuery, setGeneClass, resetGeneClass] = useFilterData<number[]>(
    [],
    filterBase as IRequestFilterBase,
    QueryString.GENE_CLASS
  );
  const [slimeClass, hasSlimeClassQuery, setSlimeClass, resetSlimeClass] = useFilterData<number[]>(
    [],
    filterBase as IRequestFilterBase,
    QueryString.CLASS
  );
  const [rangeCooldown, hasCooldownQuery, setRangeCooldown, resetRangeCooldown] = useFilterData<
    number[] | undefined
  >(filterBase?.cd?.value, filterBase as IRequestFilterBase, QueryString.COOLDOWN); // // Cooldown in seconds * 100
  const [rangeLuckyLevel, hasLuckyLevelQuery, setRangeLuckyLevel, resetRangeLuckyLevel] =
    useFilterData<number[] | undefined>(
      filterBase?.luck?.value,
      filterBase as IRequestFilterBase,
      QueryString.LUCK
    ); // // Luck * 100

  const { pathname, search } = useLocation();
  const queryParams = queryString.parse(search);
  const history = useHistory();

  useEffect(() => {
    const queryData = coreUtils.standardizeFilterToQuery(queryParams, filterBase);
    resetSearchQuery(queryData.search);
    resetRangeMiningPower(queryData.mp);
    resetCategory(queryData.cat);
    resetRarity(queryData.rarity);
    resetRangeCooldown(queryData.cd);
    resetRangeLuckyLevel(queryData.luck);
    resetPart(queryData.part);
    resetGeneClass(queryData.geneClass);
    resetSlimeClass(queryData.class);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBase]);

  const clearFilter = useCallback(() => {
    resetSearchQuery();
    resetRangeMiningPower();
    resetCategory();
    resetRarity();
    resetRangeCooldown();
    resetRangeLuckyLevel();
    resetPart();
    resetGeneClass();
    resetSlimeClass();

    const newQueryParams = omit(queryParams, [
      QueryString.PAGE,
      QueryString.SEARCH_QUERY,
      QueryString.CATEGORY,
      QueryString.MINING_POWER,
      QueryString.COOLDOWN,
      QueryString.RARITY,
      QueryString.LUCK,
      QueryString.PART,
      QueryString.GENE_CLASS,
      QueryString.CLASS,
    ]);

    const querySearch = queryString.stringify(newQueryParams);
    history.push(`${pathname}?${querySearch}`);
  }, [
    resetSearchQuery,
    resetRangeMiningPower,
    resetCategory,
    resetRarity,
    resetRangeCooldown,
    resetRangeLuckyLevel,
    resetPart,
    resetGeneClass,
    resetSlimeClass,
    queryParams,
    history,
    pathname,
  ]);

  const { t } = useTranslation();

  const activeText = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const renderCategoryOptions = () => {
    return (
      filterBase?.cat?.value.map((value) => ({
        label: <CategoryLabel categoryId={value} fontSize={'sm'} />,
        value,
      })) || []
    );
  };
  const renderRarityOptions = () => {
    return (
      filterBase?.rarity?.value.map((value) => ({
        label: <RarityLabel rarity={value} fontSize={'sm'} />,
        value,
      })) || []
    );
  };
  const renderGeneClassOptions = () => {
    return (
      filterBase?.geneClass?.value.map((value) => ({
        label: <GeneClassLabel geneClass={value} fontSize={'sm'} />,
        value,
      })) || []
    );
  };
  const renderSlimeClassOptions = () => {
    return (
      filterBase?.class?.value.map((value) => ({
        label: <GeneClassLabel geneClass={value} fontSize={'sm'} />,
        value,
      })) || []
    );
  };
  const renderPartOptions = () => {
    return (
      filterBase?.part?.value.map((value) => ({
        label: <GenePartLabel genePart={value} fontSize={'sm'} />,
        value,
      })) || []
    );
  };
  return (
    <VStack pos={'relative'} w={'full'} p={4} align="flex-start" spacing={4}>
      <Flex justify="space-between" align="center" w="full">
        <Flex flex={1} align="center" alignItems="flex-end">
          <Heading as="h2" color="heading" fontSize="2xl">
            {t('Component:Filter.Title')}
          </Heading>
          <Link
            variant="ghost"
            color={activeText}
            size="sm"
            borderRadius="full"
            onClick={clearFilter}
            ml={2}
          >
            {t('Component:Filter.ClearAll')}
          </Link>
        </Flex>
        {showBackButton && onBack && <CloseButton onClick={onBack} />}
      </Flex>

      {hasSearchQuery && (
        <SearchText
          currentValue={searchQuery}
          onChange={setSearchQuery}
          placeholder={t('Component:Filter.SearchText')}
        />
      )}

      {hasGeneClassQuery && (
        <Selection
          currentValue={geneClass}
          onChange={setGeneClass}
          title={t('Term:GeneClass')}
          options={renderGeneClassOptions()}
        />
      )}

      {hasSlimeClassQuery && (
        <Selection
          currentValue={slimeClass}
          onChange={setSlimeClass}
          title={t('Term:GeneClass')}
          options={renderSlimeClassOptions()}
        />
      )}

      {hasPartQuery && (
        <Selection
          currentValue={part}
          onChange={setPart}
          title={t('Term:Part')}
          options={renderPartOptions()}
        />
      )}

      {hasRarityQuery && (
        <Selection
          currentValue={rarity}
          onChange={setRarity}
          title={t('Term:Rarity')}
          options={renderRarityOptions()}
        />
      )}

      {hasCategoryQuery && (
        <Selection
          currentValue={category}
          onChange={setCategory}
          title={t('Term:Category')}
          options={renderCategoryOptions()}
        />
      )}

      {hasMiningPowerQuery && (
        <RangeSelection
          currentValues={rangeMiningPower}
          min={filterBase?.mp?.min as number}
          max={filterBase?.mp?.max as number}
          step={filterBase?.mp?.step as number}
          minStepsBetweenThumbs={filterBase?.mp?.minStepsBetweenThumbs}
          onChange={setRangeMiningPower}
          title={t('Term:MiningPower')}
          unit={filterBase?.mp?.unit}
        />
      )}

      {hasCooldownQuery && (
        <RangeSelection
          currentValues={rangeCooldown}
          min={filterBase?.cd?.min as number}
          max={filterBase?.cd?.max as number}
          step={filterBase?.cd?.step as number}
          minStepsBetweenThumbs={filterBase?.cd?.minStepsBetweenThumbs}
          onChange={setRangeCooldown}
          title={t('Term:Cooldown')}
          unit={filterBase?.cd?.unit}
        />
      )}

      {hasLuckyLevelQuery && (
        <RangeSelection
          currentValues={rangeLuckyLevel}
          min={filterBase?.luck?.min as number}
          max={filterBase?.luck?.max as number}
          step={filterBase?.luck?.step as number}
          minStepsBetweenThumbs={filterBase?.luck?.minStepsBetweenThumbs}
          onChange={setRangeLuckyLevel}
          title={t('Term:Luck')}
          unit={filterBase?.luck?.unit}
        />
      )}
    </VStack>
  );
};

export default Filter;
