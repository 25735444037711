import apolloClient from '../apolloClient';
import { INFTSummary } from './types';
import { GetNFTSummaryQuery } from './queries';
import { DefaultNFTSummary } from './constants';

export const getNFTSummary = async () => {
  try {
    const response = await apolloClient.query({
      query: GetNFTSummaryQuery,
    });
    const result: INFTSummary = response?.data?.getNFTSummary || DefaultNFTSummary;
    return result;
  } catch (error) {
    throw error;
  }
};
