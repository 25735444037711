import { useCallback } from 'react';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import useApprovalSetting from 'src/features/approvalSettings/hooks/useApprovalSetting';
import useCurrentAccount from 'src/hooks/useWalletAddress';

const useApproveNFTToFixedPriceSaleMarket = (nftType: TNFTType) => {
  const currentAccount = useCurrentAccount();

  const { nftApprovalKey } = coreUtils.getPairOfContractsByNftType(nftType);

  const nftToNftMarket = useApprovalSetting(nftApprovalKey, currentAccount);

  const isGettingApprovedStatus = nftToNftMarket.approvalStatus.isLoading;
  const isApprovingForSale = nftToNftMarket.approveMutation.isLoading;
  const isApprovedForSale = nftToNftMarket.approvalStatus.data;
  const error =
    nftToNftMarket.approvalStatus.error?.toString() ||
    nftToNftMarket.approveMutation.error?.toString();

  // Only call approve if the tokenId is set and the token is not approved
  const onApprovedNFTToFixedPriceSaleMarket = useCallback(() => {
    if (currentAccount && !isApprovedForSale) {
      nftToNftMarket.approveMutation.mutate(true);
    }
  }, [currentAccount, isApprovedForSale, nftToNftMarket.approveMutation]);

  return [
    onApprovedNFTToFixedPriceSaleMarket,
    isGettingApprovedStatus,
    isApprovingForSale,
    isApprovedForSale,
    error,
  ];
};

export default useApproveNFTToFixedPriceSaleMarket;
