import React, { useMemo } from 'react';
import { Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { IFixedPriceSaleNFT, INFT, TNFTType } from 'src/gql/types';
import { EmptyView, LinkButton, Spinner } from 'src/components';
import { coreUtils } from 'src/common';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Colors } from 'src/shared';
import useDashboardRecently from 'src/hooks/useDashboardRecently';
import { RecentlyListKey, TRecentlyListKey } from 'src/redux/types';
import { FixedPriceSaleStatus, RoutePath } from '../../../globals/constants';
import RecentlyListItem from './RecentlyListItem';

interface RecentlyListProps {
  nftType: TNFTType;
  recentlyListKey: TRecentlyListKey;
}

const RecentlyList = (props: RecentlyListProps) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  const { recentlyListKey, nftType } = props;

  const { dashboardRecentlyQuery } = useDashboardRecently({
    nftType,
    status:
      recentlyListKey === RecentlyListKey.RECENTLY_SOLD
        ? FixedPriceSaleStatus.BOUGHT
        : FixedPriceSaleStatus.AVAILABLE,
  });

  const listOfFixedPriceSaleNFTs = useMemo(
    () => dashboardRecentlyQuery.data?.records || [],
    [dashboardRecentlyQuery.data?.records]
  );

  const isGettingFixedPriceSaleNFTs =
    dashboardRecentlyQuery.isLoading || dashboardRecentlyQuery.isFetching;

  const emptyDescription = t('Component:RecentlyList.EmptyList');
  return (
    <Flex direction="column" flex={1}>
      {recentlyListKey === RecentlyListKey.RECENTLY_LISTED && (
        <Flex justify="space-between" align="center" height={10}>
          <Heading as="h2" color="heading" fontSize="2xl">
            {t('Component:RecentlyList.Title.RecentlyListed')}
          </Heading>
          <LinkButton to={RoutePath.SLIME} color={textColor} paddingInline={0}>
            {t('Component:RecentlyList.ViewMore')}
            <ChevronRightIcon h={6} w={6} />
          </LinkButton>
        </Flex>
      )}
      {recentlyListKey === RecentlyListKey.RECENTLY_SOLD && (
        <Flex justify="space-between" align="center" w="full" height={10}>
          <Heading as="h2" color="heading" fontSize="2xl">
            {t('Component:RecentlyList.Title.RecentlySold')}
          </Heading>
        </Flex>
      )}
      <Flex flex={{ base: 1 }} minHeight="120px" flexDirection="column" pos="relative">
        {!isEmpty(listOfFixedPriceSaleNFTs) && (
          <Flex direction="row" flexWrap="wrap" justifyContent="center">
            {listOfFixedPriceSaleNFTs.map((item: INFT | IFixedPriceSaleNFT) => (
              <RecentlyListItem
                recentlyListKey={recentlyListKey}
                nftType={nftType}
                key={(item as IFixedPriceSaleNFT).id}
                item={item as IFixedPriceSaleNFT}
                rootPath={coreUtils.getPathByNFTType(nftType)}
              />
            ))}
          </Flex>
        )}
        {!isGettingFixedPriceSaleNFTs && isEmpty(listOfFixedPriceSaleNFTs) && (
          <EmptyView showImage={false} description={emptyDescription} />
        )}
        {isGettingFixedPriceSaleNFTs && <Spinner size="md" top={2} />}
      </Flex>
    </Flex>
  );
};

export default RecentlyList;
