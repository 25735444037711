import { TNFTType } from 'src/gql/types';
import * as GQLApi from 'src/gql';
import { QueryKeys } from 'src/globals/constants';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash/isNil';

const useMyNFTDetails = (props: {
  tokenType: TNFTType;
  tokenId?: number;
  currentAccount?: string;
}) => {
  const myNFTDetailsQuery = useQuery(
    [QueryKeys.myNftDetails, props.tokenType, props.tokenId, props.currentAccount],
    () => {
      if (isNil(props.currentAccount)) {
        throw new Error('currentAccount is required');
      }
      if (isNil(props.tokenId)) {
        throw new Error('tokenId is required');
      }
      return GQLApi.getMyNFTDetailByNFTType(props.tokenType, {
        tokenId: props.tokenId,
        owner: props.currentAccount,
        type: props.tokenType,
      });
    },
    {
      enabled: !isNil(props.currentAccount) && !isNil(props.tokenId),
    }
  );
  return {
    myNFTDetailsQuery,
  };
};

export default useMyNFTDetails;
