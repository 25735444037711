import React from 'react';
import { Redirect } from 'react-router-dom';
import { RoutePath } from 'src/globals/constants';

type HomeProps = {};
const Home = (props: HomeProps) => {
  return (
    <>
      <Redirect to={RoutePath.DASHBOARD} />
    </>
  );
};

export default Home;
