import { readContract } from 'wagmi/actions';
import * as contracts from 'src/shared/contracts';

export const getCurrentMiningTools = async (currentAccount: string) => {
  const result: any[] = await Promise.all(
    [0, 1, 2].map((index) =>
      readContract({
        args: [currentAccount, index],
        ...contracts.getContractAddressAndAbi(contracts.contractNames.mining),
        functionName: 'miningTools',
      })
    )
  );

  return result
    .filter((i: bigint) => i.toString() !== '0')
    .map((i: bigint) => parseInt(i.toString()));
};
