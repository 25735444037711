import useApprovalStatus from './useApprovalStatus';
import useApproveMutation from './useApproveMutation';

const useApprovalSetting = (approvalRelationKey: string, currentAccount?: string) => {
  const approvalStatus = useApprovalStatus(approvalRelationKey, currentAccount);
  const approveMutation = useApproveMutation(approvalRelationKey, currentAccount);
  return { approvalStatus, approveMutation };
};

export default useApprovalSetting;
