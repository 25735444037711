import { Box, VStack, Button, ButtonGroup, Flex } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SlimeInfoMetadata } from 'src/common/slimeStatCalculation';
import { ISlimeMetadata } from '../../../../gql/types';
import Part from './Part';

interface PartsStatPropsType {
  metadata: ISlimeMetadata;
}

const Parts = ({ metadata }: PartsStatPropsType) => {
  const { t } = useTranslation();
  const [displayMode, setDisplayMode] = useState<'percentage' | 'point'>('percentage');

  const fullStatGroup = useMemo(() => {
    return new SlimeInfoMetadata(metadata).fullPartStatPoints;
  }, [metadata]);

  return (
    <VStack alignItems="start">
      <Flex marginY={5} justifyContent="center" width="100%">
        <ButtonGroup isAttached variant="outline" size="sm">
          <Button
            onClick={() => setDisplayMode('percentage')}
            isActive={displayMode === 'percentage'}
          >
            {t('Component:SlimeDetails:DisplayMode:Percentage')}
          </Button>
          <Button onClick={() => setDisplayMode('point')} isActive={displayMode === 'point'}>
            {t('Component:SlimeDetails:DisplayMode:Point')}
          </Button>
        </ButtonGroup>
      </Flex>
      <Box w="100%">
        <Part
          title={t('Component:SlimeDetails:Part:RArm')}
          klass={metadata.rightArmDetails.class}
          rarity={metadata.rightArmDetails.rarity}
          displayMode={displayMode}
          hp={fullStatGroup.rightArm.hp}
          atk={fullStatGroup.rightArm.atk}
          def={fullStatGroup.rightArm.def}
        />
        <Part
          title={t('Component:SlimeDetails:Part:LArm')}
          klass={metadata.leftArmDetails.class}
          rarity={metadata.leftArmDetails.rarity}
          displayMode={displayMode}
          hp={fullStatGroup.leftArm.hp}
          atk={fullStatGroup.leftArm.atk}
          def={fullStatGroup.leftArm.def}
        />
        <Part
          title={t('Component:SlimeDetails:Part:Head')}
          klass={metadata.headDetails.class}
          rarity={metadata.headDetails.rarity}
          displayMode={displayMode}
          critDmg={fullStatGroup.head.critdmg}
          block={fullStatGroup.head.block}
        />
        <Part
          title={t('Component:SlimeDetails:Part:SubBody')}
          klass={metadata.subDetails.class}
          rarity={metadata.subDetails.rarity}
          displayMode={displayMode}
          crit={fullStatGroup.sub.crit}
          evade={fullStatGroup.sub.evade}
        />
        <Part
          title={t('Component:SlimeDetails:Part:Body')}
          klass={metadata.bodyDetails.class}
          rarity={metadata.bodyDetails.rarity}
          displayMode={displayMode}
          hp={fullStatGroup.body.hp}
          atk={fullStatGroup.body.atk}
          def={fullStatGroup.body.def}
        />
        <Part
          title={t('Component:SlimeDetails:Part:Face')}
          klass={metadata.faceDetails.class}
          rarity={metadata.faceDetails.rarity}
          displayMode={displayMode}
          hp={fullStatGroup.face.hp}
          atk={fullStatGroup.face.atk}
          def={fullStatGroup.face.def}
          block={fullStatGroup.face.block}
          crit={fullStatGroup.face.crit}
          critDmg={fullStatGroup.face.critdmg}
          evade={fullStatGroup.face.evade}
        />
      </Box>
    </VStack>
  );
};

export default Parts;
