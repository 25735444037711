import { useEffect } from 'react';
import isFunction from 'lodash/isFunction';
import useUpdateCustomerInfo from './useUpdateCustomerInfo';
import useAutoCreateUserByWalletAddress from './useAutoCreateUserByWalletAddress';
import useCurrentAccount from './useWalletAddress';

const useAutoUpdateCustomerInfo = () => {
  const metaMaskWalletAddress = useCurrentAccount();
  const [, , updateCustomerInfo] = useUpdateCustomerInfo();
  const [, , registerUserWithAccountService] = useAutoCreateUserByWalletAddress();
  useEffect(() => {
    if (metaMaskWalletAddress && isFunction(updateCustomerInfo)) {
      updateCustomerInfo(metaMaskWalletAddress, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).catch(console.error);
    }
    if (metaMaskWalletAddress && isFunction(registerUserWithAccountService)) {
      registerUserWithAccountService(metaMaskWalletAddress).catch(console.error);
    }
  }, [metaMaskWalletAddress, registerUserWithAccountService, updateCustomerInfo]);
};

export default useAutoUpdateCustomerInfo;
