import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text, useColorModeValue } from '@chakra-ui/react';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import useAccountInformation from '../hooks/useAccountInformation';
import { AppSizes, Colors } from '../../../shared';
import useBindToAccountByWallet from '../hooks/useBindToAccountByWallet';
import { BindingType } from '../../../types/AccountServiceClient';
import ChangePasswordByWalletModal from './ChangePasswordByWalletModal';
// import ChangePasswordByCurrentPasswordModal from './ChangePasswordByCurrentPasswordModal';
// import useAuthenticatedWithAccountServiceByWalletAddress
//   from '../hooks/useAuthenticatedWithAccountServiceByWalletAddress';

interface PasswordSettingProps {}

enum ChangePasswordType {
  CurrentPassword,
  None,
  Wallet,
}

const PasswordSetting = (props: PasswordSettingProps) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAccountInformation();

  const metaMaskWalletAddress = useCurrentAccount();
  const { isBinding, bind } = useBindToAccountByWallet(BindingType.password, metaMaskWalletAddress);

  const textColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const [changePasswordType, setChangePasswordType] = React.useState<ChangePasswordType>(
    ChangePasswordType.None
  );
  const defaultBtnBg = useColorModeValue(Colors.dark.primary, Colors.dark.primary);
  const onPressChangePasswordByCurrentPassword = useCallback(() => {
    setChangePasswordType(ChangePasswordType.CurrentPassword);
  }, []);

  const onPressChangePasswordByWallet = useCallback(() => {
    setChangePasswordType(ChangePasswordType.Wallet);
  }, []);

  const onPressCloseChangePassword = useCallback(() => {
    setChangePasswordType(ChangePasswordType.None);
  }, []);

  const onConfirmChangePasswordByWallet = useCallback(
    async (password: string) => {
      await bind(
        { password },
        {
          onSuccess: () => {
            onPressCloseChangePassword();
          },
        }
      );
    },
    [bind, onPressCloseChangePassword]
  );

  return (
    <Box>
      <Text color={textColor} mb={2}>
        {t('Common:Password')}
      </Text>
      <Button
        disabled={!isAuthenticated}
        minWidth={AppSizes.ButtonMinWidth}
        borderRadius={'full'}
        onClick={onPressChangePasswordByWallet}
      >
        {t('Component:AccountSettings.ChangePassword.ChangePasswordBtn')}
      </Button>
      {changePasswordType === ChangePasswordType.Wallet && (
        <ChangePasswordByWalletModal
          isOpen={changePasswordType === ChangePasswordType.Wallet}
          onClose={onPressCloseChangePassword}
          onConfirm={onConfirmChangePasswordByWallet}
          onClickSwitchMethod={onPressChangePasswordByCurrentPassword}
          isLoading={isBinding}
        />
      )}
      {/*<ChangePasswordByCurrentPasswordModal*/}
      {/*  isOpen={changePasswordType === ChangePasswordType.CurrentPassword}*/}
      {/*  onClose={onPressCloseChangePassword}*/}
      {/*  onConfirm={() => {}}*/}
      {/*  onClickSwitchMethod={onPressChangePasswordByWallet}*/}
      {/*/>*/}
    </Box>
  );
};

export default PasswordSetting;
