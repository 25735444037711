import React, { ReactElement } from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Colors } from 'src/shared';
import { RootReducerState } from 'src/redux';
interface InventoryLayoutProps {
  children: ReactElement | ReactElement[];
}
const FilterSection = (props: InventoryLayoutProps) => {
  const { children } = props;
  const borderColor = useColorModeValue(Colors.light.divider, Colors.dark.divider);
  const bg = useColorModeValue(Colors.light.secondaryBg, Colors.dark.secondaryBg);
  const inventoryFilterVisible = useSelector(
    (state: RootReducerState) => state.app.inventoryFilterVisible
  );
  if (!inventoryFilterVisible) return null;

  return (
    <Flex
      w={{ base: 'full', sm: '280px' }}
      flexDirection={'column'}
      borderLeft={{ base: 'none', md: `1px solid ${borderColor}` }}
      pos={{ base: 'fixed', md: 'relative' }}
      bg={bg}
      right={0}
      overflowY={'auto'}
      height={'inherit'}
    >
      {children}
    </Flex>
  );
};

export default FilterSection;
