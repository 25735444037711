import { appUtils } from 'src/common';
import { IMarketStatistics, ISummary, TTimeFrame } from '../types';

export const standardizeMarketplaceSummary = (
  marketStatistics: IMarketStatistics
): ISummary | undefined => {
  const volume = appUtils.parseDisplayPriceFromNumberWithDecimal(
    marketStatistics.totalVolume?.origin ?? '0'
  );
  const volumeUSD = appUtils.parseDisplayPriceFromNumberWithDecimal(
    marketStatistics.totalVolume?.usdt ?? '0'
  );
  const totalSale = Number(marketStatistics.totalSale ?? '0');
  const totalTransaction = Number(marketStatistics.totalTransaction ?? '0');
  const avgPrice = totalSale > 0 ? volume / totalSale : 0;
  const avgPriceUSD = totalSale > 0 ? volumeUSD / totalSale : 0;

  return {
    totalSale,
    avgPrice,
    avgPriceUSD,
    volume,
    volumeUSD,
    totalTransaction,
  };
};

export const getSummaryKeyByTimeFrame = (timeFrame: TTimeFrame) => {
  switch (timeFrame) {
    case 'last24h':
      return 'last24Hours';
    case 'last7days':
      return 'last7Days';
    case 'last30days':
    default:
      return 'last30Days';
  }
};
