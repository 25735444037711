import { IAppVariable, INFTVariable, IOrderByKeyInput, TNFTType } from 'src/gql/types';
import * as GQLApi from 'src/gql';
import { QueryKeys } from 'src/globals/constants';
import { useQuery } from '@tanstack/react-query';
import { getOrderByVariableByType } from 'src/gql/common';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { coreUtils } from 'src/common';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useMyNFTs = (props: { nftType: TNFTType; ownerId?: string }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [variables, setVariables] = useState<IAppVariable | null>(null);

  useEffect(() => {
    const queryParams = queryString.parse(search);
    const variables = coreUtils.standardizeVariablesForQuery(queryParams);
    setVariables(variables);
  }, [search, history, setVariables]);

  const myNFTsQuery = useQuery(
    [QueryKeys.myNfts, props.nftType, variables, props.ownerId],
    () => {
      if (isNil(variables)) {
        throw new Error('Variables is null');
      }
      const { orderBy: orderByNFTType, ...remainingVariables } = variables;

      const orderBy = isNil(orderByNFTType)
        ? undefined
        : getOrderByVariableByType(props.nftType, orderByNFTType as IOrderByKeyInput);
      const newVariables: INFTVariable = {
        ...remainingVariables,
        orderBy,
        owner: props.ownerId,
      };
      return GQLApi.getMyNFTsByNFTType(props.nftType, newVariables);
    },
    {
      enabled: !isNil(props.ownerId) && !isNil(variables),
      keepPreviousData: true,
    }
  );
  return {
    myNFTsQuery,
    variables,
  };
};

export default useMyNFTs;
