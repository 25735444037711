import React from 'react';
import { Tag } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import WrongNetworkModal from './WrongNetworkModal';

const SwitchNetworkButton = () => {
  const { t } = useTranslation();
  const [isOpenWrongNetworkModal, setIsOpenWrongNetworkModal] = React.useState(false);

  return (
    <>
      <Tag
        size="lg"
        colorScheme="red"
        borderRadius="full"
        cursor="pointer"
        onClick={() => setIsOpenWrongNetworkModal(true)}
      >
        {t('Component:ModalMetaMaskNotConnectBSC:WrongNetwork')}
      </Tag>
      <WrongNetworkModal
        isOpen={isOpenWrongNetworkModal}
        onClose={() => setIsOpenWrongNetworkModal(false)}
      />
    </>
  );
};

export default SwitchNetworkButton;
