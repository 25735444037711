import React, { memo } from 'react';
import {
  Flex,
  Box,
  Heading,
  Image,
  Text,
  TextProps,
  FlexProps,
  BoxProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { AppSizes, Colors } from 'src/shared';

export interface OverviewCardProps extends FlexProps {
  icon?: string;
  iconStyle?: BoxProps;
  title?: string;
  highlightedText?: string;
  highlightedTextStyle?: TextProps;
  secondaryText?: string;
  secondaryTextStyle?: TextProps;
}

const OverviewCard = (props: OverviewCardProps) => {
  const {
    highlightedText,
    highlightedTextStyle: highlightedTextStyleProps = {},
    secondaryText,
    secondaryTextStyle: secondaryTextStyleProps = {},
    icon,
    title,
    iconStyle: iconStyleProps = {},
    ...remainingProps
  } = props;
  const bg = useColorModeValue(Colors.dark.cardBg, Colors.dark.cardBg);
  const primaryColor = useColorModeValue(Colors.dark.primary, Colors.dark.primary);
  const descriptionColor = useColorModeValue(Colors.dark.description, Colors.dark.description);
  const textStyle = {
    color: descriptionColor,
  };
  const highlightedTextStyle = {
    ...styles.highlightedText,
    color: primaryColor,
    ...highlightedTextStyleProps,
  };
  const secondaryTextStyle = {
    ...styles.secondaryText,
    color: descriptionColor,
    ...secondaryTextStyleProps,
  };
  const iconStyle = {
    ...styles.icon,
    ...iconStyleProps,
  };
  return (
    <Flex {...styles.overviewContainer} bg={bg} {...remainingProps}>
      <Box {...iconStyle}>
        <Image
          maxWidth="inherit"
          width={[AppSizes.NFTMenuIcon, AppSizes.Icon]}
          height={[AppSizes.NFTMenuIcon, AppSizes.Icon]}
          src={icon}
        />
      </Box>
      <Flex {...styles.overviewInfo}>
        <Heading fontSize="md" fontWeight={700} fontFamily="body" {...textStyle} isTruncated>
          {title}
        </Heading>
        <Box display="inline">
          {!!highlightedText && <Text {...highlightedTextStyle}>{highlightedText}</Text>}
          {!!secondaryText && <Text {...secondaryTextStyle}>{` ${secondaryText}`}</Text>}
        </Box>
      </Flex>
    </Flex>
  );
};

export default memo(OverviewCard);

const styles = {
  overviewContainer: {
    flexDirection: 'row' as 'row',
    minW: '200px',
    w: 'full',
    p: [2, 4],
    overflow: 'hidden' as 'hidden',
    alignItems: { base: 'center' as 'center', lg: 'flex-start' as 'flex-start' },
  },
  overviewInfo: {
    paddingLeft: [2, 4],
    flexDirection: 'column' as 'column',
  },
  highlightedText: {
    as: 'span' as 'span',
    fontSize: '2xl',
    fontWeight: 700,
    m: 0,
    isTruncated: true,
  },
  secondaryText: {
    as: 'span' as 'span',
    fontSize: 'lg',
    fontWeight: 700,
    m: 0,
  },
  icon: {
    width: '50px',
    height: '50px',
    padding: ['10px', '5px'],
    mb: [2, 4],
    borderRadius: 'full',
  },
};
