import {
  Text,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useChangeWagmiNetwork from 'src/hooks/useChangeWagmiNetwork';
import useDisconnectWagmi from 'src/hooks/useDisconnectWagmi';
import { AppSizes, Colors, Resources } from 'src/shared';

interface IWrongNetworkModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WrongNetworkModal = ({ isOpen, onClose }: IWrongNetworkModalProps) => {
  const [canChangeNetwork, setCanChangeNetwork] = useState(true);
  const { isLoading: isLoadingConnectWagmi, doChangeWagmiNetwork } = useChangeWagmiNetwork();
  const { isLoading: isDisconnecting, doDisconnectWagmi } = useDisconnectWagmi();
  const { t } = useTranslation();
  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );
  const secondaryButtonBackgroundColor = useColorModeValue(
    Colors.light.secondaryBg,
    Colors.dark.secondaryBg
  );

  const onPressChangeNetwork = async () => {
    const timeoutId = setTimeout(() => {
      setCanChangeNetwork(false);
    }, 3000);
    const changeSuccessfully = await doChangeWagmiNetwork();
    clearTimeout(timeoutId);
    if (!changeSuccessfully) {
      setCanChangeNetwork(false);
    }
  };

  return (
    <Modal size={'sm'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor={modalBackgroundColor}>
        <ModalHeader textAlign={'center'}>
          {t('Component:ModalMetaMaskNotConnectBSC.Title')}
        </ModalHeader>
        <ModalBody textAlign={'center'}>
          <Flex flexDirection="column" alignItems="center">
            <Image boxSize="50%" src={Resources.images.network_switch} marginRight={3} />
            <Text mt={5}>{t('Component:ModalMetaMaskNotConnectBSC.Description')}</Text>
          </Flex>
        </ModalBody>
        <ModalCloseButton />
        <ModalFooter mx={'auto'}>
          {canChangeNetwork && (
            <Button
              isLoading={isLoadingConnectWagmi}
              borderRadius="full"
              minWidth={AppSizes.ButtonMinWidth}
              onClick={onPressChangeNetwork}
              backgroundColor={secondaryButtonBackgroundColor}
            >
              {t('Component:ModalMetaMaskNotConnectBSC.SwitchToBSCNetwork')}
            </Button>
          )}
          {!canChangeNetwork && (
            <Button
              isLoading={isDisconnecting}
              borderRadius="full"
              minWidth={AppSizes.ButtonMinWidth}
              onClick={doDisconnectWagmi}
              backgroundColor={secondaryButtonBackgroundColor}
            >
              {t('Component:ModalMetaMaskNotConnectBSC.Disconnect')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WrongNetworkModal;
