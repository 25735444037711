import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Text, useColorModeValue, Input, Button, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppSizes, Colors } from '../../../shared';
import * as Utils from '../../../common/utils';

interface BaseFieldSettingProps {
  label: string;
  currentValue: string;
  onConfirmChange: (value: string) => void;
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  type?: 'email' | 'text';
}

const BaseFieldSetting = ({
  label,
  currentValue,
  onConfirmChange,
  loading,
  placeholder,
  disabled,
  type,
}: BaseFieldSettingProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>('');
  const textColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const defaultBtnBg = useColorModeValue(Colors.dark.primary, Colors.dark.primary);

  const onPressSaveChange = useCallback(() => {
    onConfirmChange(value);
  }, [onConfirmChange, value]);

  const handleChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  useEffect(() => setValue(currentValue), [currentValue]);

  const dataChanged = useMemo(() => value !== currentValue, [value, currentValue]);

  const isValidInput = useMemo(() => {
    if (!value) {
      return false;
    }
    if (type === 'email') {
      return Utils.isValidEmail(value);
    }
    return true;
  }, [type, value]);

  return (
    <Box>
      <Text color={textColor} mb={2}>
        {label}
      </Text>
      <HStack>
        <Input
          type={type === 'email' ? 'email' : ''}
          value={value}
          onChange={handleChangeInput}
          placeholder={placeholder}
          size="md"
          disabled={disabled || loading}
        />
        <Button
          minWidth={AppSizes.ButtonMinWidth}
          borderRadius={'full'}
          bg={dataChanged ? defaultBtnBg : undefined}
          disabled={!dataChanged || disabled || !isValidInput || loading}
          isLoading={loading}
          onClick={onPressSaveChange}
        >
          {t('Common:SaveChangeBtn')}
        </Button>
      </HStack>
    </Box>
  );
};

export default BaseFieldSetting;
