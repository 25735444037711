// @ts-nocheck
import { MetamaskNetwork } from 'src/metamask-provider';
import * as fts from './sc-abi/fts';

import * as slimeplanet from './sc-abi/slimeplanet';
import * as slimegacha from './sc-abi/slimegacha';
import * as slimeregistry from './sc-abi/slimeregistry';
import * as slimefactory from './sc-abi/slimefactory';
import * as slimenft from './sc-abi/slimenft';
import * as gachabox from './sc-abi/gachabox';
import * as shardfusion from './sc-abi/shardfusion';
import * as shardnft from './sc-abi/shardnft';
import * as genenft from './sc-abi/genenft';
import * as slimemarket from './sc-abi/slimemarket';
import * as toolmaterialmarket from './sc-abi/toolmaterialmarket';
import * as landmarket from './sc-abi/landmarket';
import * as gachaboxmarket from './sc-abi/gachaboxmarket';
import * as genemarket from './sc-abi/genemarket';
import * as toolmarket from './sc-abi/toolmarket';
import * as toolgachafts from './sc-abi/toolgachafts';
import * as toolgachaearly from './sc-abi/toolgachaearly';
import * as landgacha from './sc-abi/landgacha';
import * as nftland from './sc-abi/nftland';
import * as landownernft from './sc-abi/landownernft';
import * as toolupgrade from './sc-abi/toolupgrade';
import * as toolmaterial from './sc-abi/toolmaterial';
import * as toolnft from './sc-abi/toolnft';
import * as landnft from './sc-abi/landnft';
import * as mining from './sc-abi/mining';
import * as planet from './sc-abi/planet';
import * as ftc from './sc-abi/ftc';
import * as slimeaitoken from './sc-abi/slimeaitoken';

export const contractNames = {
  fts: 'fts',
  ftc: 'ftc',
  planet: 'planet',
  mining: 'mining',
  landnft: 'landnft',
  toolnft: 'toolnft',
  toolmaterial: 'toolmaterial',
  toolupgrade: 'toolupgrade',
  landownernft: 'landownernft',
  nftland: 'nftland',
  landgacha: 'landgacha',
  toolgachaearly: 'toolgachaearly',
  toolgachafts: 'toolgachafts',
  toolmarket: 'toolmarket',
  genemarket: 'genemarket',
  gachaboxmarket: 'gachaboxmarket',
  landmarket: 'landmarket',
  toolmaterialmarket: 'toolmaterialmarket',
  slimemarket: 'slimemarket',
  genenft: 'genenft',
  shardnft: 'shardnft',
  shardfusion: 'shardfusion',
  gachabox: 'gachabox',
  slimenft: 'slimenft',
  slimefactory: 'slimefactory',
  slimeregistry: 'slimeregistry',
  slimegacha: 'slimegacha',
  slimeplanet: 'slimeplanet',
  slimeaitoken: 'slimeaitoken',
};

const list = [
  { name: contractNames.fts, cfg: fts },
  { name: contractNames.ftc, cfg: ftc },
  { name: contractNames.planet, cfg: planet },
  { name: contractNames.mining, cfg: mining },
  { name: contractNames.landnft, cfg: landnft },
  { name: contractNames.toolnft, cfg: toolnft },
  { name: contractNames.toolmaterial, cfg: toolmaterial },
  { name: contractNames.toolupgrade, cfg: toolupgrade },
  { name: contractNames.landownernft, cfg: landownernft },
  { name: contractNames.nftland, cfg: nftland },
  { name: contractNames.landgacha, cfg: landgacha },
  { name: contractNames.toolgachaearly, cfg: toolgachaearly },
  { name: contractNames.toolgachafts, cfg: toolgachafts },
  { name: contractNames.toolmarket, cfg: toolmarket },
  { name: contractNames.genemarket, cfg: genemarket },
  { name: contractNames.gachaboxmarket, cfg: gachaboxmarket },
  { name: contractNames.landmarket, cfg: landmarket },
  { name: contractNames.toolmaterialmarket, cfg: toolmaterialmarket },
  { name: contractNames.slimemarket, cfg: slimemarket },
  { name: contractNames.genenft, cfg: genenft },
  { name: contractNames.shardnft, cfg: shardnft },
  { name: contractNames.shardfusion, cfg: shardfusion },
  { name: contractNames.gachabox, cfg: gachabox },
  { name: contractNames.slimenft, cfg: slimenft },
  { name: contractNames.slimefactory, cfg: slimefactory },
  { name: contractNames.slimeregistry, cfg: slimeregistry },
  { name: contractNames.slimegacha, cfg: slimegacha },
  { name: contractNames.slimeplanet, cfg: slimeplanet },
  { name: contractNames.slimeaitoken, cfg: slimeaitoken },
];

const dict = {};
for (let i = 0; i < list.length; ++i) {
  const sc = list[i];
  dict[sc.name] = sc;
}
let contracts = null;

export const lazyInitContracts = async function (ctx, names) {
  if (contracts === null) {
    contracts = {
      lazyInit: this.lazyInitContracts.bind(null, ctx),
    };
  }

  const { networkId, web3 } = ctx;

  for (let i = 0; i < names.length; ++i) {
    const sc = dict[names[i]];
    if (typeof contracts[sc.name] !== 'undefined') continue;

    const address = sc.cfg.addr[networkId];
    const contract = new web3.eth.Contract(sc.cfg.abi, address);
    contracts[sc.name] = { contract, address };
  }

  return contracts;
};

export const getContractInfo = function (name) {
  return dict[name];
};

export const getContractAddress = function (name: string) {
  const networkId = MetamaskNetwork.chainId;
  return dict[name].cfg.addr[networkId];
};

export const getContractAbi = function (name: string) {
  return dict[name].cfg.abi;
};

export const getContractAddressAndAbi = function (name: string) {
  return {
    address: getContractAddress(name),
    abi: getContractAbi(name),
  };
};
