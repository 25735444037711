import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
  readContract,
} from 'wagmi/actions';
import * as contracts from 'src/shared/contracts';
import { TokenFormat } from 'src/metamask-provider/type';

export const addNFTToFixedPriceSaleMarket = async (
  marketContract: string,
  tokenId: number,
  tokenFormat: TokenFormat,
  price: string,
  amount: number
) => {
  let callConfig;
  if (tokenFormat === TokenFormat.ERC721) {
    callConfig = await prepareWriteContract({
      ...contracts.getContractAddressAndAbi(marketContract),
      functionName: 'add',
      args: [tokenId, price],
    });
  } else if (tokenFormat === TokenFormat.ERC1155) {
    callConfig = await prepareWriteContract({
      ...contracts.getContractAddressAndAbi(marketContract),
      functionName: 'add',
      args: [tokenId, price, amount],
    });
  }
  if (!callConfig) {
    throw new Error('Failed to prepare write contract');
  }
  const { hash } = await writeContract(callConfig.request);
  await waitForTransaction({
    hash: hash,
  });
  return hash;
};

export const buyNFTFromFixedSaleMarket = async (marketContract: string, saleId: string) => {
  const callConfig = await prepareWriteContract({
    ...contracts.getContractAddressAndAbi(marketContract),
    functionName: 'buy',
    args: [saleId],
  });
  const { hash } = await writeContract(callConfig.request);
  await waitForTransaction({
    hash: hash,
  });
  return hash;
};

export const getSaleInfoBySaleId = async (
  marketContract: string,
  saleId: string,
  tokenFormat: TokenFormat
) => {
  try {
    const saleInfo: any = await readContract({
      args: [saleId],
      ...contracts.getContractAddressAndAbi(marketContract),
      functionName: 'getSale',
    });
    if (tokenFormat === TokenFormat.ERC721) {
      const [tokenId, price, status, sellTime, buyTime, seller, buyer] = saleInfo;
      if (parseInt(status) === 1) {
        // 1: sale is active
        return {
          isAvailableForSale: true,
          saleId,
          amount: '1',
          tokenId: tokenId.toString(),
          price: price.toString(),
          sellTime: parseInt(sellTime),
        };
      }
      return {
        isAvailableForSale: false,
      };
    } else if (tokenFormat === TokenFormat.ERC1155) {
      const [tokenId, amount, price, status, sellTime, buyTime, seller, buyer] = saleInfo;
      if (parseInt(status) === 1) {
        // 1: sale is active
        return {
          isAvailableForSale: true,
          saleId,
          amount: amount.toString(),
          tokenId: tokenId.toString(),
          price: price.toString(),
          sellTime: parseInt(sellTime),
        };
      }
      return {
        isAvailableForSale: false,
      };
    }
    return {
      isAvailableForSale: false,
    };
  } catch (e) {
    console.error(e);
    return {
      isAvailableForSale: false,
    };
  }
};

export const getFixedPriceSaleInfoByTokenId721 = async (
  marketContract: string,
  tokenId: number
) => {
  const saleId: any = await readContract({
    args: [tokenId],
    ...contracts.getContractAddressAndAbi(marketContract),
    functionName: 'getSaleIdFromTokenId',
  });

  if (saleId.toString() === '0') {
    return {
      isAvailableForSale: false,
    };
  }
  return getSaleInfoBySaleId(marketContract, saleId, TokenFormat.ERC721);
};

export const getFixedPriceSaleInfoByTokenId1155 = async (
  marketContract: string,
  tokenId: number,
  currentAccount: string
) => {
  const saleInfos = [];
  let index = 0;
  while (true) {
    try {
      const [saleId] = (await readContract({
        args: [currentAccount, index.toString(10), '1'],
        ...contracts.getContractAddressAndAbi(marketContract),
        functionName: 'getUserSaleIds',
      })) as any;
      if (saleId.toString() === '0') {
        break;
      }
      const saleInfo = await getSaleInfoBySaleId(marketContract, saleId, TokenFormat.ERC1155);
      if (saleInfo.tokenId === tokenId) {
        saleInfos.push(saleInfo);
      }
      index += 1;
    } catch (e) {
      break;
    }
  }

  return saleInfos;
};

export const removeSaleFromFixedSaleMarket = async (marketContract: string, saleId: string) => {
  const callConfig = await prepareWriteContract({
    ...contracts.getContractAddressAndAbi(marketContract),
    functionName: 'cancel',
    args: [saleId],
  });
  const { hash } = await writeContract(callConfig.request);
  await waitForTransaction({
    hash: hash,
  });
  return hash;
};

export const updateSalePriceOfNFTFromFixedSaleMarket = async (
  marketContract: string,
  saleId: string,
  newPrice: string
) => {
  const callConfig = await prepareWriteContract({
    ...contracts.getContractAddressAndAbi(marketContract),
    functionName: 'setPrice',
    args: [saleId, newPrice],
  });
  const { hash } = await writeContract(callConfig.request);
  await waitForTransaction({
    hash: hash,
  });
  return hash;
};

export const transferNFT = async (
  marketContract: string,
  currentAccount: string,
  toAddress: string,
  tokenId: number,
  amount: number,
  tokenFormat: TokenFormat
) => {
  let callConfig;
  if (tokenFormat === TokenFormat.ERC721) {
    callConfig = await prepareWriteContract({
      ...contracts.getContractAddressAndAbi(marketContract),
      functionName: 'transferFrom',
      args: [currentAccount, toAddress, tokenId],
    });
  } else if (tokenFormat === TokenFormat.ERC1155) {
    callConfig = await prepareWriteContract({
      ...contracts.getContractAddressAndAbi(marketContract),
      functionName: 'safeTransferFrom',
      args: [currentAccount, toAddress, tokenId, amount, '0x'],
    });
  }
  if (!callConfig) {
    throw new Error('Failed to prepare write contract');
  }
  const { hash } = await writeContract(callConfig.request);
  await waitForTransaction({
    hash: hash,
  });
  return hash;
};
