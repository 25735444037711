import { gql } from '@apollo/client';

export const SLIME_METADATA_KEY = 'slimeMetadata';
export const getSlimeMetadataFragment = (key = SLIME_METADATA_KEY) => gql`
  fragment ${key} on SlimeMetadata {
    body
    bodyDetails {
      class
      rarity
      variant
      skill
      customImage
    }
    sub
    subDetails {
      class
      rarity
      variant
      skill
      customImage
    }
    head
    headDetails {
      class
      rarity
      variant
      skill
      customImage
    }
    leftArm
    leftArmDetails {
      class
      rarity
      variant
      skill
      customImage
    }
    rightArm
    rightArmDetails {
      class
      rarity
      variant
      skill
      customImage
    }
    face
    faceDetails {
      class
      rarity
      variant
      customImage
    }
    mythicalSkill
    bodyComboPoints
    subComboPoints
    headComboPoints
    leftArmComboPoints
    rightArmComboPoints
    bodyAttrs
    bodyAttrsDetails {
      hp
      atk
      def
    }
    subAttrs
    subAttrsDetails {
      crit
      evade
    }
    headAttrs
    headAttrsDetails {
      critdmg
      block
    }
    leftArmAttrs
    leftArmAttrsDetails {
      hp
      atk
      def
    }
    rightArmAttrs
    rightArmAttrsDetails {
      hp
      atk
      def
    }
    faceAttrs
    faceAttrsDetails {
      hp
      atk
      def
      crit
      critdmg
      evade
      block
    }
    bodyMods
    subMods
    headMods
    leftArmMods
    rightArmMods
    reserved
  }
`;
