import BN from 'bn.js';
import { readContract } from 'wagmi/actions';
import * as contracts from 'src/shared/contracts';
export const getFTCBalanceWagmi = async (address: string): Promise<BN> => {
  const data = await readContract({
    args: [address],
    ...contracts.getContractAddressAndAbi(contracts.contractNames.ftc),
    functionName: 'balanceOf',
  });
  return new BN(data as any);
};
