import React from 'react';
import { useAuth } from 'src/hooks';
import SwitchNetworkButton from 'src/features/ConnectWallet/components/SwitchNetworkButton';
import MyAccountButton from '../../../components/MyAccountButton';
import LoginButton from '../../../components/LoginButton';

interface ProfileButtonProps {}

const ProfileButton = (props: ProfileButtonProps) => {
  const [signedIn, autoLoginChecked, isWalletConnected, isValidChainId] = useAuth();
  if (!autoLoginChecked) {
    return null;
  }

  if (isWalletConnected && !isValidChainId) {
    return <SwitchNetworkButton />;
  }

  if (signedIn) {
    return <MyAccountButton />;
  }
  return <LoginButton />;
};

export default ProfileButton;
