import {
  FullPartStatPoint,
  FullStatGroup,
  ISlimeBodyAttrsDetails,
  ISlimeBodyDetail,
  ISlimeFaceAttrsDetails,
  ISlimeFaceDetail,
  ISlimeHeadAttrsDetails,
  ISlimeHeadDetail,
  ISlimeLeftArmAttrsDetails,
  ISlimeLeftArmDetail,
  ISlimeMetadata,
  ISlimeRightArmAttrsDetails,
  ISlimeRightArmDetail,
  ISlimeSubAttrsDetails,
  ISlimeSubDetail,
  SlimeClass,
  SlimePart,
} from 'src/gql/types';

import * as coreUtils from 'src/common/coreUtils';

const config = {
  battle_stat_base: {
    health: 50,
    attack: 3,
    defense: 0.75,
    critical_rate: 0.0007,
    critical_damage_ratio: 0.0035,
    block_rate: 0.0015, //0.0014
    evasion_rate: 0.00072,
  },

  default_battle_stats: {
    move_speed: 100,
    attack_speed: 75,
    critical_damage_ratio: 1.5,
    critical_rate: 0.05,
  },

  combo_points_by_set_size: [0, 0, 8, 10, 15, 15],

  attribute_points_by_rarity: [30, 40, 55, 70, 100],

  skill_defense_ratio: 0.25,
};

export const ATK_SPEED = config.default_battle_stats.attack_speed;

export class SlimeInfoMetadata implements ISlimeMetadata {
  body: string;
  bodyAttrs: string;
  bodyAttrsDetails: ISlimeBodyAttrsDetails;
  bodyComboPoints: string;
  bodyDetails: ISlimeBodyDetail;
  bodyMods: string;
  face: string;
  faceAttrs: string;
  faceAttrsDetails: ISlimeFaceAttrsDetails;
  faceDetails: ISlimeFaceDetail;
  head: string;
  headAttrs: string;
  headAttrsDetails: ISlimeHeadAttrsDetails;
  headComboPoints: string;
  headDetails: ISlimeHeadDetail;
  headMods: string;
  leftArm: string;
  leftArmAttrs: string;
  leftArmAttrsDetails: ISlimeLeftArmAttrsDetails;
  leftArmComboPoints: string;
  leftArmDetails: ISlimeLeftArmDetail;
  leftArmMods: string;
  mythicalSkill: string;
  reserved: string;
  rightArm: string;
  rightArmAttrs: string;
  rightArmAttrsDetails: ISlimeRightArmAttrsDetails;
  rightArmComboPoints: string;
  rightArmDetails: ISlimeRightArmDetail;
  rightArmMods: string;
  sub: string;
  subAttrs: string;
  subAttrsDetails: ISlimeSubAttrsDetails;
  subComboPoints: string;
  subDetails: ISlimeSubDetail;
  subMods: string;

  private _fullStatGroup: FullStatGroup | null = null;
  private _partStatPoints: { [key: string]: number } | null = null;
  private _fullPartStatPoints: FullPartStatPoint | null = null;

  constructor(metadata: ISlimeMetadata) {
    this.body = metadata.body;
    this.bodyAttrs = metadata.bodyAttrs;
    this.bodyAttrsDetails = metadata.bodyAttrsDetails;
    this.bodyComboPoints = metadata.bodyComboPoints;
    this.bodyDetails = metadata.bodyDetails;
    this.bodyMods = metadata.bodyMods;
    this.face = metadata.face;
    this.faceAttrs = metadata.faceAttrs;
    this.faceAttrsDetails = metadata.faceAttrsDetails;
    this.faceDetails = metadata.faceDetails;
    this.head = metadata.head;
    this.headAttrs = metadata.headAttrs;
    this.headAttrsDetails = metadata.headAttrsDetails;
    this.headComboPoints = metadata.headComboPoints;
    this.headDetails = metadata.headDetails;
    this.headMods = metadata.headMods;
    this.leftArm = metadata.leftArm;
    this.leftArmAttrs = metadata.leftArmAttrs;
    this.leftArmAttrsDetails = metadata.leftArmAttrsDetails;
    this.leftArmComboPoints = metadata.leftArmComboPoints;
    this.leftArmDetails = metadata.leftArmDetails;
    this.leftArmMods = metadata.leftArmMods;
    this.mythicalSkill = metadata.mythicalSkill;
    this.reserved = metadata.reserved;
    this.rightArm = metadata.rightArm;
    this.rightArmAttrs = metadata.rightArmAttrs;
    this.rightArmAttrsDetails = metadata.rightArmAttrsDetails;
    this.rightArmComboPoints = metadata.rightArmComboPoints;
    this.rightArmDetails = metadata.rightArmDetails;
    this.rightArmMods = metadata.rightArmMods;
    this.sub = metadata.sub;
    this.subAttrs = metadata.subAttrs;
    this.subAttrsDetails = metadata.subAttrsDetails;
    this.subComboPoints = metadata.subComboPoints;
    this.subDetails = metadata.subDetails;
    this.subMods = metadata.subMods;
  }

  get partStatPoints(): { [key: string]: number } {
    if (this._partStatPoints) {
      return this._partStatPoints;
    }
    const result = {
      [SlimePart.Body]: 0,
      [SlimePart.Sub]: 0,
      [SlimePart.Head]: 0,
      [SlimePart.LeftArm]: 0,
      [SlimePart.RightArm]: 0,
      [SlimePart.Face]: 0,
    };
    // count the parts that have the same class
    const classCount = {
      [SlimeClass.Shaman]: 0,
      [SlimeClass.Assassin]: 0,
      [SlimeClass.Saint]: 0,
      [SlimeClass.Wizard]: 0,
      [SlimeClass.Crusader]: 0,
    };
    Object.keys(result).forEach((_part: string) => {
      const part = parseInt(_part);
      // @ts-ignore
      classCount[this[`${coreUtils.mapPartToString(part)}Details`].class] += 1;
    });
    // Get the stat points for each part based on rarity and class
    Object.keys(result).forEach((_part: string) => {
      const part = parseInt(_part);
      // @ts-ignore
      const partRarity = this[`${coreUtils.mapPartToString(part)}Details`].rarity - 1;
      result[part as SlimePart] =
        config.attribute_points_by_rarity[partRarity] +
        config.combo_points_by_set_size[
          // @ts-ignore
          classCount[this[`${coreUtils.mapPartToString(part)}Details`].class] - 1
        ];
    });
    this._partStatPoints = result;
    return result;
  }

  get fullPartStatPoints(): FullPartStatPoint {
    if (this._fullPartStatPoints) {
      return this._fullPartStatPoints;
    }
    const result = new FullPartStatPoint();
    // Body
    result.body.hp.points =
      this.partStatPoints[SlimePart.Body] * (this.bodyAttrsDetails.hp / 100) +
      Number(this.bodyComboPoints);
    result.body.hp.value = this.bodyAttrsDetails.hp;
    result.body.atk.points =
      this.partStatPoints[SlimePart.Body] * (this.bodyAttrsDetails.atk / 100) +
      Number(this.bodyComboPoints);
    result.body.atk.value = this.bodyAttrsDetails.atk;
    result.body.def.points =
      this.partStatPoints[SlimePart.Body] * (this.bodyAttrsDetails.def / 100) +
      Number(this.bodyComboPoints);
    result.body.def.value = this.bodyAttrsDetails.def;
    // Sub
    result.sub.crit.points =
      this.partStatPoints[SlimePart.Sub] * (this.subAttrsDetails.crit / 100) +
      Number(this.subComboPoints);
    result.sub.crit.value = this.subAttrsDetails.crit;
    result.sub.evade.points =
      this.partStatPoints[SlimePart.Sub] * (this.subAttrsDetails.evade / 100) +
      Number(this.subComboPoints);
    result.sub.evade.value = this.subAttrsDetails.evade;
    // Head
    result.head.critdmg.points =
      this.partStatPoints[SlimePart.Head] * (this.headAttrsDetails.critdmg / 100) +
      Number(this.headComboPoints);
    result.head.critdmg.value = this.headAttrsDetails.critdmg;
    result.head.block.points =
      this.partStatPoints[SlimePart.Head] * (this.headAttrsDetails.block / 100) +
      Number(this.headComboPoints);
    result.head.block.value = this.headAttrsDetails.block;
    // Left Arm
    result.leftArm.hp.points =
      this.partStatPoints[SlimePart.LeftArm] * (this.leftArmAttrsDetails.hp / 100) +
      Number(this.leftArmComboPoints);
    result.leftArm.hp.value = this.leftArmAttrsDetails.hp;
    result.leftArm.atk.points =
      this.partStatPoints[SlimePart.LeftArm] * (this.leftArmAttrsDetails.atk / 100) +
      Number(this.leftArmComboPoints);
    result.leftArm.atk.value = this.leftArmAttrsDetails.atk;
    result.leftArm.def.points =
      this.partStatPoints[SlimePart.LeftArm] * (this.leftArmAttrsDetails.def / 100) +
      Number(this.leftArmComboPoints);
    result.leftArm.def.value = this.leftArmAttrsDetails.def;
    // Right Arm
    result.rightArm.hp.points =
      this.partStatPoints[SlimePart.RightArm] * (this.rightArmAttrsDetails.hp / 100) +
      Number(this.rightArmComboPoints);
    result.rightArm.hp.value = this.rightArmAttrsDetails.hp;
    result.rightArm.atk.points =
      this.partStatPoints[SlimePart.RightArm] * (this.rightArmAttrsDetails.atk / 100) +
      Number(this.rightArmComboPoints);
    result.rightArm.atk.value = this.rightArmAttrsDetails.atk;
    result.rightArm.def.points =
      this.partStatPoints[SlimePart.RightArm] * (this.rightArmAttrsDetails.def / 100) +
      Number(this.rightArmComboPoints);
    result.rightArm.def.value = this.rightArmAttrsDetails.def;
    // Face
    result.face.hp.points = this.partStatPoints[SlimePart.Face] * (this.faceAttrsDetails.hp / 100);
    result.face.hp.value = this.faceAttrsDetails.hp;
    result.face.atk.points =
      this.partStatPoints[SlimePart.Face] * (this.faceAttrsDetails.atk / 100);
    result.face.atk.value = this.faceAttrsDetails.atk;
    result.face.def.points =
      this.partStatPoints[SlimePart.Face] * (this.faceAttrsDetails.def / 100);
    result.face.def.value = this.faceAttrsDetails.def;
    result.face.block.points =
      this.partStatPoints[SlimePart.Face] * (this.faceAttrsDetails.block / 100);
    result.face.block.value = this.faceAttrsDetails.block;
    result.face.evade.points =
      this.partStatPoints[SlimePart.Face] * (this.faceAttrsDetails.evade / 100);
    result.face.evade.value = this.faceAttrsDetails.evade;
    result.face.crit.points =
      this.partStatPoints[SlimePart.Face] * (this.faceAttrsDetails.crit / 100);
    result.face.crit.value = this.faceAttrsDetails.crit;
    result.face.critdmg.points =
      this.partStatPoints[SlimePart.Face] * (this.faceAttrsDetails.critdmg / 100);
    result.face.critdmg.value = this.faceAttrsDetails.critdmg;

    this._fullPartStatPoints = result;
    return result;
  }

  get fullStatGroup(): FullStatGroup {
    if (this._fullStatGroup) {
      return this._fullStatGroup;
    }

    const result = new FullStatGroup();

    result.hp.points =
      (this.fullPartStatPoints.body.hp.points +
        this.fullPartStatPoints.leftArm.hp.points +
        this.fullPartStatPoints.rightArm.hp.points +
        this.fullPartStatPoints.face.hp.points) *
      config.battle_stat_base.health;

    result.atk.points =
      (this.fullPartStatPoints.body.atk.points +
        this.fullPartStatPoints.leftArm.atk.points +
        this.fullPartStatPoints.rightArm.atk.points +
        this.fullPartStatPoints.face.atk.points) *
      config.battle_stat_base.attack;

    result.def.points =
      (this.fullPartStatPoints.body.def.points +
        this.fullPartStatPoints.leftArm.def.points +
        this.fullPartStatPoints.rightArm.def.points +
        +this.fullPartStatPoints.face.def.points) *
      config.battle_stat_base.defense;

    result.crit.points =
      ((this.fullPartStatPoints.sub.crit.points + this.fullPartStatPoints.face.crit.points) *
        config.battle_stat_base.critical_rate +
        config.default_battle_stats.critical_rate) *
      100;

    result.critdmg.points =
      ((this.fullPartStatPoints.head.critdmg.points + this.fullPartStatPoints.face.critdmg.points) *
        config.battle_stat_base.critical_damage_ratio +
        config.default_battle_stats.critical_damage_ratio) *
      100;

    result.evade.points =
      (this.fullPartStatPoints.sub.evade.points + this.fullPartStatPoints.face.evade.points) *
      config.battle_stat_base.evasion_rate *
      100;

    result.block.points =
      (this.fullPartStatPoints.head.block.points + this.fullPartStatPoints.face.block.points) *
      config.battle_stat_base.block_rate *
      100;

    result.skillDmgReduction.points = result.def.points * config.skill_defense_ratio;

    this._fullStatGroup = result;
    return result;
  }
}
