import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Input, Text } from '@chakra-ui/react';

interface SearchTextProps {
  placeholder?: string;
  currentValue: string;
  onChange: (value: string) => void;
  title?: string;
}

const SearchText = (props: SearchTextProps) => {
  const { currentValue, onChange, title = '', placeholder = '' } = props;
  const [value, setValue] = useState(currentValue);
  const doSearch = () => {
    onChange(value);
  };
  return (
    <Box pos={'relative'} w={'full'} px={2.5}>
      {!isEmpty(title) && <Text>{title}</Text>}
      <Input
        onChange={(e) => setValue(e.target.value)}
        defaultValue={currentValue}
        onBlur={doSearch}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default SearchText;
