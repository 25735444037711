import BN from 'bn.js';
import { prepareWriteContract, readContract, waitForTransaction, writeContract } from '@wagmi/core';
import * as contracts from 'src/shared/contracts';
import {
  ContractType,
  MAX_TOKEN_NEED_TO_APPROVED,
  MIN_TOKEN_NEED_TO_APPROVED,
  approvalRelation,
} from 'src/globals/constants';
import { coreUtils } from 'src/common';

const getHasApprovedERC20TokenToContract = async (
  currentAccount: string,
  tokenContract: string,
  operatorContract: string
) => {
  const allowance = await readContract({
    args: [currentAccount, contracts.getContractAddress(operatorContract)],
    ...contracts.getContractAddressAndAbi(tokenContract),
    functionName: 'allowance',
  });
  return new BN(allowance as any).gt(MIN_TOKEN_NEED_TO_APPROVED);
};

const setApprovalStatusERC20TokenToContract = async (
  tokenContract: string,
  operatorContract: string,
  approved: boolean
) => {
  const callConfig = await prepareWriteContract({
    ...contracts.getContractAddressAndAbi(tokenContract),
    functionName: 'approve',
    args: [
      contracts.getContractAddress(operatorContract),
      approved ? MAX_TOKEN_NEED_TO_APPROVED : coreUtils.toBN('0'),
    ],
  });

  const { hash } = await writeContract(callConfig.request);
  await waitForTransaction({
    hash: hash,
  });
  return hash;
};

const getHasApprovedNFTTokenToContract = async (
  currentAccount: string,
  nftContract: string,
  operatorContract: string
) => {
  const allowance = await readContract({
    args: [currentAccount, contracts.getContractAddress(operatorContract)],
    ...contracts.getContractAddressAndAbi(nftContract),
    functionName: 'isApprovedForAll',
  });
  return Boolean(allowance);
};

const setApprovalStatusNFTTokenToContract = async (
  nftContract: string,
  operatorContract: string,
  approved: boolean
) => {
  const callConfig = await prepareWriteContract({
    ...contracts.getContractAddressAndAbi(nftContract),
    functionName: 'setApprovalForAll',
    args: [contracts.getContractAddress(operatorContract), approved],
  });

  const { hash } = await writeContract(callConfig.request);
  await waitForTransaction({
    hash: hash,
  });
  return hash;
};

export const getApprovalStatus = async (currentAccount: string, approvalRelationKey: string) => {
  const { allowanceContractType, allowanceContract, operatorContract } =
    approvalRelation[approvalRelationKey];
  switch (allowanceContractType) {
    case ContractType.ERC20:
      return getHasApprovedERC20TokenToContract(
        currentAccount,
        allowanceContract,
        operatorContract
      );
    case ContractType.ERC721:
    case ContractType.ERC1155:
      return getHasApprovedNFTTokenToContract(currentAccount, allowanceContract, operatorContract);
    default:
      throw new Error(`Invalid annotation type ${allowanceContractType}`);
  }
};

export const setApprovalStatus = async (approvalRelationKey: string, approved: boolean) => {
  const { allowanceContractType, allowanceContract, operatorContract } =
    approvalRelation[approvalRelationKey];
  let callFunction;
  switch (allowanceContractType) {
    case ContractType.ERC20:
      callFunction = setApprovalStatusERC20TokenToContract;
      break;
    case ContractType.ERC721:
    case ContractType.ERC1155:
      callFunction = setApprovalStatusNFTTokenToContract;
      break;
    default:
      throw new Error(`Invalid annotation type ${allowanceContractType}`);
  }
  await callFunction(allowanceContract, operatorContract, approved);
};
