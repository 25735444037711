export type WalletAccount = {
  address: string;
  chainId: number;
};

export interface WagmiAccount extends WalletAccount {}
export interface MetaMaskAccount extends WalletAccount {}

export enum WalletType {
  WAGMI_METAMASK = 'wagmi_metamask',
  WAGMI = 'wagmi',
}
