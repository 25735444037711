import { gql } from '@apollo/client';

export const GetUserActivitiesQuery = gql`
  query GetUserActivities($pagination: PaginationInput!, $user: String!) {
    getUserActivities(pagination: $pagination, user: $user) {
      records {
        time
        eventName
        user
        contractAddress
        item {
          ... on Land {
            _id
            tokenId
            templateId
            owner
            totalAmount
            onSaleAmount
            onLockAmount
            lockMeta {
              lockBy
            }
            format
            type
            metadata {
              templateId
              name
              supply
              weight
              cd
              mp
              luck
              tax
            }
          }
          ... on MiningTool {
            _id
            tokenId
            templateId
            owner
            totalAmount
            onSaleAmount
            onLockAmount
            lockMeta {
              lockBy
            }
            format
            type
            metadata {
              templateId
              name
              cat
              rarity
              supply
              weight
              cd
              mp
              luck
            }
          }
          ... on Gene {
            _id
            tokenId
            templateId
            owner
            totalAmount
            onSaleAmount
            onLockAmount
            lockMeta {
              lockBy
            }
            format
            type
            metadata {
              templateId
              name
              geneClass
              part
              rarity
              supply
              weight
              extractedData {
                origin
                part
                class
                rarity
                variant
                skill
                hp
                atk
                def
                crit
                evade
                critdmg
                block
                comboPoints
              }
            }
          }
          ... on GachaBox {
            _id
            tokenId
            templateId
            owner
            totalAmount
            onSaleAmount
            onLockAmount
            lockMeta {
              lockBy
            }
            format
            type
          }
          ... on ToolMaterial {
            _id
            tokenId
            templateId
            owner
            totalAmount
            onSaleAmount
            onLockAmount
            lockMeta {
              lockBy
            }
            format
            type
          }
          ... on Slime {
            _id
            tokenId
            templateId
            owner
            totalAmount
            onSaleAmount
            onLockAmount
            lockMeta {
              lockBy
            }
            format
            type
            metadata {
              body
              bodyDetails {
                class
                rarity
                variant
                skill
              }
              sub
              subDetails {
                class
                rarity
                variant
                skill
              }
              head
              headDetails {
                class
                rarity
                variant
                skill
              }
              leftArm
              leftArmDetails {
                class
                rarity
                variant
                skill
              }
              rightArm
              rightArmDetails {
                class
                rarity
                variant
                skill
              }
              face
              faceDetails {
                class
                rarity
                variant
              }
              mythicalSkill
              bodyComboPoints
              subComboPoints
              headComboPoints
              leftArmComboPoints
              rightArmComboPoints
              bodyAttrs
              bodyAttrsDetails {
                hp
                atk
                def
              }
              subAttrs
              subAttrsDetails {
                crit
                evade
              }
              headAttrs
              headAttrsDetails {
                critdmg
                block
              }
              leftArmAttrs
              leftArmAttrsDetails {
                hp
                atk
                def
              }
              rightArmAttrs
              rightArmAttrsDetails {
                hp
                atk
                def
              }
              faceAttrs
              faceAttrsDetails {
                hp
                atk
                def
                crit
                critdmg
                evade
                block
              }
              bodyMods
              subMods
              headMods
              leftArmMods
              rightArmMods
              reserved
            }
          }
        }
        transaction {
          blockNumber
          transactionIndex
          transactionHash
          price {
            origin
            originalCurrency
            usdt
            bnb
            eth
            btc
          }
        }
        rawPayload {
          ... on Approval {
            owner
            approved
            tokenId
          }
          ... on ApprovalForAll {
            owner
            operator
            approved
          }
          ... on Transfer {
            from
            to
            tokenId
          }
          ... on CreateTemplate {
            templateId
            supplyLimit
            weight
            rarity
          }
          ... on ChangeTax {
            tokenId
            tax
            landowner
          }
          ... on SaleAdded {
            seller
            id
            tokenId
            amount
            price
            timestamp
          }
          ... on SaleCanceled {
            seller
            id
            tokenId
            timestamp
          }
          ... on SaleDone {
            seller
            buyer
            id
            tokenId
            amount
            price
            timestamp
          }
          ... on SalePriceChanged {
            seller
            id
            price
            timestamp
          }
          ... on TransferSingle {
            operator
            from
            to
            id
            value
          }
          ... on TransferBatch {
            operator
            from
            to
            ids
            values
          }
          ... on ModifySlime {
            user
            slimeTokenId
            geneTokenId
          }
          ... on RegisterSlime {
            owner
            slimeTokenId
            slotIndex
          }
          ... on UnregisterSlime {
            owner
            slimeTokenId
            slotIndex
          }
        }
      }
      total
    }
  }
`;
