import { useCallback, useState } from 'react';
import * as accountServices from 'src/services/accountServices';

const useAutoCreateUserByWalletAddress = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [hasCallRegister, setHasCallRegister] = useState<boolean>(false);
  const register = useCallback(async (walletAddress: string) => {
    setIsUpdating(true);
    try {
      await accountServices.register(walletAddress);
      setHasCallRegister(true);
    } catch (error) {
    } finally {
      setIsUpdating(false);
    }
  }, []);
  return [hasCallRegister, isUpdating, register];
};

export default useAutoCreateUserByWalletAddress;
