import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { INFT, TNFTType } from 'src/gql/types';
import useMyNFTs from 'src/hooks/useMyNFTs';
import { getEmptyMessageByNFTType, getInventoryPathByNFTType } from '../common';
import Listing from './Listing';
import NFTItem from './NFTItem';

type MyNFTListProps = {
  ownerId?: string;
  nftType: TNFTType;
};

const MyNFTList = (props: MyNFTListProps) => {
  const { ownerId, nftType } = props;
  const {
    myNFTsQuery: { isFetching: isGettingNFTs, data: myNfts },
  } = useMyNFTs({
    nftType,
    ownerId,
  });
  const listOfNFTs = useMemo(() => myNfts?.records || [], [myNfts]);
  const { t } = useTranslation();

  return (
    <Listing
      emptyDescription={t(getEmptyMessageByNFTType(nftType))}
      loading={isGettingNFTs}
      data={listOfNFTs}
      renderItem={({ item, index }) => (
        <NFTItem
          key={`${item.tokenId}-${index}`}
          item={item as INFT}
          nftType={nftType}
          rootPath={getInventoryPathByNFTType(nftType)}
        />
      )}
    />
  );
};

export default MyNFTList;
