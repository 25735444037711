import { IRequestFilterBase } from 'src/globals/types';
import isNil from 'lodash/isNil';
import has from 'lodash/has';
import map from 'lodash/map';
import round from 'lodash/round';

export const hasRequestFilterAttribute = (
  filterBase: IRequestFilterBase,
  attribute: string
): boolean => {
  if (isNil(filterBase)) return false;
  return has(filterBase, attribute);
};

export const roundValues = (values: number[]) => map(values, (v: number) => round(v, 2));
