import { TNFTType } from 'src/gql/types';
import * as GQLApi from 'src/gql';
import { QueryKeys } from 'src/globals/constants';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash/isNil';

const useSellingNftDetails = (props: { tokenType: TNFTType; saleId?: string }) => {
  const sellingNftDetails = useQuery(
    [QueryKeys.currentSellingNft, props.tokenType, props.saleId],
    () => {
      if (isNil(props.saleId)) {
        throw new Error('saleId is required');
      }
      return GQLApi.getFixedPriceSaleDetailByNFTType(props.tokenType, props.saleId);
    },
    {
      enabled: !isNil(props.saleId),
    }
  );
  return {
    sellingNftDetails,
  };
};

export default useSellingNftDetails;
