import { Button, Text, useDisclosure, usePrevious } from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import isFunction from 'lodash/isFunction';
import useErrorModal from 'src/features/errorModal/hooks/useErrorModal';
import { INFT, TNFTType } from 'src/gql/types';
import { useAuth, useIsMounted } from 'src/hooks';
import useTransferNFTAsGift from '../hooks/useTransferNFTAsGift';
import TransferNFTModal from './TransferNFTModal';
import { handleMetamaskError } from 'src/common/utils';

interface IGiftButtonProps {
  tokenId: number;
  isLoading?: boolean;
  isDisabled?: boolean;
  nftType: TNFTType;
  currentNFT?: INFT;
  onGiftDone?: (txid: string) => void;
}

const GiftButton = ({
  tokenId,
  isLoading,
  onGiftDone,
  nftType,
  currentNFT,
  isDisabled,
}: IGiftButtonProps) => {
  const { t } = useTranslation();
  const [openErrorModal] = useErrorModal();
  const isMounted = useIsMounted();

  const [signedIn] = useAuth();

  const [isTransferring, onTransferNFT, transferNFTerror] = useTransferNFTAsGift(nftType);

  const {
    isOpen: isOpenTransferModal,
    onOpen: onOpenTransferModal,
    onClose: onCloseTransferModal,
  } = useDisclosure();

  const onClickGiftButton = useCallback(() => {
    onOpenTransferModal();
  }, [onOpenTransferModal]);

  const isButtonLoading = !!isTransferring || !!isLoading;
  const isButtonDisabled = !signedIn || isButtonLoading || !!isDisabled;

  let buttonLoadingText;
  if (isTransferring) {
    buttonLoadingText = t('Component:Gift.GiftButtonLoading');
  }

  const onTransfer = useCallback(
    (receiverAddress: string, amount: number) => {
      try {
        if (isFunction(onTransferNFT)) {
          onTransferNFT(receiverAddress, tokenId, amount, onGiftDone);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [onTransferNFT, tokenId, onGiftDone]
  );

  const prevIsAddingMiningTool = usePrevious(isTransferring);
  useEffect(() => {
    if (!isMounted()) return;
    if (prevIsAddingMiningTool && !isTransferring && !transferNFTerror) {
      onCloseTransferModal();
    }
  }, [isMounted, prevIsAddingMiningTool, isTransferring, onCloseTransferModal, transferNFTerror]);

  const prevAddMiningToolToFixedPriceSaleErrorMessage = usePrevious<string | undefined>(
    transferNFTerror as string
  );
  useEffect(() => {
    if (!isMounted()) return;
    if (prevAddMiningToolToFixedPriceSaleErrorMessage !== transferNFTerror && transferNFTerror) {
      if (isFunction(openErrorModal) && handleMetamaskError(transferNFTerror))
        openErrorModal(t(handleMetamaskError(transferNFTerror) as string));
    }
  }, [
    isMounted,
    prevAddMiningToolToFixedPriceSaleErrorMessage,
    transferNFTerror,
    openErrorModal,
    t,
  ]);

  return (
    <>
      <Button
        isLoading={isButtonLoading}
        loadingText={buttonLoadingText}
        borderRadius="full"
        onClick={onClickGiftButton}
        disabled={isButtonDisabled}
      >
        <Text>{t('Common:Gift')}</Text>
      </Button>
      {isOpenTransferModal && (
        <TransferNFTModal
          isOpen={isOpenTransferModal}
          onClose={onCloseTransferModal}
          currentNFT={currentNFT}
          onConfirm={onTransfer}
          isLoading={isTransferring as boolean}
        />
      )}
    </>
  );
};

export default GiftButton;
