import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { IRequestFilterBase } from 'src/globals/types';
import { QueryString } from 'src/globals/constants';
import { hasRequestFilterAttribute } from '../common';

const useFilterData = <S>(
  initialState: S | (() => S),
  filterBase: IRequestFilterBase,
  query: string
): [S, boolean, (value: S) => void, (value?: S) => void] => {
  const [filteringData, setFilteringData] = useState<S>(initialState);

  const { pathname, search } = useLocation();
  const queryParams = queryString.parse(search);
  const history = useHistory();
  const hasQuery = hasRequestFilterAttribute(filterBase as IRequestFilterBase, query);
  const updateFilteringData = useCallback(
    (value: S) => {
      let newQueryParams = omit(queryParams, [query, QueryString.PAGE]);
      if (hasQuery && !isEmpty(value) && !isEqual(value, initialState)) {
        newQueryParams = {
          ...newQueryParams,
          [query]: value,
        };
      }
      setFilteringData(value);
      const querySearch = queryString.stringify(newQueryParams);
      history.push(`${pathname}?${querySearch}`);
    },
    [history, pathname, queryParams, query, hasQuery, initialState]
  );

  const resetFilteringData = useCallback(
    (value?: S) => {
      setFilteringData(value || initialState);
    },
    [initialState]
  );
  return [filteringData, hasQuery, updateFilteringData, resetFilteringData];
};

export default useFilterData;
