import { MenuLabel, NFTMenuLabel, RoutePath } from 'src/globals/constants';
import { INavigationLink } from './types';

export const MainLinks: INavigationLink[] = [
  {
    label: MenuLabel.DASHBOARD,
    route: RoutePath.DASHBOARD,
    activeOnlyWhenExact: true,
  },
  {
    label: MenuLabel.MARKETPLACE,
    route: RoutePath.SLIME,
    activeOnlyWhenExact: false,
    matchRoutes: [
      RoutePath.MINING_TOOL,
      RoutePath.GENE,
      RoutePath.SLIME,
      RoutePath.LAND,
      RoutePath.TOOL_MATERIAL,
      RoutePath.GACHA_BOX,
    ],
  },
];

export const ProfileLinks: INavigationLink[] = [
  {
    label: MenuLabel.INVENTORY,
    route: RoutePath.INVENTORY_SLIME,
    activeOnlyWhenExact: false,
    matchRoutes: [
      RoutePath.INVENTORY_MINING_TOOL,
      RoutePath.INVENTORY_TOOL_MATERIAL,
      RoutePath.INVENTORY_GENE,
      RoutePath.INVENTORY_SLIME,
      RoutePath.INVENTORY_GACHA_BOX,
      RoutePath.INVENTORY_LAND,
    ],
  },
  {
    label: MenuLabel.ACTIVITY,
    route: RoutePath.USER_ACTIVITY,
    activeOnlyWhenExact: true,
  },
  {
    label: MenuLabel.APPROVAL_SETTINGS,
    route: RoutePath.APPROVAL_SETTINGS,
    activeOnlyWhenExact: true,
  },
  {
    label: MenuLabel.ACCOUNT_SETTINGS,
    route: RoutePath.ACCOUNT_SETTINGS,
    activeOnlyWhenExact: true,
  },
];

export const NFTMenuLinks: INavigationLink[] = [
  // {
  //   label: NFTMenuLabel.MINING_TOOL,
  //   route: RoutePath.MINING_TOOL,
  //   activeOnlyWhenExact: false,
  // },
  // {
  //   label: NFTMenuLabel.TOOL_MATERIAL,
  //   route: RoutePath.TOOL_MATERIAL,
  //   activeOnlyWhenExact: false,
  // },
  // {
  //   label: NFTMenuLabel.GENE,
  //   route: RoutePath.GENE,
  //   activeOnlyWhenExact: false,
  // },
  {
    label: NFTMenuLabel.SLIME,
    route: RoutePath.SLIME,
    activeOnlyWhenExact: false,
  },
  // {
  //   label: NFTMenuLabel.GACHA_BOX,
  //   route: RoutePath.GACHA_BOX,
  //   activeOnlyWhenExact: false,
  // },
  // {
  //   label: NFTMenuLabel.LAND,
  //   route: RoutePath.LAND,
  //   activeOnlyWhenExact: false,
  // },
];

export const MyNFTMenuLinks: INavigationLink[] = [
  // {
  //   label: NFTMenuLabel.MINING_TOOL,
  //   route: RoutePath.INVENTORY_MINING_TOOL,
  //   activeOnlyWhenExact: false,
  // },
  // {
  //   label: NFTMenuLabel.TOOL_MATERIAL,
  //   route: RoutePath.INVENTORY_TOOL_MATERIAL,
  //   activeOnlyWhenExact: false,
  // },
  // {
  //   label: NFTMenuLabel.GENE,
  //   route: RoutePath.INVENTORY_GENE,
  //   activeOnlyWhenExact: false,
  // },
  {
    label: NFTMenuLabel.SLIME,
    route: RoutePath.INVENTORY_SLIME,
    activeOnlyWhenExact: false,
  },
  // {
  //   label: NFTMenuLabel.GACHA_BOX,
  //   route: RoutePath.INVENTORY_GACHA_BOX,
  //   activeOnlyWhenExact: false,
  // },
  // {
  //   label: NFTMenuLabel.LAND,
  //   route: RoutePath.INVENTORY_LAND,
  //   activeOnlyWhenExact: false,
  // },
];
