import { Box, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NFTMenuLabel } from 'src/globals/constants';
import { appUtils } from 'src/common';

interface Props {
  label: NFTMenuLabel;
}

const MobileNavContent: React.FC<Props> = (props) => {
  const { label } = props;

  const icon = appUtils.getNFTIconByLabel(label);

  const { t } = useTranslation();

  return (
    <Box
      fontWeight="bold"
      pos="relative"
      minW={120}
      py={2}
      px={1}
      justifyContent="center"
      alignItems="center"
      display="inline-flex"
    >
      {Boolean(icon) && <Image src={icon} width="26px" height="26px" alt="icon" mr={2} />}
      {t(label)}
    </Box>
  );
};

export default MobileNavContent;
