import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RESOURCE_URL } from '../globals/configs';

const requestAuthInterceptor = (req: AxiosRequestConfig): AxiosRequestConfig => {
  // TODO: handle auth token here
  return req;
};

const responseRejectInterceptor = (res: any): AxiosResponse => {
  throw res;
};

const responseFulfilledInterceptor = (res: any): AxiosResponse => {
  return res;
};

const instance = axios.create({
  baseURL: RESOURCE_URL,
});

instance.interceptors.request.use(requestAuthInterceptor);
instance.interceptors.response.use(responseFulfilledInterceptor, responseRejectInterceptor);

export default instance;
