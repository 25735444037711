import React from 'react';
import { Image, ButtonProps, forwardRef } from '@chakra-ui/react';
import { AppSizes, Resources } from 'src/shared';
import ButtonWithHover from '../Button/ButtonWithHover';

const FilterButton = forwardRef((props: ButtonProps, ref) => {
  const { ...remainingProps } = props;

  return (
    <ButtonWithHover ref={ref} {...remainingProps}>
      <Image boxSize={AppSizes.MenuIcon} objectFit="cover" src={Resources.svgs.filter} />
    </ButtonWithHover>
  );
});

export default FilterButton;
