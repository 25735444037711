import { configureChains, createConfig, createStorage } from 'wagmi';
import { polygonMumbai, polygon } from '@wagmi/core/chains';
import { alchemyProvider } from '@wagmi/core/providers/alchemy';
import { publicProvider } from '@wagmi/core/providers/public';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { Web3Modal } from '@web3modal/html';
import { getConfig } from 'src/globals/env-config';

const chains = [polygonMumbai, polygon];

export const metaMaskConnectorInstance = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: true,
  },
});

const { publicClient, webSocketPublicClient } = configureChains(chains, [
  w3mProvider({ projectId: getConfig('REACT_APP_WALLET_CONNECT_PROJECT_ID') }),
  publicProvider(),
  alchemyProvider({
    apiKey: getConfig('REACT_APP_ALCHEMY_KEY'),
  }),
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors: w3mConnectors({
    projectId: getConfig('REACT_APP_WALLET_CONNECT_PROJECT_ID'),
    chains,
  }).concat(metaMaskConnectorInstance),
  logger: {
    warn: (message) => console.warn(message),
  },
  storage: createStorage({ storage: window.localStorage }),
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);
export const web3Modal = new Web3Modal(
  {
    projectId: getConfig('REACT_APP_WALLET_CONNECT_PROJECT_ID'),
    walletImages: {
      safe: '/src/assets/game_title.webp',
    },
  },
  ethereumClient
);
