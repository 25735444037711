import React from 'react';
import { Box, BoxProps, Tooltip, useColorModeValue, Text } from '@chakra-ui/react';
import { Colors } from 'src/shared';
import { useTranslation } from 'react-i18next';

interface SaleQuantityLabelProps extends BoxProps {
  saleQuantity?: number;
  totalQuantity?: number;
}

const SaleQuantityLabel = (props: SaleQuantityLabelProps) => {
  const { totalQuantity, saleQuantity, ...remainingProps } = props;
  const { t } = useTranslation();
  const bgColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const color = useColorModeValue(Colors.light.quantity, Colors.dark.quantity);
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const isQuantityValid = !!totalQuantity && Number(totalQuantity) > 0;
  const quantityText = t('Component:SaleQuantityLabel.Hint', { saleQuantity, totalQuantity });
  return (
    <Tooltip isDisabled={!isQuantityValid} label={quantityText} hasArrow>
      <Box
        px="10px"
        py="3px"
        height="inherit"
        minWidth="60px"
        bg={bgColor}
        rounded="full"
        alignSelf="flex-start"
        fontWeight="bold"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        textAlign="center"
        {...remainingProps}
      >
        <Text as="span" color={textColor}>
          {`${t('Component:SaleQuantityLabel.SaleQuantity.Shortened')}: `}
        </Text>
        <Text as="span" color={color}>
          {saleQuantity || 0}
        </Text>
        <Text as="span" color={textColor}>
          {` / ${totalQuantity || 0}`}
        </Text>
      </Box>
    </Tooltip>
  );
};

export default SaleQuantityLabel;
