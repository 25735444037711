export enum OrderByDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type OrderByDirectionType = 'asc' | 'desc';

export enum OrderByKey {
  INDEX = 'index',
  UPDATED_AT = 'updatedAt',
  RARITY = 'rarity',
}

export interface IPaginationInput {
  limit: number;
  skip: number;
}

export interface IOrderByKeyInput {
  [OrderByKey.INDEX]?: OrderByDirectionType;
  [OrderByKey.UPDATED_AT]?: OrderByDirectionType;
  [OrderByKey.RARITY]?: OrderByDirectionType;
}

export interface IMarketPrice {
  origin: string;
  originalCurrency: string;
  usdt: string;
  bnb: string;
  eth: string;
  btc: string;
}

export interface ITokenIdDetail {
  index: number;
  templateId: string;
  contractId: string;
}

export interface IUserMeta {
  address: string;
  country: string;
  ip: string;
}

export interface IMiningToolMetadata {
  templateId: number;
  name: string;
  cat: number;
  rarity: number;
  supply: number;
  weight: number;
  cd: number;
  mp: number;
  luck: number;
}

export interface ILandMetadata {
  templateId: number;
  name: string;
  supply: number;
  weight: number;
  cd: number;
  mp: number;
  luck: number;
}

export interface IGenePartAttrsDetails {
  hp: number;
  atk: number;
  def: number;
  variant: number;
}

export interface IGeneHeadAttrsDetails {
  critdmg: number;
  block: number;
  variant: number;
}

export interface IGeneSubAttrsDetails {
  crit: number;
  evade: number;
  variant: number;
}

export interface IGeneExtractedData {
  origin?: string;
  part?: number;
  class?: number;
  rarity?: number;
  variant?: number;
  skill?: number;
  hp?: number;
  atk?: number;
  def?: number;
  crit?: number;
  evade?: number;
  critdmg?: number;
  block?: number;
  comboPoints?: number;
}

export interface IGeneMetadata {
  extractedData?: IGeneExtractedData;
  templateId: number;
  name: string;
  supply: number;
  weight: number;
  geneClass: number;
  part: number;
  rarity: number;
}

export enum SlimePart {
  Body = 1,
  Sub = 2,
  Head = 3,
  LeftArm = 4,
  RightArm = 5,
  Face = 6,
}

export enum SlimeClass {
  Common = 1,
  Crusader = 2,
  Wizard = 3,
  Assassin = 4,
  Saint = 5,
  Shaman = 6,
}

export interface ISlimePartDetail {
  class: number;
  rarity: number;
  variant: number;
  skill: number;
  customImage?: string;
}

export interface ISlimeBodyDetail extends ISlimePartDetail {}

export interface ISlimeSubDetail extends ISlimePartDetail {}

export interface ISlimeHeadDetail extends ISlimePartDetail {}

export interface ISlimeLeftArmDetail extends ISlimePartDetail {}

export interface ISlimeRightArmDetail extends ISlimePartDetail {}

export interface ISlimeFaceDetail {
  class: number;
  rarity: number;
  variant: number;
}

export interface ISlimePartAttrsDetails {
  hp: number;
  atk: number;
  def: number;
}

export interface ISlimeBodyAttrsDetails extends ISlimePartAttrsDetails {}

export interface ISlimeLeftArmAttrsDetails extends ISlimePartAttrsDetails {}

export interface ISlimeRightArmAttrsDetails extends ISlimePartAttrsDetails {}

export interface ISlimeSubAttrsDetails {
  crit: number;
  evade: number;
}

export interface ISlimeHeadAttrsDetails {
  critdmg: number;
  block: number;
}

export interface ISlimeFaceAttrsDetails {
  hp: number;
  atk: number;
  def: number;
  crit: number;
  critdmg: number;
  evade: number;
  block: number;
}
export interface SkillInfo {
  skillType: SkillType;
  klass: number;
  skillIndex: number;
}

export class Stat {
  points: number;
  value: number;

  constructor(points: number, value: number) {
    this.points = points;
    this.value = value;
  }
}

export interface IStandardPartStat {
  hp: Stat;
  atk: Stat;
  def: Stat;
}

export interface IBodyPartStat extends IStandardPartStat {}
export interface ILeftArmPartStat extends IStandardPartStat {}
export interface IRightArmPartStat extends IStandardPartStat {}
export interface ISubPartStat {
  crit: Stat;
  evade: Stat;
}
export interface IHeadPartStat {
  critdmg: Stat;
  block: Stat;
}
export interface IFacePartStat extends IStandardPartStat, ISubPartStat, IHeadPartStat {}

export class FullStatGroup {
  hp: Stat;
  atk: Stat;
  def: Stat;
  crit: Stat;
  critdmg: Stat;
  evade: Stat;
  block: Stat;
  skillDmgReduction: Stat;

  constructor(
    hp?: Stat,
    atk?: Stat,
    def?: Stat,
    crit?: Stat,
    critdmg?: Stat,
    evade?: Stat,
    block?: Stat,
    skillDmgReduction?: Stat
  ) {
    this.hp = hp || new Stat(0, 0);
    this.atk = atk || new Stat(0, 0);
    this.def = def || new Stat(0, 0);
    this.crit = crit || new Stat(0, 0);
    this.critdmg = critdmg || new Stat(0, 0);
    this.evade = evade || new Stat(0, 0);
    this.block = block || new Stat(0, 0);
    this.skillDmgReduction = skillDmgReduction || new Stat(0, 0);
  }
}

export class FullPartStatPoint {
  body: IBodyPartStat;
  sub: ISubPartStat;
  head: IHeadPartStat;
  leftArm: ILeftArmPartStat;
  rightArm: IRightArmPartStat;
  face: IFacePartStat;

  constructor(
    body?: IBodyPartStat,
    sub?: ISubPartStat,
    head?: IHeadPartStat,
    leftArm?: ILeftArmPartStat,
    rightArm?: IRightArmPartStat,
    face?: IFacePartStat
  ) {
    this.body = body || { hp: new Stat(0, 0), atk: new Stat(0, 0), def: new Stat(0, 0) };
    this.sub = sub || { crit: new Stat(0, 0), evade: new Stat(0, 0) };
    this.head = head || { critdmg: new Stat(0, 0), block: new Stat(0, 0) };
    this.leftArm = leftArm || { hp: new Stat(0, 0), atk: new Stat(0, 0), def: new Stat(0, 0) };
    this.rightArm = rightArm || { hp: new Stat(0, 0), atk: new Stat(0, 0), def: new Stat(0, 0) };
    this.face = face || {
      hp: new Stat(0, 0),
      atk: new Stat(0, 0),
      def: new Stat(0, 0),
      crit: new Stat(0, 0),
      critdmg: new Stat(0, 0),
      evade: new Stat(0, 0),
      block: new Stat(0, 0),
    };
  }
}

export interface ISlimeMetadata {
  body: string;
  bodyDetails: ISlimeBodyDetail;
  sub: string;
  subDetails: ISlimeSubDetail;
  head: string;
  headDetails: ISlimeHeadDetail;
  leftArm: string;
  leftArmDetails: ISlimeLeftArmDetail;
  rightArm: string;
  rightArmDetails: ISlimeRightArmDetail;
  face: string;
  faceDetails: ISlimeFaceDetail;

  mythicalSkill: string;

  bodyComboPoints: string;
  subComboPoints: string;
  headComboPoints: string;
  leftArmComboPoints: string;
  rightArmComboPoints: string;

  bodyAttrs: string;
  bodyAttrsDetails: ISlimeBodyAttrsDetails;
  subAttrs: string;
  subAttrsDetails: ISlimeSubAttrsDetails;
  headAttrs: string;
  headAttrsDetails: ISlimeHeadAttrsDetails;
  leftArmAttrs: string;
  leftArmAttrsDetails: ISlimeLeftArmAttrsDetails;
  rightArmAttrs: string;
  rightArmAttrsDetails: ISlimeRightArmAttrsDetails;
  faceAttrs: string;
  faceAttrsDetails: ISlimeFaceAttrsDetails;
  bodyMods: string;
  subMods: string;
  headMods: string;
  leftArmMods: string;
  rightArmMods: string;
  reserved: string;
}

export enum SkillType {
  PASSIVE,
  ACTIVE,
  MYTHICAL,
}

export interface INFT {
  _id: string;
  tokenId: number;
  templateId: number;
  owner: string;
  format: string;
  type: string;
  metadata: TNFTMetadata;
  totalAmount: number;
  onSaleAmount: number;
  onLockAmount?: number;
  lockMeta?: {
    lockBy: string;
  };
  salePrice: string;
  priceCurrency: string;
  createdAt: number;
  updatedAt: number;
  updatedAtBlockTimestamp: number;
  createdAtBlockTimestamp: number;
}

export interface IMiningTool extends INFT {
  metadata: IMiningToolMetadata;
}

export interface ILand extends INFT {
  metadata: ILandMetadata;
}

export interface IGene extends INFT {
  metadata: IGeneMetadata;
}

export interface IStandardVariable {
  pagination: IPaginationInput;
}

export interface IFilter {
  search?: string;
  cat?: number[];
  rarity?: number[];
  mp?: number[];
  cd?: number[];
  luck?: number[];
  geneClass?: number[];
  part?: number[];
  class?: number[];
}

export interface IAppVariable extends IStandardVariable {
  type?: TNFTType;
  owner?: string;
  filter?: IFilter;
  orderBy?: IOrderByKeyInput;
}

export interface INFTVariable extends IStandardVariable {
  type?: TNFTType;
  owner?: string;
  filter?: IFilter;
  orderBy?: INFTOrderByType;
}

export interface IFixedPriceSaleNFTVariable extends IStandardVariable {
  type?: TNFTType;
  filter?: INFTFilterByType;
  status?: TFixedPriceSaleStatus;
  orderBy?: INFTOrderByType;
}

export interface IMyNFTDetailVariable {
  tokenId: number;
  owner: string;
  type: TNFTType;
}

export type TFixedPriceSaleStatus = 'CANCELED' | 'AVAILABLE' | 'BOUGHT';
export type TNFTType = 'Tool' | 'Land' | 'Gene' | 'Gacha' | 'ToolMaterial' | 'Slime';
export type TNFTMetadata =
  | IMiningToolMetadata
  | ILandMetadata
  | IGeneMetadata
  | ISlimeMetadata
  | null;

export interface IFixedPriceSaleNFT {
  id: string;
  tokenId: number;
  templateId: number;
  status: TFixedPriceSaleStatus;
  seller: IUserMeta;
  sellPrice: IMarketPrice;
  sellTime: number;
  buyer: IUserMeta;
  buyPrice: IMarketPrice;
  buyTime: number;
  item: INFT;
  amount: string;
}

export interface IListFixedPriceSaleNFTResult {
  total: number;
  records: IFixedPriceSaleNFT[];
}

export interface IFixedPriceSaleNFTDetailVariable {
  saleId: string;
  type: TNFTType;
}

export interface INFTsResult {
  total: number;
  records: INFT[];
}

export interface INFTFilterByType {
  land?: IFilter;
  tool?: IFilter;
  gene?: IFilter;
  slime?: IFilter;
}

export interface INFTOrderByType {
  land?: IOrderByKeyInput;
  tool?: IOrderByKeyInput;
  gene?: IOrderByKeyInput;
  slime?: IOrderByKeyInput;
  gacha?: IOrderByKeyInput;
  toolMaterial?: IOrderByKeyInput;
}

export interface IGetNFTsVariable extends IStandardVariable {
  filter?: INFTFilterByType;
  type: TNFTType;
  owner?: string;
}

export interface ISummary {
  totalSale: number;
  avgPrice: number;
  avgPriceUSD: number;
  volume: number;
  volumeUSD: number;
  totalTransaction: number;
}

export interface IMarketplaceSummary {
  last24Hours: ISummary;
  last7Days: ISummary;
  last30Days: ISummary;
}

export interface IMarketStatistics {
  totalSale: string;
  totalTransaction: string;
  totalVolume: IMarketPrice;
}

export type TTimeFrame = 'last24h' | 'last7days' | 'last30days' | 'last31days';

export interface ICustomerInformationInput {
  timeZone?: string;
}

export interface IUpdateCustomerInformationVariable {
  walletAddress: string;
  customerInfo?: ICustomerInformationInput;
}
