import React from 'react';
import { Flex, Box, Button, useColorModeValue } from '@chakra-ui/react';
import { appUtils } from 'src/common';
import { Colors } from 'src/shared';

interface IBalanceInMobileViewProps {
  balance?: string;
  isLoading?: boolean;
}

const BalanceInMobileView: React.FC<IBalanceInMobileViewProps> = ({ balance, isLoading }) => {
  const defaultBg = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const balanceFlexStyle = {
    marginLeft: -10,
    paddingY: 2,
    paddingLeft: 6,
    paddingRight: 4,
    borderRadius: 50,
    borderWidth: 1,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    bg: defaultBg,
    minWidth: '80px',
    height: '50px',
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
  };
  return (
    <Flex {...balanceFlexStyle}>
      {isLoading && <Button isLoading backgroundColor="transparent" height="inherit" />}
      {!isLoading && (
        <Box as="span" mr={5} fontWeight="bold">
          FTC
        </Box>
      )}
      {!isLoading && (
        <Box as="span">{appUtils.displayPriceWithCurrencyFromNumberWithDecimal(balance, '')}</Box>
      )}
    </Flex>
  );
};

export default BalanceInMobileView;
