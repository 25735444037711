import request, { getDeviceId } from '../clients/accountServiceClient';
import { GameCode } from '../globals/constants';
import {
  AuthBinding,
  AuthRegister,
  GetNoneRes,
  TokenRes,
  UserRes,
} from '../types/AccountServiceClient';

export const register = async (walletAddress: string): Promise<AuthRegister> =>
  request.post('/api/v1/auth/address/register', { walletAddress, deviceId: getDeviceId() });

export const loginByWallet = async (walletAddress: string, signature: string): Promise<TokenRes> =>
  request.post('/api/v1/auth/address/login', {
    walletAddress,
    signature,
    gameCode: GameCode,
    deviceId: getDeviceId(),
  });

export const getWalletNonce = async (walletAddress: string): Promise<GetNoneRes> =>
  request.get(`/api/v1/wallet/${walletAddress}/get-nonce`);

export const getMe = async (): Promise<UserRes> => request.get('/api/v1/auth/me');

export const bindByWallet = async (payload: AuthBinding): Promise<UserRes> =>
  request.post('/api/v1/auth/address/bind', payload);
