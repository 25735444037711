import { Box, VStack, useColorModeValue, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import * as coreUtils from 'src/common/coreUtils';
import { useMemo } from 'react';
import { ISlimeMetadata, SkillType } from '../../../../gql/types';
import Skill from './Skill';

interface SkillStatPropsType {
  metadata: ISlimeMetadata;
}

const Skills = ({ metadata }: SkillStatPropsType) => {
  const { t } = useTranslation();

  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  const isHeadActiveSkill = metadata.headDetails.skill % 2 === 0;

  const mythicalSkillKey = useMemo(() => {
    if (metadata.mythicalSkill === '0') return '';
    return coreUtils.getSkillInfoKey(
      SkillType.MYTHICAL,
      metadata.faceDetails.class,
      parseInt(metadata.mythicalSkill)
    );
  }, [metadata.faceDetails.class, metadata.mythicalSkill]);

  if (metadata.body === '0') {
    return <></>;
  }

  const headActiveSkillKey = coreUtils.getSkillInfoKey(
    SkillType.ACTIVE,
    metadata.headDetails.class,
    metadata.headDetails.skill
  );

  const headPassiveSkillKey = coreUtils.getSkillInfoKey(
    SkillType.PASSIVE,
    metadata.headDetails.class,
    metadata.headDetails.skill
  );

  const rightArmSkillKey = coreUtils.getSkillInfoKey(
    SkillType.ACTIVE,
    metadata.rightArmDetails.class,
    metadata.rightArmDetails.skill
  );
  const leftArmSkillKey = coreUtils.getSkillInfoKey(
    SkillType.ACTIVE,
    metadata.leftArmDetails.class,
    metadata.leftArmDetails.skill
  );
  const bodySkillKey = coreUtils.getSkillInfoKey(
    SkillType.PASSIVE,
    metadata.bodyDetails.class,
    metadata.bodyDetails.skill
  );
  const subBodySkillKey = coreUtils.getSkillInfoKey(
    SkillType.PASSIVE,
    metadata.subDetails.class,
    metadata.subDetails.skill
  );

  return (
    <VStack alignItems="start">
      <Box w="100%">
        <VStack align="start">
          <Flex width="100%">
            <Text color={descriptionColor} pb={2}>
              {t('Component:SlimeDetails:Special')}
            </Text>
          </Flex>
          {metadata.mythicalSkill !== '0' ? (
            <VStack justify="space-around" w="100%">
              <Skill
                title=""
                skillImage={coreUtils.getSkillImageUrl(mythicalSkillKey)}
                rarity={coreUtils.getSkillRarity(mythicalSkillKey)}
                skillName={t(`Skills:${mythicalSkillKey}.name`)}
                skillDescription={t(`Skills:${mythicalSkillKey}.description_full`)}
              />
            </VStack>
          ) : (
            <Flex justifyContent="center" width="100%">
              <Text color={descriptionColor} pb={2}>
                {t('Component:SlimeDetails.NoSkill')}
              </Text>
            </Flex>
          )}
        </VStack>
      </Box>
      <Box w="100%">
        <VStack align="start">
          <Flex width="100%">
            <Text color={descriptionColor} pb={2}>
              {t('Component:SlimeDetails:Active')}
            </Text>
          </Flex>
          <VStack justify="space-around" w="100%">
            {isHeadActiveSkill && (
              <Skill
                title={t('Component:SlimeDetails:Part:Head')}
                skillImage={coreUtils.getSkillImageUrl(headActiveSkillKey)}
                rarity={coreUtils.getSkillRarity(headActiveSkillKey)}
                skillName={t(`Skills:${headActiveSkillKey}.name`)}
                skillDescription={t(`Skills:${headActiveSkillKey}.description_full`)}
              />
            )}
            <Skill
              title={t('Component:SlimeDetails:Part:RArm')}
              skillImage={coreUtils.getSkillImageUrl(rightArmSkillKey)}
              rarity={coreUtils.getSkillRarity(rightArmSkillKey)}
              skillName={t(`Skills:${rightArmSkillKey}.name`)}
              skillDescription={t(`Skills:${rightArmSkillKey}.description_full`)}
            />
            <Skill
              title={t('Component:SlimeDetails:Part:LArm')}
              skillImage={coreUtils.getSkillImageUrl(leftArmSkillKey)}
              rarity={coreUtils.getSkillRarity(leftArmSkillKey)}
              skillName={t(`Skills:${leftArmSkillKey}.name`)}
              skillDescription={t(`Skills:${leftArmSkillKey}.description_full`)}
            />
          </VStack>
        </VStack>
      </Box>
      <Box w="100%">
        <VStack align="start">
          <Flex width="100%">
            <Text color={descriptionColor} pb={2}>
              {t('Component:SlimeDetails:Passive')}
            </Text>
          </Flex>
          <VStack justify="space-around" w="100%">
            {!isHeadActiveSkill && (
              <Skill
                title={t('Component:SlimeDetails:Part:Head')}
                skillImage={coreUtils.getSkillImageUrl(headPassiveSkillKey)}
                rarity={coreUtils.getSkillRarity(headPassiveSkillKey)}
                skillName={t(`Skills:${headPassiveSkillKey}.name`)}
                skillDescription={t(`Skills:${headPassiveSkillKey}.description_full`)}
              />
            )}
            <Skill
              title={t('Component:SlimeDetails:Part:Body')}
              skillImage={coreUtils.getSkillImageUrl(bodySkillKey)}
              rarity={coreUtils.getSkillRarity(bodySkillKey)}
              skillName={t(`Skills:${bodySkillKey}.name`)}
              skillDescription={t(`Skills:${bodySkillKey}.description_full`)}
            />
            <Skill
              title={t('Component:SlimeDetails:Part:SubBody')}
              skillImage={coreUtils.getSkillImageUrl(subBodySkillKey)}
              rarity={coreUtils.getSkillRarity(subBodySkillKey)}
              skillName={t(`Skills:${subBodySkillKey}.name`)}
              skillDescription={t(`Skills:${subBodySkillKey}.description_full`)}
            />
          </VStack>
        </VStack>
      </Box>
    </VStack>
  );
};

export default Skills;
