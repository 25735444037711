import React from 'react';
import { ILandMetadata, IMiningToolMetadata, TNFTMetadata, TNFTType } from 'src/gql/types';
import { NFTType } from 'src/globals/constants';
import { useTranslation } from 'react-i18next';
import { Text, TextProps } from '@chakra-ui/react';
import MiningToolAttributes from './MiningToolAttributes';
import LandAttributes from './LandAttributes';

interface NFTAttributesProps {
  metadata: TNFTMetadata;
  nftType: TNFTType;
  showTitle?: boolean;
  titleStyle?: TextProps;
}

const NFTAttributes = (props: NFTAttributesProps) => {
  const { metadata, nftType, showTitle = true, titleStyle } = props;
  const { t } = useTranslation();
  const renderAttributesContent = () => {
    switch (nftType) {
      case NFTType.LAND:
        return <LandAttributes metadata={metadata as ILandMetadata} />;
      case NFTType.MINING_TOOL:
        return <MiningToolAttributes metadata={metadata as IMiningToolMetadata} />;
      default:
    }
    return null;
  };

  const titleSectionStyle = { ...styles.titleSection, ...titleStyle };
  const attributesContent = renderAttributesContent();
  return (
    <>
      {attributesContent && showTitle && (
        <Text {...titleSectionStyle}>{t('Component:NFTDetail.Section.Attributes')}</Text>
      )}
      {attributesContent}
    </>
  );
};

export default React.memo(NFTAttributes);

const styles = {
  titleSection: {
    fontSize: 'lg',
    pt: 4,
    pb: 2,
    fontWeight: 'bold' as 'bold',
  },
};
