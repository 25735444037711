import React, { useCallback } from 'react';
import { ButtonProps, Image, forwardRef } from '@chakra-ui/react';
import ButtonWithHover from 'src/components/Button/ButtonWithHover';
import { AppSizes, Resources } from 'src/shared';
import { QueryKeys } from 'src/globals/constants';
import { TNFTType } from 'src/gql/types';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import useMyNFTs from 'src/hooks/useMyNFTs';
import { useQueryClient } from '@tanstack/react-query';

interface IRefreshInventoryButtonProps extends ButtonProps {
  refreshNFTType: TNFTType;
}

const RefreshInventoryButton = forwardRef((props: IRefreshInventoryButtonProps, ref) => {
  const { refreshNFTType, ...remainingProps } = props;
  const ownerId = useCurrentAccount();
  const queryClient = useQueryClient();

  const { myNFTsQuery } = useMyNFTs({
    nftType: refreshNFTType,
    ownerId,
  });

  const isLoading = myNFTsQuery.isLoading;

  const onClickRefresh = useCallback(() => {
    queryClient.invalidateQueries([QueryKeys.myNfts, refreshNFTType]);
  }, [queryClient, refreshNFTType]);

  return (
    <ButtonWithHover
      {...remainingProps}
      ref={ref}
      onClick={onClickRefresh}
      isLoading={isLoading as boolean}
      bg="transparent"
      _hover={{ backgroundColor: 'transparent' }}
      _active={{ backgroundColor: 'transparent' }}
    >
      <Image boxSize={AppSizes.MenuIcon} objectFit="cover" src={Resources.svgs.refresh} />
    </ButtonWithHover>
  );
});

export default RefreshInventoryButton;
