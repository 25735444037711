import {
  Chain,
  connect,
  disconnect,
  getAccount,
  GetAccountResult,
  getNetwork,
  signMessage,
  switchNetwork,
} from '@wagmi/core';
import { sleep } from 'src/common/utils';
import { metaMaskConnectorInstance, web3Modal } from 'src/libs/wagmi';
import { MetamaskNetwork } from 'src/metamask-provider';

export const connectWalletConnect = async () => {
  return new Promise((resolve, reject) => {
    let isConnected = false;
    let unSubscribedProvider: any = null;
    let unSubscribedEvents: any = null;
    const closeFn = () => {
      setTimeout(function () {
        unSubscribedProvider();
        unSubscribedEvents();
        if (isConnected) {
          resolve(true);
        } else {
          reject(new Error('Not connected!'));
        }
      }, 1000);
    };

    web3Modal.openModal();

    unSubscribedProvider = web3Modal.subscribeModal(async () => {
      closeFn();
    });
    unSubscribedEvents = web3Modal.subscribeEvents(async (newEvent: { name: string }) => {
      isConnected = newEvent.name === 'ACCOUNT_CONNECTED';
    });
  });
};

export const connectMetaMask = async () => {
  const result = await connect({
    chainId: parseInt(MetamaskNetwork.chainId),
    connector: metaMaskConnectorInstance,
  });
  return result;
};

export const changeWagmiToRightNetwork = async () => {
  const result = await switchNetwork({
    chainId: parseInt(MetamaskNetwork.chainId),
  });
  return result;
};

export const disconnectedWagmi = async () => {
  const result = await disconnect();
  return result;
};

export const getWalletAccount = (): Promise<GetAccountResult> => {
  return new Promise((resolve, reject) => {
    const getAccountFn = async (loopCount: number) => {
      if (loopCount > 2) {
        return reject(new Error('Not connect yet!'));
      }
      const account = getAccount();
      if (!account) {
        return reject(new Error('Not connect yet!'));
      }
      if (account.status === 'connected') {
        resolve(account);
      } else if (account.status === 'disconnected') {
        reject(new Error('Disconnected!'));
      } else {
        await sleep(1000);
        await getAccountFn(loopCount + 1);
      }
    };
    getAccountFn(0);
  });
};

export const getWalletNetwork = (): Promise<Chain> => {
  return new Promise((resolve) => {
    const getNetworkFn = async () => {
      const { chain } = getNetwork();
      if (chain) {
        resolve(chain);
      } else {
        await sleep(1000);
        await getNetworkFn();
      }
    };
    getNetworkFn();
  });
};

export const signMessageByWallet = (message: string): Promise<string> =>
  signMessage({
    message,
  });
