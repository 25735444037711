// Update this list when using new env variables
export type ENV_VARIABLES =
  | 'REACT_APP_ENV'
  | 'REACT_APP_SENTRY_DSN'
  | 'REACT_APP_FANTASY_GRAPHQL_API'
  | 'REACT_APP_FANTASY_ACCOUNT_SERVICE_API'
  | 'REACT_APP_FANTASY_RESOURCE_URL'
  | 'REACT_APP_TRANSACTION_EXPLORER_URL'
  | 'REACT_APP_ADDRESS_EXPLORER_URL'
  | 'REACT_APP_MAX_PRICE_SUPPORTED'
  | 'REACT_APP_MIN_PRICE_SUPPORTED'
  | 'REACT_APP_IS_SHOW_DASHBOARD_STATISTICS'
  | 'REACT_APP_IS_SHOW_SALE_HISTORIES'
  | 'REACT_APP_GA_TRACKING_CODE'
  | 'REACT_APP_WALLET_CONNECT_PROJECT_ID'
  | 'REACT_APP_ALCHEMY_KEY';

export const getConfig = (name: ENV_VARIABLES): string => {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Configuration ${name} is invalid`);
  }
  return value;
};
