const variants = require('./variants');

module.exports = function (slimeData) {
  const getVariant = function (part, details) {
    return safeGetVariant(part, details['class'], details['rarity'], details['variant']);
  };

  const safeGetVariant = function (part, klass, rarity, ordinal) {
    const rarities = variants[klass - 2];
    const list = rarities[rarity - 1][part];
    if (ordinal < 1) return list[0];
    else if (ordinal > list.length) return list[list.length - 1];
    else return list[ordinal - 1];
  };

  const skins = [];
  skins.push(getVariant('body', slimeData.bodyDetails));
  skins.push(getVariant('sub', slimeData.subDetails));
  skins.push(getVariant('head', slimeData.headDetails));
  skins.push(getVariant('left_arm', slimeData.leftArmDetails));
  skins.push(getVariant('right_arm', slimeData.rightArmDetails));
  skins.push(getVariant('face', slimeData.faceDetails));

  return skins;
};
