import { gql } from '@apollo/client';

export const USER_META_KEY = 'userMeta';
export const getUserMetaFragment = (key = USER_META_KEY) => gql`
  fragment ${key} on UserMeta {
    address
    country
    ip
  }
`;
