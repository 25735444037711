import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';
import * as approvalService from 'src/services/approval';

const useApproveMutation = (approvalRelationKey: string, address?: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (approval: boolean) => approvalService.setApprovalStatus(approvalRelationKey, approval),
    {
      onSettled: () => {
        queryClient.invalidateQueries([
          QueryKeys.approvalSettingQuery,
          address,
          approvalRelationKey,
        ]);
      },
    }
  );
};

export default useApproveMutation;
