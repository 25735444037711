import { useMemo } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { NFTType, RARITY_BY_TEXT, RarityType, SLIME_CLASS_BY_TEXT } from '../globals/constants';
import { INFT, ISlimeMetadata, SlimeClass, TNFTType } from '../gql/types';

interface UseNftNameProps {
  item?: INFT;
  nftType?: TNFTType;
  includeIndex?: boolean;
}

const useNftName = ({ item, nftType, includeIndex }: UseNftNameProps) => {
  const { t } = useTranslation();
  return useMemo(() => {
    const tokenIndex = get(item, 'tokenId', '');
    if (nftType === NFTType.SLIME) {
      if (item) {
        const rarityName = t(
          `Term:Rarity.${
            RARITY_BY_TEXT[(item.metadata as ISlimeMetadata).faceDetails.rarity as RarityType]
          }`
        );
        const className = t(
          `Term:GeneClass.${
            SLIME_CLASS_BY_TEXT[(item.metadata as ISlimeMetadata).faceDetails.class as SlimeClass]
          }`
        );
        return [rarityName, className, tokenIndex].join(' ');
      }
      return '';
    }
    const name = get(item, 'metadata.name', '') || '';
    return includeIndex ? `${name} #${tokenIndex}` : name;
  }, [includeIndex, item, nftType, t]);
};

export default useNftName;
