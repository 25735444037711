import React, { useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppSizes, Colors } from 'src/shared';

interface IChangePasswordByWalletModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: (password: string) => void;
  onClickSwitchMethod: () => void;
}

const ChangePasswordByWalletModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  onClickSwitchMethod,
}: IChangePasswordByWalletModalProps) => {
  const { t } = useTranslation();
  const confirmBtnBg = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const labelColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const borderColor = useColorModeValue(Colors.light.text, Colors.dark.text);

  const onSubmit = useCallback(
    ({ password }: { password: string; confirmPassword: string }) => {
      onConfirm(password);
    },
    [onConfirm]
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<{ password: string; confirmPassword: string }>();

  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ModalContent backgroundColor={modalBackgroundColor}>
          <ModalHeader textAlign={'center'}>
            {t('Component:AccountSettings.ChangePassword.Title')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody as={VStack} pb={6} spacing={4}>
            <Box w="80%" alignItems="flex-start">
              <FormControl isInvalid={!!errors.password} mb={5}>
                <FormLabel htmlFor="password" color={labelColor}>
                  {t('Component:AccountSettings.ChangePassword.NewPassword.Label')}:
                </FormLabel>
                <Input
                  borderColor={borderColor}
                  placeholder={t(
                    'Component:AccountSettings.ChangePassword.NewPassword.Placeholder'
                  )}
                  type="password"
                  {...register('password', {
                    required: {
                      value: true,
                      message: t(
                        'Component:AccountSettings.ChangePassword.NewPassword.Error.Required'
                      ),
                    },
                    min: {
                      value: 6,
                      message: t(
                        'Component:AccountSettings.ChangePassword.NewPassword.Error.MinLength'
                      ),
                    },
                  })}
                />
                {errors.password && <FormErrorMessage>{errors.password?.message}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={!!errors.confirmPassword} mb={5}>
                <FormLabel htmlFor="confirmPassword" color={labelColor}>
                  {t('Component:AccountSettings.ChangePassword.ConfirmNewPassword.Label')}:
                </FormLabel>
                <Input
                  borderColor={borderColor}
                  placeholder={t(
                    'Component:AccountSettings.ChangePassword.ConfirmNewPassword.Placeholder'
                  )}
                  type="password"
                  {...register('confirmPassword', {
                    required: {
                      value: true,
                      message: t(
                        'Component:AccountSettings.ChangePassword.ConfirmNewPassword.Error.Required'
                      ),
                    },
                    validate: {
                      noMatch: (value: string) => {
                        const password = getValues('password');
                        if (password !== value) {
                          return t(
                            'Component:AccountSettings.ChangePassword.ConfirmNewPassword.Error.NoMatch'
                          ) as string;
                        }
                        return true;
                      },
                    },
                  })}
                />
                {errors.confirmPassword && (
                  <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
                )}
              </FormControl>
              {/*<Box>*/}
              {/*  <Text color={labelColor} cursor="pointer" onClick={onClickSwitchMethod}>*/}
              {/*    {t('Component:AccountSettings.ChangePassword.ChangePasswordByCurrentPassword')}*/}
              {/*  </Text>*/}
              {/*</Box>*/}
            </Box>
          </ModalBody>

          <ModalFooter mx={'auto'}>
            <Button mr={3} type="submit" bg={confirmBtnBg} {...styles.button} isLoading={isLoading}>
              {t('Common:Confirm')}
            </Button>
            <Button onClick={onClose} {...styles.button} disabled={isLoading}>
              {t('Common:Cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ChangePasswordByWalletModal;

const styles = {
  button: {
    borderRadius: 'full' as 'full',
    minWidth: AppSizes.ButtonMinWidth,
  },
};
