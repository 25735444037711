import React from 'react';
import { Flex, Box, useColorModeValue, FlexProps, Text, forwardRef } from '@chakra-ui/react';
import { Colors } from 'src/shared';

interface IconLabelProps extends FlexProps {
  value: string;
  icon?: React.ReactElement;
}

const IconLabel = forwardRef((props: IconLabelProps, ref) => {
  const { value, color, icon, children, ...remainingProps } = props;
  const defaultBg = useColorModeValue(Colors.dark.iconLabelBg, Colors.dark.iconLabelBg);
  const renderIconElement = () => {
    if (!icon) return children;
    return (
      <Box ref={ref} bg={defaultBg} rounded="full" p={1}>
        {icon}
      </Box>
    );
  };
  return (
    <Flex
      ref={ref}
      rounded={4}
      flexDirection="row"
      alignSelf="flex-start"
      alignItems="center"
      {...remainingProps}
    >
      {renderIconElement()}
      <Text fontWeight="bold" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" pl={2}>
        {value}
      </Text>
    </Flex>
  );
});

export default IconLabel;
