// sp_c5_r1_03
import { SlimeClass } from 'src/gql/types';

export const SlimeNormalSkillIds = {
  [SlimeClass.Crusader]: [
    null,
    null,
    'sa_c2_r1_01',
    'sp_c2_r1_01',
    'sa_c2_r1_02',
    'sp_c2_r1_02',
    'sa_c2_r1_03',
    'sp_c2_r1_03',
    'sa_c2_r1_04',
    'sp_c2_r1_04',

    'sa_c2_r2_01',
    'sp_c2_r2_01',
    'sa_c2_r2_02',
    'sp_c2_r2_02',
    'sa_c2_r2_03',
    'sp_c2_r2_03',

    'sa_c2_r3_01',
    'sp_c2_r3_01',
    'sa_c2_r3_02',
    'sp_c2_r3_02',
    'sa_c2_r3_03',
    'sp_c2_r3_03',

    'sa_c2_r4_01',
    'sp_c2_r4_01',
    'sa_c2_r4_02',
    'sp_c2_r4_02',
    'sa_c2_r4_03',
    'sp_c2_r4_03',

    'sa_c2_r5_01',
    'sp_c2_r5_01',
    'sa_c2_r5_02',
    'sp_c2_r5_02',
  ],

  [SlimeClass.Wizard]: [
    null,
    null,
    'sa_c3_r1_01',
    'sp_c3_r1_01',
    'sa_c3_r1_02',
    'sp_c3_r1_02',
    'sa_c3_r1_03',
    'sp_c3_r1_03',
    'sa_c3_r1_04',
    'sp_c3_r1_04',

    'sa_c3_r2_01',
    'sp_c3_r2_01',
    'sa_c3_r2_02',
    'sp_c3_r2_02',
    'sa_c3_r2_03',
    'sp_c3_r2_03',

    'sa_c3_r3_01',
    'sp_c3_r3_01',
    'sa_c3_r3_02',
    'sp_c3_r3_02',
    'sa_c3_r3_03',
    'sp_c3_r3_03',

    'sa_c3_r4_01',
    'sp_c3_r4_01',
    'sa_c3_r4_02',
    'sp_c3_r4_02',
    'sa_c3_r4_03',
    'sp_c3_r4_03',

    'sa_c3_r5_01',
    'sp_c3_r5_01',
    'sa_c3_r5_02',
    'sp_c3_r5_02',
  ],

  [SlimeClass.Assassin]: [
    null,
    null,
    'sa_c4_r1_01',
    'sp_c4_r1_01',
    'sa_c4_r1_02',
    'sp_c4_r1_02',
    'sa_c4_r1_03',
    'sp_c4_r1_03',
    'sa_c4_r1_04',
    'sp_c4_r1_04',

    'sa_c4_r2_01',
    'sp_c4_r2_01',
    'sa_c4_r2_02',
    'sp_c4_r2_02',
    'sa_c4_r2_03',
    'sp_c4_r2_03',

    'sa_c4_r3_01',
    'sp_c4_r3_01',
    'sa_c4_r3_02',
    'sp_c4_r3_02',
    'sa_c4_r3_03',
    'sp_c4_r3_03',

    'sa_c4_r4_01',
    'sp_c4_r4_01',
    'sa_c4_r4_02',
    'sp_c4_r4_02',
    'sa_c4_r4_03',
    'sp_c4_r4_03',

    'sa_c4_r5_01',
    'sp_c4_r5_01',
    'sa_c4_r5_02',
    'sp_c4_r5_02',
  ],

  [SlimeClass.Saint]: [
    null,
    null,
    'sa_c5_r1_01',
    'sp_c5_r1_01',
    'sa_c5_r1_02',
    'sp_c5_r1_02',
    'sa_c5_r1_03',
    'sp_c5_r1_03',
    'sa_c5_r1_04',
    'sp_c5_r1_04',

    'sa_c5_r2_01',
    'sp_c5_r2_01',
    'sa_c5_r2_02',
    'sp_c5_r2_02',
    'sa_c5_r2_03',
    'sp_c5_r2_03',

    'sa_c5_r3_01',
    'sp_c5_r3_01',
    'sa_c5_r3_02',
    'sp_c5_r3_02',
    'sa_c5_r3_03',
    'sp_c5_r3_03',

    'sa_c5_r4_01',
    'sp_c5_r4_01',
    'sa_c5_r4_02',
    'sp_c5_r4_02',
    'sa_c5_r4_03',
    'sp_c5_r4_03',

    'sa_c5_r5_01',
    'sp_c5_r5_01',
    'sa_c5_r5_02',
    'sp_c5_r5_02',
  ],

  [SlimeClass.Shaman]: [
    null,
    null,
    'sa_c6_r1_01',
    'sp_c6_r1_01',
    'sa_c6_r1_02',
    'sp_c6_r1_02',
    'sa_c6_r1_03',
    'sp_c6_r1_03',
    'sa_c6_r1_04',
    'sp_c6_r1_04',

    'sa_c6_r2_01',
    'sp_c6_r2_01',
    'sa_c6_r2_02',
    'sp_c6_r2_02',
    'sa_c6_r2_03',
    'sp_c6_r2_03',

    'sa_c6_r3_01',
    'sp_c6_r3_01',
    'sa_c6_r3_02',
    'sp_c6_r3_02',
    'sa_c6_r3_03',
    'sp_c6_r3_03',

    'sa_c6_r4_01',
    'sp_cru_r4_01',
    'sa_c6_r4_02',
    'sp_c6_r4_02',
    'sa_c6_r4_03',
    'sp_c6_r4_03',

    'sa_c6_r5_01',
    'sp_c6_r5_01',
    'sa_c6_r5_02',
    'sp_c6_r5_02',
  ],
  Diamond: [
    null,
    null,
    'sa_cru_r1_01',
    'sp_cru_r1_01',
    'sa_cru_r1_02',
    'sp_cru_r1_02',
    'sa_cru_r1_03',
    'sp_cru_r1_03',
    'sa_cru_r1_04',
    'sp_cru_r1_04',
    'sa_wiz_r1_01',
    'sp_wiz_r1_01',
    'sa_wiz_r1_02',
    'sp_wiz_r1_02',
    'sa_wiz_r1_03',
    'sp_wiz_r1_03',
    'sa_wiz_r1_04',
    'sp_wiz_r1_04',
    'sa_ass_r1_01',
    'sp_ass_r1_01',
    'sa_ass_r1_02',
    'sp_ass_r1_02',
    'sa_ass_r1_03',
    'sp_ass_r1_03',
    'sa_ass_r1_04',
    'sp_ass_r1_04',
    'sa_sai_r1_01',
    'sp_sai_r1_01',
    'sa_sai_r1_02',
    'sp_sai_r1_02',
    'sa_sai_r1_03',
    'sp_sai_r1_03',
    'sa_sai_r1_04',
    'sp_sai_r1_04',
    'sa_sha_r1_01',
    'sp_sha_r1_01',
    'sa_sha_r1_02',
    'sp_sha_r1_02',
    'sa_sha_r1_03',
    'sp_sha_r1_03',
    'sa_sha_r1_04',
    'sp_sha_r1_04',
    'sa_cru_r2_01',
    'sp_cru_r2_01',
    'sa_cru_r2_02',
    'sp_cru_r2_02',
    'sa_cru_r2_03',
    'sp_cru_r2_03',
    'sa_wiz_r2_01',
    'sp_wiz_r2_01',
    'sa_wiz_r2_02',
    'sp_wiz_r2_02',
    'sa_wiz_r2_03',
    'sp_wiz_r2_03',
    'sa_ass_r2_01',
    'sp_ass_r2_01',
    'sa_ass_r2_02',
    'sp_ass_r2_02',
    'sa_ass_r2_03',
    'sp_ass_r2_03',
    'sa_sai_r2_01',
    'sp_sai_r2_01',
    'sa_sai_r2_02',
    'sp_sai_r2_02',
    'sa_sai_r2_03',
    'sp_sai_r2_03',
    'sa_sha_r2_01',
    'sp_sha_r2_01',
    'sa_sha_r2_02',
    'sp_sha_r2_02',
    'sa_sha_r2_03',
    'sp_sha_r2_03',
    'sa_cru_r3_01',
    'sp_cru_r3_01',
    'sa_cru_r3_02',
    'sp_cru_r3_02',
    'sa_cru_r3_03',
    'sp_cru_r3_03',
    'sa_wiz_r3_01',
    'sp_wiz_r3_01',
    'sa_wiz_r3_02',
    'sp_wiz_r3_02',
    'sa_wiz_r3_03',
    'sp_wiz_r3_03',
    'sa_ass_r3_01',
    'sp_ass_r3_01',
    'sa_ass_r3_02',
    'sp_ass_r3_02',
    'sa_ass_r3_03',
    'sp_ass_r3_03',
    'sa_sai_r3_01',
    'sp_sai_r3_01',
    'sa_sai_r3_02',
    'sp_sai_r3_02',
    'sa_sai_r3_03',
    'sp_sai_r3_03',
    'sa_sha_r3_01',
    'sp_sha_r3_01',
    'sa_sha_r3_02',
    'sp_sha_r3_02',
    'sa_sha_r3_03',
    'sp_sha_r3_03',
    'sa_cru_r4_01',
    'sp_cru_r4_01',
    'sa_cru_r4_02',
    'sp_cru_r4_02',
    'sa_cru_r4_03',
    'sp_cru_r4_03',
    'sa_wiz_r4_01',
    'sp_wiz_r4_01',
    'sa_wiz_r4_02',
    'sp_wiz_r4_02',
    'sa_wiz_r4_03',
    'sp_wiz_r4_03',
    'sa_ass_r4_01',
    'sp_ass_r4_01',
    'sa_ass_r4_02',
    'sp_ass_r4_02',
    'sa_ass_r4_03',
    'sp_ass_r4_03',
    'sa_sai_r4_01',
    'sp_sai_r4_01',
    'sa_sai_r4_02',
    'sp_sai_r4_02',
    'sa_sai_r4_03',
    'sp_sai_r4_03',
    'sa_sha_r4_01',
    'sp_cru_r4_01',
    'sa_sha_r4_02',
    'sp_sha_r4_02',
    'sa_sha_r4_03',
    'sp_sha_r4_03',
    'sa_cru_r5_01',
    'sp_cru_r5_01',
    'sa_cru_r5_02',
    'sp_cru_r5_02',
    'sa_wiz_r5_01',
    'sp_wiz_r5_01',
    'sa_wiz_r5_02',
    'sp_wiz_r5_02',
    'sa_ass_r5_01',
    'sp_ass_r5_01',
    'sa_ass_r5_02',
    'sp_ass_r5_02',
    'sa_sai_r5_01',
    'sp_sai_r5_01',
    'sa_sai_r5_02',
    'sp_sai_r5_02',
    'sa_sha_r5_01',
    'sp_sha_r5_01',
    'sa_sha_r5_02',
    'sp_sha_r5_02',
  ],
};

export const SlimeMythicalSkillIds = {
  [SlimeClass.Crusader]: [
    'sm_c2_r1_01',
    'sm_c2_r1_02',
    'sm_c2_r2_01',
    'sm_c2_r2_02',
    'sm_c2_r3_01',
    'sm_c2_r3_02',
    'sm_c2_r4_01',
    'sm_c2_r4_02',
    'sm_c2_r5_01',
    'sm_c2_r5_02',
  ],
  [SlimeClass.Wizard]: [
    'sm_c3_r1_01',
    'sm_c3_r1_02',
    'sm_c3_r2_01',
    'sm_c3_r2_02',
    'sm_c3_r3_01',
    'sm_c3_r3_02',
    'sm_c3_r4_01',
    'sm_c3_r4_02',
    'sm_c3_r5_01',
    'sm_c3_r5_02',
  ],
  [SlimeClass.Assassin]: [
    'sm_c4_r1_01',
    'sm_c4_r1_02',
    'sm_c4_r2_01',
    'sm_c4_r2_02',
    'sm_c4_r3_01',
    'sm_c4_r3_02',
    'sm_c4_r4_01',
    'sm_c4_r4_02',
    'sm_c4_r5_01',
    'sm_c4_r5_02',
  ],
  [SlimeClass.Saint]: [
    'sm_c5_r1_01',
    'sm_c5_r1_02',
    'sm_c5_r2_01',
    'sm_c5_r2_02',
    'sm_c5_r3_01',
    'sm_c5_r3_02',
    'sm_c5_r4_01',
    'sm_c5_r4_02',
    'sm_c5_r5_01',
    'sm_c5_r5_02',
  ],
  [SlimeClass.Shaman]: [
    'sm_c6_r1_01',
    'sm_c6_r1_02',
    'sm_c6_r2_01',
    'sm_c6_r2_02',
    'sm_c6_r3_01',
    'sm_c6_r3_02',
    'sm_c6_r4_01',
    'sm_c6_r4_02',
    'sm_c6_r5_01',
    'sm_c6_r5_02',
  ],
  Diamond: [
    'sm_c2_r1_01',
    'sm_c2_r1_02',
    'sm_c2_r2_01',
    'sm_c2_r2_02',
    'sm_c2_r3_01',
    'sm_c2_r3_02',
    'sm_c2_r4_01',
    'sm_c2_r4_02',
    'sm_c2_r5_01',
    'sm_c2_r5_02',

    'sm_c3_r1_01',
    'sm_c3_r1_02',
    'sm_c3_r2_01',
    'sm_c3_r2_02',
    'sm_c3_r3_01',
    'sm_c3_r3_02',
    'sm_c3_r4_01',
    'sm_c3_r4_02',
    'sm_c3_r5_01',
    'sm_c3_r5_02',

    'sm_c4_r1_01',
    'sm_c4_r1_02',
    'sm_c4_r2_01',
    'sm_c4_r2_02',
    'sm_c4_r3_01',
    'sm_c4_r3_02',
    'sm_c4_r4_01',
    'sm_c4_r4_02',
    'sm_c4_r5_01',
    'sm_c4_r5_02',

    'sm_c5_r1_01',
    'sm_c5_r1_02',
    'sm_c5_r2_01',
    'sm_c5_r2_02',
    'sm_c5_r3_01',
    'sm_c5_r3_02',
    'sm_c5_r4_01',
    'sm_c5_r4_02',
    'sm_c5_r5_01',
    'sm_c5_r5_02',

    'sm_c6_r1_01',
    'sm_c6_r1_02',
    'sm_c6_r2_01',
    'sm_c6_r2_02',
    'sm_c6_r3_01',
    'sm_c6_r3_02',
    'sm_c6_r4_01',
    'sm_c6_r4_02',
    'sm_c6_r5_01',
    'sm_c6_r5_02',
  ],
};
