import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { QueryKeys } from 'src/globals/constants';
import * as wagmiAuthService from 'src/services/wagmiAuth';
import { WalletType } from 'src/types/Wallet';

const useDoConnectWagmi = (walletType: WalletType) => {
  const [isLoading, setIsloading] = useState(false);
  const queryClient = useQueryClient();
  const doConnect = async () => {
    setIsloading(true);
    try {
      if (walletType === WalletType.WAGMI_METAMASK) {
        await wagmiAuthService.connectMetaMask();
      } else {
        await wagmiAuthService.connectWalletConnect();
      }
      queryClient.invalidateQueries([QueryKeys.wagmiAccountQueryKey]);
    } catch (err) {
      console.error(err);
    } finally {
      setIsloading(false);
    }
  };

  return { isLoading, doConnect };
};

export default useDoConnectWagmi;
