import React from 'react';
import { Box, Button, useColorModeValue } from '@chakra-ui/react';
import { Colors } from 'src/shared';
import { appUtils } from 'src/common';

interface IBalanceInDesktopViewProps {
  balance?: string;
  isLoading?: boolean;
}

const BalanceInDesktopView: React.FC<IBalanceInDesktopViewProps> = ({ balance, isLoading }) => {
  const defaultBg = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const borderColor = useColorModeValue(Colors.light.text, Colors.dark.text);

  const balanceBoxStyle = {
    padding: 2,
    as: 'span' as 'span',
    borderRadius: 'full',
    borderColor: borderColor,
    borderWidth: 1,
    width: 'fit-content' as 'fit-content',
    bg: defaultBg,
    minWidth: '50px',
  };
  return (
    <Box {...balanceBoxStyle}>
      {isLoading && <Button isLoading bg="transparent" height="inherit" />}
      {!isLoading && (
        <Box as="span" mr={2} fontWeight="bold">
          FTC
        </Box>
      )}
      {!isLoading && (
        <Box as="span">{appUtils.displayPriceWithCurrencyFromNumberWithDecimal(balance, '')}</Box>
      )}
    </Box>
  );
};

export default BalanceInDesktopView;
