import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppAction } from 'src/redux';
import useAutoUpdateCustomerInfo from 'src/hooks/useAutoUpdateCustomerInfo';

const AppConfig = () => {
  const dispatch = useDispatch();
  useAutoUpdateCustomerInfo();

  useEffect(() => {
    dispatch(AppAction.getFilterConfigs());
  }, [dispatch]);

  return <></>;
};

export default AppConfig;
