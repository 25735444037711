import React, { useCallback, useEffect } from 'react';
import { Text, useDisclosure, usePrevious } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import isFunction from 'lodash/isFunction';
import { PrimaryButton } from 'src/components';
import { useAuth, useIsMounted } from 'src/hooks';
import { appUtils } from 'src/common';
import useErrorModal from 'src/features/errorModal/hooks/useErrorModal';
import { INFT, TNFTType } from 'src/gql/types';
import useApproveNFTToFixedPriceSaleMarket from '../hooks/useApproveNFTToFixedPriceSaleMarket';
import useAddNFTToFixedPriceSaleMarket from '../hooks/useAddNFTToFixedPriceSaleMarket';
import PlaceOrderModal from './PlaceOrderModal';
import { handleMetamaskError } from 'src/common/utils';

interface ISellButtonProps {
  tokenId: number;
  isLoading?: boolean;
  onAddSaleDone?: (txid: string) => void;
  nftType: TNFTType;
  currentNFT?: INFT;
  isDisabled?: boolean;
}

const SellButton = ({
  tokenId,
  isLoading,
  onAddSaleDone,
  nftType,
  currentNFT,
  isDisabled,
}: ISellButtonProps) => {
  const { t } = useTranslation();
  const [openErrorModal] = useErrorModal();
  const isMounted = useIsMounted();

  const [signedIn] = useAuth();
  const [
    onApprovedNFTToFixedPriceSaleMarket,
    isGettingApprovedStatus,
    isApprovingForSale,
    isApprovedForSale,
    approveErrorMessage,
  ] = useApproveNFTToFixedPriceSaleMarket(nftType);

  const [
    isAddingNFTToFixedPriceSaleMarket,
    onAddNFTToFixedPriceSaleMarket,
    addNFTToFixedPriceSaleMarketErrorMessage,
  ] = useAddNFTToFixedPriceSaleMarket(nftType);

  const {
    isOpen: isOpenPlaceOrderModal,
    onOpen: onOpenPlaceOrderModal,
    onClose: onClosePlaceOrderModal,
  } = useDisclosure();

  const onClickSellButton = useCallback(() => {
    onOpenPlaceOrderModal();
  }, [onOpenPlaceOrderModal]);

  const isButtonLoading = !!isGettingApprovedStatus || !!isApprovingForSale || !!isLoading;
  const isButtonDisabled = !signedIn || isButtonLoading || !!isDisabled;

  let buttonLoadingText;
  if (isApprovedForSale) {
    buttonLoadingText = t('Common:Approving');
  }

  const onSell = useCallback(
    (price: string, amount: number) => {
      try {
        if (isFunction(onAddNFTToFixedPriceSaleMarket)) {
          onAddNFTToFixedPriceSaleMarket(
            tokenId,
            appUtils.parseNumberWithDecimalFromDisplayPrice(price),
            amount,
            onAddSaleDone
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [onAddNFTToFixedPriceSaleMarket, onAddSaleDone, tokenId]
  );

  const prevIsApprovingForSale = usePrevious(isApprovingForSale);

  useEffect(() => {
    if (!isMounted()) return;
    if (!!prevIsApprovingForSale && !isApprovingForSale && isApprovedForSale) {
      onOpenPlaceOrderModal();
    }
  }, [
    isMounted,
    prevIsApprovingForSale,
    isApprovingForSale,
    onOpenPlaceOrderModal,
    isApprovedForSale,
  ]);

  const prevIsAddingMiningTool = usePrevious(isAddingNFTToFixedPriceSaleMarket);
  useEffect(() => {
    if (!isMounted()) return;
    if (
      prevIsAddingMiningTool &&
      !isAddingNFTToFixedPriceSaleMarket &&
      !addNFTToFixedPriceSaleMarketErrorMessage
    ) {
      onClosePlaceOrderModal();
    }
  }, [
    isMounted,
    prevIsAddingMiningTool,
    isAddingNFTToFixedPriceSaleMarket,
    onClosePlaceOrderModal,
    addNFTToFixedPriceSaleMarketErrorMessage,
  ]);

  const prevApproveErrorMessage = usePrevious<string | undefined>(approveErrorMessage as string);
  useEffect(() => {
    if (!isMounted()) return;
    if (prevApproveErrorMessage !== approveErrorMessage && approveErrorMessage) {
      if (isFunction(openErrorModal) && handleMetamaskError(approveErrorMessage))
        openErrorModal(t(handleMetamaskError(approveErrorMessage) as string));
    }
  }, [isMounted, prevApproveErrorMessage, approveErrorMessage, openErrorModal, t]);

  const prevAddMiningToolToFixedPriceSaleErrorMessage = usePrevious<string | undefined>(
    addNFTToFixedPriceSaleMarketErrorMessage as string
  );
  useEffect(() => {
    if (!isMounted()) return;
    if (
      prevAddMiningToolToFixedPriceSaleErrorMessage !== addNFTToFixedPriceSaleMarketErrorMessage &&
      addNFTToFixedPriceSaleMarketErrorMessage
    ) {
      if (
        isFunction(openErrorModal) &&
        handleMetamaskError(addNFTToFixedPriceSaleMarketErrorMessage)
      )
        openErrorModal(t(handleMetamaskError(addNFTToFixedPriceSaleMarketErrorMessage) as string));
    }
  }, [
    isMounted,
    prevAddMiningToolToFixedPriceSaleErrorMessage,
    addNFTToFixedPriceSaleMarketErrorMessage,
    openErrorModal,
    t,
  ]);

  return (
    <>
      <PrimaryButton
        isLoading={isButtonLoading}
        loadingText={buttonLoadingText}
        borderRadius={'full'}
        onClick={onClickSellButton}
        disabled={isButtonDisabled}
      >
        <Text>{t('Component:SellButton.SellNow')}</Text>
      </PrimaryButton>
      {isOpenPlaceOrderModal && (
        <PlaceOrderModal
          currentNFT={currentNFT}
          isOpen={isOpenPlaceOrderModal}
          onClose={onClosePlaceOrderModal}
          onConfirm={onSell}
          isLoading={isAddingNFTToFixedPriceSaleMarket as boolean}
          onApprove={onApprovedNFTToFixedPriceSaleMarket as () => void}
          isApproved={isApprovedForSale as boolean}
          isApproving={isApprovingForSale as boolean}
        />
      )}
    </>
  );
};

export default SellButton;
