import { Dispatch } from 'redux';
import * as GQLApi from 'src/gql';
import { SupportedLanguage, SupportedTheme } from '../../globals/constants';
import * as Types from './types';
import { standardizeFilterConfigs } from './common';
import { IFilterConfigs } from './types';

export const toggleMobileNavbar = (visible: boolean) => {
  return {
    type: Types.TOGGLE_MOBILE_NAVBAR,
    payload: visible,
  };
};

export const toggleInventoryFilter = (visible: boolean) => {
  return {
    type: Types.TOGGLE_INVENTORY_FILTER,
    payload: visible,
  };
};

export const toggleSideBar = (visible?: boolean) => {
  return {
    type: Types.TOGGLE_SIDEBAR,
    payload: visible,
  };
};

export const openErrorModal = (errorMessage: string) => {
  return {
    type: Types.OPEN_ERROR_MODAL,
    payload: {
      errorMessage,
    },
  };
};

export const closeErrorModal = () => {
  return {
    type: Types.CLOSE_ERROR_MODAL,
  };
};

export const updateFilterConfigs = (filterConfigs: IFilterConfigs) => {
  return {
    type: Types.UPDATE_FILTER_CONFIGS,
    payload: filterConfigs,
  };
};

export const getFilterConfigs = () => {
  return (dispatch: Dispatch) => {
    return GQLApi.getNFTSummary()
      .then((result) => {
        const filterConfigs = standardizeFilterConfigs(result);
        dispatch(updateFilterConfigs(filterConfigs));
      })
      .catch(console.error);
  };
};

export const setAppLanguage = (lang: SupportedLanguage) => {
  return {
    type: Types.SET_APP_LANGUAGE,
    payload: lang,
  };
};

export const setAppTheme = (theme: SupportedTheme) => {
  return {
    type: Types.SET_APP_THEME,
    payload: theme,
  };
};
