import React from 'react';
import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import InfoItem from './InfoItem';

const StandardAbouts = ({ nftType }: { nftType: TNFTType }) => {
  const { t } = useTranslation();
  const nameKey = coreUtils.getNFTTypeNameByNftType(nftType);
  return (
    <HStack alignItems={'inherit'}>
      <InfoItem title={t('Term:Type')} text={t(nameKey)} />
    </HStack>
  );
};

export default React.memo(StandardAbouts);
