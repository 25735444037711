import { gql } from '@apollo/client';

export const MARKET_PRICE_KEY = 'marketPrice';
export const getMarketPriceFragment = (key = MARKET_PRICE_KEY) => gql`
  fragment ${key} on MarketPrice {
    origin
    originalCurrency
    usdt
    bnb
    eth
    btc
  }
`;
