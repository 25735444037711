import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Button,
  Image,
  Spinner,
  useColorModeValue,
  Text,
  VStack,
  HStack,
  Box,
  Heading,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { ChevronLeftIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Colors } from 'src/shared';
import { TNFTType, TNFTMetadata, IGeneMetadata } from 'src/gql/types';
import { appUtils, coreUtils } from 'src/common';
import isFunction from 'lodash/isFunction';
import { useTranslation } from 'react-i18next';
import { Label, PrimaryButton } from 'src/components';
import { ISaleInfoFromMetaMask } from 'src/metamask-provider/type';
import {
  TransactionSubmittedModal,
  SellButton,
  GiftButton,
  useGetFixedPriceSaleInfoByTokenId,
} from 'src/features/trading';
import { OpenSaleOrderList } from 'src/features/openSaleOrderList';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import useMyNFTDetails from 'src/hooks/useMyNftDetails';
import { NFTType } from 'src/globals/constants';
import useCurrentMinningToolIdsQuery from 'src/hooks/useCurrentMinningToolIdsQuery';
import * as nftDetailCommon from '../common';
import SlimeSpinePreview from '../../../components/SlimeSpinePreview';
import useNftName from '../../../hooks/useNftName';
import NFTAttributes from './NFTAttributes';
import NFTAbouts from './NFTAbouts';
import NFTDetailQuantity from './NFTDetailQuantity';

interface ParamTypes {
  tokenId: string;
}

interface NFTDetailProps extends RouteComponentProps<ParamTypes, {}> {
  nftType: TNFTType;
}

const NFTDetail = (props: NFTDetailProps) => {
  const { t } = useTranslation();
  const { nftType, match } = props;
  const [isOpenTransactionSubmittedModal, setIsOpenTransactionSubmittedModal] =
    useState<boolean>(false);
  const [currentTxid, setCurrentTxid] = useState<string | undefined>();
  const tokenId = parseInt(match.params.tokenId);
  const metaMaskWalletAddress = useCurrentAccount();

  const {
    myNFTDetailsQuery: { isLoading: isGettingCurrentMyNFT, data: currentMyNFT },
  } = useMyNFTDetails({ tokenType: nftType, tokenId, currentAccount: metaMaskWalletAddress });
  const { isLoading: isGettingCurrentMiningTool, data: miningToolsId } =
    useCurrentMinningToolIdsQuery(metaMaskWalletAddress);

  const history = useHistory();

  const isMining = useMemo(() => {
    if (currentMyNFT && nftType === NFTType.MINING_TOOL) {
      return miningToolsId?.includes(currentMyNFT.tokenId);
    }

    return false;
  }, [nftType, currentMyNFT, miningToolsId]);

  const isLocking = useMemo(() => Number(currentMyNFT?.onLockAmount) > 0, [currentMyNFT]);

  const [isGettingSaleInfo, saleInfo, getFixedPriceSaleInfoByTokenId] =
    useGetFixedPriceSaleInfoByTokenId(nftType);

  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const emptyColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const borderColor = useColorModeValue(Colors.light.divider, Colors.dark.divider);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);

  const defaultNFTMetadata = coreUtils.getDefaultMetadataByNftType(nftType);
  const metadata: TNFTMetadata = get(currentMyNFT, 'metadata', defaultNFTMetadata);

  const nftItemImage = useMemo(() => {
    if (currentMyNFT?.tokenId) {
      return coreUtils.getNftImage(
        nftType,
        currentMyNFT?.tokenId,
        currentMyNFT?.templateId || 0,
        !!(currentMyNFT?.metadata as IGeneMetadata)?.extractedData,
        (currentMyNFT?.metadata as IGeneMetadata)?.extractedData?.variant,
        currentMyNFT.updatedAt
      );
    }
    return '#';
  }, [nftType, currentMyNFT]);

  const isSlime = useMemo(() => nftType === NFTType.SLIME, [nftType]);

  const titleSectionStyle = {
    ...styles.titleSection,
    color: descriptionColor,
    borderBottom: `1px solid ${borderColor}`,
  };
  const NFTContractAddress = appUtils.getNFTContractAddress(nftType);
  const NFTContractDisplay = appUtils.shortenHash(NFTContractAddress);
  const NFTContractURL = appUtils.getAddressExplorerLink(NFTContractAddress);

  const nftName = useNftName({ item: currentMyNFT || undefined, nftType });
  const index = get(currentMyNFT, 'tokenId', '');
  const isAvailableAmountForSale = nftDetailCommon.isAvailableAmountForSale(
    currentMyNFT,
    saleInfo as ISaleInfoFromMetaMask | ISaleInfoFromMetaMask[]
  );
  const ownerDisplay = appUtils.shortenHash((currentMyNFT?.owner as string) || '');
  const ownerAddressURL = appUtils.getAddressExplorerLink((currentMyNFT?.owner as string) || '');

  const tokenIndex = `#${index}`;

  const isOwner =
    appUtils.isAddressEqual(metaMaskWalletAddress, currentMyNFT?.owner) && !isMining && !isLocking;
  const isShowGiftButton = isOwner && isAvailableAmountForSale && !isMining && !isLocking;
  const isActionDisabled = !(isOwner && isAvailableAmountForSale && !isMining && !isLocking);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onGetSaleInfo = useCallback(() => {
    if (isFunction(getFixedPriceSaleInfoByTokenId)) {
      getFixedPriceSaleInfoByTokenId(tokenId);
    }
  }, [tokenId, getFixedPriceSaleInfoByTokenId]);

  const onCloseTransactionSubmittedModal = useCallback(() => {
    setIsOpenTransactionSubmittedModal(false);
  }, [setIsOpenTransactionSubmittedModal]);

  const onTransactionSubmitted = useCallback(
    (txId: string) => {
      setIsOpenTransactionSubmittedModal(true);
      setCurrentTxid(txId);
    },
    [setIsOpenTransactionSubmittedModal, setCurrentTxid]
  );

  const onAddSaleDone = useCallback(
    (txId: string) => {
      onTransactionSubmitted(txId);
      onGetSaleInfo();
    },
    [onTransactionSubmitted, onGetSaleInfo]
  );

  useEffect(() => {
    onGetSaleInfo();
  }, [onGetSaleInfo]);
  const listOfOpenSaleOrders: Array<ISaleInfoFromMetaMask> =
    nftDetailCommon.getListOfOpenSaleOrders(
      saleInfo as Array<ISaleInfoFromMetaMask> | ISaleInfoFromMetaMask
    );

  const { forSaleAmount, onSaleAmount, totalAmount } = nftDetailCommon.getAmountInfo(
    saleInfo as Array<ISaleInfoFromMetaMask> | ISaleInfoFromMetaMask,
    currentMyNFT
  );

  const imageStyle = useMemo(() => {
    if (nftType === NFTType.LAND) {
      return {
        mt: 0,
        px: 0,
        width: '100%',
        maxWidth: '450px',
        objectFit: 'contain' as 'contain',
        borderRadius: '4px',
        border: '1px solid white',
        filter: isMining || isLocking ? 'grayscale(100%)' : 'none',
      };
    } else {
      return {
        mt: 0,
        px: 4,
        width: '100%',
        maxWidth: '400px',
        objectFit: 'contain' as 'contain',
        borderRadius: '0px',
        border: 'none',
        filter: isMining || isLocking ? 'grayscale(100%)' : 'none',
      };
    }
  }, [nftType, isMining, isLocking]);

  return (
    <>
      <Flex {...styles.container}>
        <VStack {...styles.leftContainer}>
          <Button {...styles.backButton} onClick={onBack}>
            <ChevronLeftIcon h={6} w={6} />
            <Text>{t('Back')}</Text>
          </Button>
          {isSlime ? (
            <SlimeSpinePreview
              slime={currentMyNFT && currentMyNFT.metadata ? currentMyNFT : undefined}
              renderKey="nft-detail"
              isLoading={isGettingCurrentMyNFT}
            />
          ) : (
            <Image {...imageStyle} src={nftItemImage} />
          )}
        </VStack>
        <VStack {...styles.rightContainer}>
          <Label
            label={tokenIndex}
            bg={borderColor}
            alignSelf={'flex-start'}
            px={4}
            minWidth={'50px'}
          />

          <Flex {...styles.gridDetail}>
            <Box {...styles.leftGridDetail} order={0} flexBasis="100%">
              <Heading as="h2" size="lg" color="heading">
                {nftName}
              </Heading>
            </Box>
            <Box {...styles.leftGridDetail} order={2} flexBasis="100%">
              {!!NFTContractAddress && (
                <Tooltip label={NFTContractAddress}>
                  <Link href={NFTContractURL} fontWeight={'bold'} color={textColor} isExternal>
                    {NFTContractDisplay} <ExternalLinkIcon mx="2px" mb="4px" />
                  </Link>
                </Tooltip>
              )}
            </Box>
          </Flex>
          <HStack>
            {isMining && (
              <PrimaryButton borderRadius="full" disabled>
                <Text>{t('Common:IsMining')}</Text>
              </PrimaryButton>
            )}
            {isLocking && (
              <PrimaryButton borderRadius="full" disabled>
                <Text>{t('Common:OnSlimeGame')}</Text>
              </PrimaryButton>
            )}
            {isOwner && (
              <SellButton
                tokenId={tokenId}
                isLoading={(isGettingSaleInfo as boolean) || isGettingCurrentMiningTool}
                nftType={nftType}
                currentNFT={currentMyNFT}
                isDisabled={isActionDisabled}
                onAddSaleDone={onAddSaleDone}
              />
            )}
            {isShowGiftButton && (
              <GiftButton
                tokenId={tokenId}
                isLoading={(isGettingSaleInfo as boolean) || isGettingCurrentMiningTool}
                nftType={nftType}
                currentNFT={currentMyNFT}
                isDisabled={isActionDisabled}
                onGiftDone={onTransactionSubmitted}
              />
            )}
          </HStack>
          <NFTDetailQuantity
            forSaleAmount={forSaleAmount}
            onSaleAmount={onSaleAmount}
            totalAmount={totalAmount}
          />
          <OpenSaleOrderList
            nftType={nftType}
            tokenId={tokenId}
            titleStyle={titleSectionStyle}
            isLoading={!!isGettingSaleInfo}
            listOfOpenSaleOrders={listOfOpenSaleOrders}
          />
          <NFTAbouts metadata={metadata} nftType={nftType} titleStyle={titleSectionStyle} />
          <Box>
            {currentMyNFT?.owner && (
              <Text color={descriptionColor}>
                {t('Component:NFTDetail.OwnedBy')}{' '}
                <Tooltip label={currentMyNFT?.owner}>
                  <Link href={ownerAddressURL} fontWeight={'bold'} color={textColor} isExternal>
                    {ownerDisplay} <ExternalLinkIcon mx="2px" mb="4px" />
                  </Link>
                </Tooltip>
              </Text>
            )}
          </Box>
          <NFTAttributes metadata={metadata} nftType={nftType} titleStyle={titleSectionStyle} />
        </VStack>
      </Flex>
      {isGettingCurrentMyNFT && isGettingCurrentMiningTool && (
        <Spinner
          {...styles.loading}
          className={'spinner'}
          emptyColor={emptyColor}
          color={primaryColor}
        />
      )}
      <TransactionSubmittedModal
        isOpen={isOpenTransactionSubmittedModal}
        onClose={onCloseTransactionSubmittedModal}
        txid={currentTxid}
      />
    </>
  );
};

export default withRouter(NFTDetail);

const styles = {
  container: {
    flex: { base: 1 },
    p: { base: 2, md: 4 },
    width: '100%',
    flexDirection: { base: 'column' as 'column', md: 'row' as 'row' },
    maxWidth: { base: 'none' as 'none', lg: '1024px', xl: '1200px' },
  },
  leftContainer: {
    flex: { base: 'initial' as 'initial', md: 1 },
    p: { base: 0, md: 4 },
    py: { base: 4 },
    alignItems: 'center',
  },
  rightContainer: {
    flex: { base: 1 },
    pt: { base: 2, md: 4 },
    alignItems: 'inherit' as 'inherit',
    pos: 'relative' as 'inherit',
    spacing: 4,
  },
  backButton: { borderRadius: 'full', alignSelf: 'flex-start' },
  loading: {
    speed: '0.65s',
    size: 'xl',
    pos: 'absolute' as 'absolute',
    thickness: '5px',
  },
  gridDetail: {
    flexWrap: 'wrap' as 'wrap',
  },
  leftGridDetail: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '100%',
    overflow: 'hidden' as 'hidden',
  },
  titleSection: {
    fontSize: 'lg',
    pt: 4,
    pb: 2,
    fontWeight: 'bold' as 'bold',
  },
};
