import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { watchAccount, watchNetwork } from '@wagmi/core';
import { QueryKeys } from 'src/globals/constants';

const useWagmiListener = () => {
  const queryClient = useQueryClient();
  const oldAccount = useRef<string | null | undefined>(null);
  const oldNetwork = useRef<number | null | undefined>(null);
  const unwatchAccount = watchAccount((account) => {
    if (
      (account.address && account.address !== oldAccount.current) ||
      (oldAccount.current && !account.address)
    ) {
      oldAccount.current = account.address as string | null;
      queryClient.invalidateQueries([QueryKeys.wagmiAccountQueryKey]);
    }
  });
  const unwatchNetwork = watchNetwork((network) => {
    if (
      (network.chain?.id && network.chain.id !== oldNetwork.current) ||
      (!network.chain?.id && oldNetwork.current)
    ) {
      oldNetwork.current = network.chain?.id;
      queryClient.invalidateQueries([QueryKeys.wagmiAccountQueryKey]);
    }
  });

  useEffect(() => {
    return () => {
      unwatchAccount();
      unwatchNetwork();
    };
  }, [unwatchAccount, unwatchNetwork]);
};

export default useWagmiListener;
