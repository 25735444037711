import { Contract } from 'web3-eth-contract';
import Web3 from 'web3';
import { TransactionReceipt } from 'web3-core';
import { getConfig } from '../globals/env-config';
import { appUtils } from '../common';
import { METAMASK_NETWORK } from './constants';
import * as metaMaskCommon from './common';

export const MetamaskNetwork = METAMASK_NETWORK[getConfig('REACT_APP_ENV')];

export default class MetamaskProvider {
  static Contracts: Record<string, Contract> = {};
  static web3: Web3;
  static getEthereum = (): any | undefined => {
    const { ethereum } = window as any;
    return ethereum;
  };
  static getWeb3 = (): Web3 => {
    if (this.web3) {
      return this.web3;
    }
    const web3 = new Web3(new Web3.providers.HttpProvider(MetamaskNetwork.rpcUrl));
    this.web3 = web3;
    return web3;
  };

  static checkIsInstalled = (): Boolean => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const ethereum = MetamaskProvider.getEthereum();
    return Boolean(ethereum && ethereum.isMetaMask);
  };

  static requestConnect = async (): Promise<void> => {
    const ethereum = MetamaskProvider.getEthereum();
    // Will open the MetaMask UI
    // You should disable this button while the request is pending!
    if (ethereum) {
      await ethereum.request({ method: 'eth_requestAccounts' });
    }
  };

  static signMessage = async (message: string, walletAddress: string): Promise<string> => {
    return this.getEthereum().request({
      method: 'personal_sign',
      params: [message, walletAddress],
    });
  };

  static getAccountAddress = async (): Promise<string | null> => {
    const ethereum = MetamaskProvider.getEthereum();
    if (!ethereum) {
      return null;
    }
    //we use eth_accounts because it returns a list of addresses owned by us.
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    //We take the first address in the array of addresses and display it
    if (accounts && accounts.length > 0) {
      return Web3.utils.toChecksumAddress(accounts[0]);
    }
    return null;
  };

  static checkIsMetaMaskConnected = async (): Promise<boolean> => {
    const address = await MetamaskProvider.getAccountAddress();
    return !!address;
  };

  static addListenerAccountChange = (callback: (newAddress: string | null) => void) => {
    const ethereum = MetamaskProvider.getEthereum();
    if (!ethereum) {
      return null;
    }
    ethereum.on('accountsChanged', function (accounts: string[]) {
      if (accounts && accounts.length > 0) {
        callback(Web3.utils.toChecksumAddress(accounts[0]));
      } else {
        callback(null);
      }
    });
  };

  static addListenerNetworkChange = (callback: () => void) => {
    const ethereum = MetamaskProvider.getEthereum();
    if (!ethereum) {
      return null;
    }
    ethereum.on('networkChanged', function () {
      callback();
    });
  };

  static getCurrentNetwork = (): string => {
    const ethereum = MetamaskProvider.getEthereum();
    return ethereum.networkVersion;
  };

  static checkIsConnectedToBSCNetwork = (): boolean => {
    return this.getCurrentNetwork() === MetamaskNetwork.chainId;
  };

  static requestSwitchToBSCNetwork = async (): Promise<void> => {
    const ethereum = MetamaskProvider.getEthereum();
    if (ethereum) {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: MetamaskNetwork.chainHex }],
        });
      } catch (switchError: any) {
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: MetamaskNetwork.chainHex,
                  chainName: MetamaskNetwork.name,
                  nativeCurrency: {
                    name: MetamaskNetwork.currencyName,
                    symbol: MetamaskNetwork.currencySymbol,
                    decimals: 18,
                  },
                  rpcUrls: [MetamaskNetwork.rpcUrl],
                  blockExplorerUrls: [MetamaskNetwork.explorerUrl],
                },
              ],
            });
          } catch (addError) {
            throw addError;
          }
        } else {
          throw switchError;
        }
      }
    }
  };

  static getTransactionReceipt = async (txHash: string): Promise<TransactionReceipt> => {
    const receipt = await this.getWeb3().eth.getTransactionReceipt(txHash);
    if (!receipt) {
      await appUtils.sleep(1000);
      return this.getTransactionReceipt(txHash);
    }
    return receipt;
  };

  static requestSwitchNetworkIfUsedWrongNetwork = async (): Promise<void> => {
    if (!this.checkIsConnectedToBSCNetwork()) {
      await this.requestSwitchToBSCNetwork();
    }
  };

  static sendTransaction = async (data: string, to: string): Promise<TransactionReceipt> => {
    await this.requestSwitchNetworkIfUsedWrongNetwork();
    const transactionParameters = {
      to,
      from: await this.getAccountAddress(),
      data,
      chainId: MetamaskNetwork.chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };

    const txHash = await this.getEthereum().request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    });

    return this.getTransactionReceipt(txHash);
  };
}

export { metaMaskCommon };
