import configureStore from './store';
import { AppTypes } from './app';

export const { store, persistor } = configureStore();

export interface RootReducerState {
  app: AppTypes.AppState;
}

export * from './app';
