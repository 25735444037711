import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GRAPHQL_API } from 'src/globals/configs';

const apolloClient = new ApolloClient({
  uri: GRAPHQL_API,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default apolloClient;
