import { MetamaskNetwork } from 'src/metamask-provider';
import { useMemo } from 'react';
import { WalletType } from 'src/types/Wallet';
import useWagmiAccountQuery from './useWagmiAccountQuery';

const useWalletInfoQuery = () => {
  const { isLoading: isLoadingWagmiAccount, data: wagmiAccount } = useWagmiAccountQuery();

  const currentConnectedWallet = useMemo(() => {
    if (wagmiAccount && wagmiAccount?.chainId === parseInt(MetamaskNetwork.chainId)) {
      return {
        walletType: WalletType.WAGMI,
        connectedWalletPayload: wagmiAccount,
      };
    }
    if (wagmiAccount) {
      return {
        walletType: WalletType.WAGMI,
        connectedWalletPayload: wagmiAccount,
      };
    }
    return null;
  }, [wagmiAccount]);

  const currentAccount = currentConnectedWallet?.connectedWalletPayload?.address;

  const isWalletConnected = !!currentConnectedWallet;

  const walletType = currentConnectedWallet?.walletType;

  const connectedWalletPayload = useMemo(() => {
    return currentConnectedWallet?.connectedWalletPayload;
  }, [currentConnectedWallet?.connectedWalletPayload]);

  const isValidChainId =
    currentConnectedWallet?.connectedWalletPayload?.chainId === parseInt(MetamaskNetwork.chainId);

  const isValidConnected = isWalletConnected && isValidChainId;

  return {
    isWalletConnected,
    isCheckingConnectedWallet: isLoadingWagmiAccount,
    isValidChainId,
    isValidConnected,
    currentAccount,
    walletType,
    connectedWalletPayload,
  };
};

export default useWalletInfoQuery;
