import React from 'react';
import { HStack } from '@chakra-ui/react';
import { SVGComponents } from 'src/shared';
import { IMiningToolMetadata } from 'src/gql/types';
import { useTranslation } from 'react-i18next';
import { IconLabel } from 'src/components';
import InfoItem from './InfoItem';

interface MiningToolAttributesProps {
  metadata: IMiningToolMetadata;
}

const MiningToolAttributes = (props: MiningToolAttributesProps) => {
  const { t } = useTranslation();

  const { metadata } = props;
  const cooldown = metadata.cd / 100;
  const cooldownStr = `${cooldown}s`;
  const miningPower = metadata.mp / 100;
  const miningPowerStr = `${miningPower}%`;
  const luck = metadata.luck / 100;
  const luckStr = `${luck}%`;

  return (
    <HStack alignItems={'inherit'}>
      <InfoItem title={t('Term:Cooldown')}>
        <IconLabel
          value={cooldownStr}
          icon={<SVGComponents.CoolDownIcon width={24} height={24} />}
        />
      </InfoItem>
      <InfoItem title={t('Term:MiningPower')}>
        <IconLabel
          value={miningPowerStr}
          icon={<SVGComponents.MiningPowerIcon width={24} height={24} />}
        />
      </InfoItem>
      <InfoItem title={t('Term:Luck')}>
        <IconLabel value={luckStr} icon={<SVGComponents.LuckIcon width={24} height={24} />} />
      </InfoItem>
    </HStack>
  );
};

export default React.memo(MiningToolAttributes);
