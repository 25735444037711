import React from 'react';
import { HStack } from '@chakra-ui/react';
import { IMiningToolMetadata } from 'src/gql/types';
import { useTranslation } from 'react-i18next';
import { RarityLabel, CategoryLabel } from 'src/components';
import InfoItem from './InfoItem';

interface MiningToolAboutsProps {
  metadata: IMiningToolMetadata;
}

const MiningToolAbouts = (props: MiningToolAboutsProps) => {
  const { t } = useTranslation();

  const { metadata } = props;
  return (
    <HStack alignItems={'inherit'}>
      <InfoItem title={t('Term:Type')} text={t('Term:MiningTool')} />
      <InfoItem title={t('Term:Category')}>
        <CategoryLabel categoryId={metadata.cat} />
      </InfoItem>
      <InfoItem title={t('Term:Rarity')}>
        <RarityLabel rarity={metadata.rarity} />
      </InfoItem>
    </HStack>
  );
};

export default React.memo(MiningToolAbouts);
