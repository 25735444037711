export type UserMetadataRes = {
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
};
export type UserRes = {
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  id: number;
  email: string;
  walletAddress: string;
  username: string;
  userMetadata: UserMetadataRes;
};
export interface PaginationResult<Item> {
  offset: number;
  limit: number;
  total: number;
  hasNext: boolean;
  items: Item[];
}

export type AuthRegister = {
  walletAddress: string;
};

export type AuthLoginByAddress = {
  gameCode: string;
  walletAddress: string;
  signature: string;
};

export type TokenRes = {
  token: string;
  user: UserRes;
};

export type AuthLoginByUsername = {
  gameCode: string;
  username: string;
  password: string;
};

export type AuthLoginByEmail = {
  gameCode: string;
  email: string;
  password: string;
};

export type AuthBindingPayload = {
  email?: string;
  username?: string;
  password?: string;
};

export enum BindingType {
  email = 'email',
  username = 'username',
  password = 'password',
}

export type AuthBinding = {
  bindingType: BindingType;
  walletAddress: string;
  signature: string;
  payload: AuthBindingPayload;
};

export type UpdateUserInfo = {
  name: string;
};

export type AuthChangePassword = {
  password: string;
  oldPassword: string;
};

export type GetNoneRes = {
  walletAddress: string;
  nonce: string;
};
