import React from 'react';
import { BoxProps, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Label } from 'src/components/index';
import { Colors } from 'src/shared';

interface QuantityLabelProps extends BoxProps {
  quantity?: string;
}

const QuantityLabel = (props: QuantityLabelProps) => {
  const { quantity, ...remainingProps } = props;
  const { t } = useTranslation();
  const bgColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const color = useColorModeValue(Colors.light.quantity, Colors.dark.quantity);
  const isQuantityValid = !!quantity && Number(quantity) > 0;
  const quantityHintText = `${t('Term:Quantity')}: ${quantity || 0}`;
  const quantityText = `${t('Term:Quantity.Shortened')}: ${quantity || 0}`;
  return (
    <Tooltip isDisabled={!isQuantityValid} label={quantityHintText} hasArrow>
      <Label
        color={color}
        bg={bgColor}
        label={quantityText}
        minWidth="50px"
        rounded="full"
        {...remainingProps}
      />
    </Tooltip>
  );
};

export default QuantityLabel;
