import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';
import { WagmiAccount } from 'src/types/Wallet';
import * as wagmiAuthService from 'src/services/wagmiAuth';
import { sleep } from 'src/common/utils';

const useWagmiAccountQuery = () => {
  const { isFetching, data, error } = useQuery<WagmiAccount | null, string>(
    [QueryKeys.wagmiAccountQueryKey],
    async (): Promise<WagmiAccount | null> => {
      try {
        const walletAccount = await wagmiAuthService.getWalletAccount();
        const chain = await wagmiAuthService.getWalletNetwork();
        await sleep(100);
        return {
          address: walletAccount.address as string,
          chainId: chain.id,
        };
      } catch (error) {
        return null;
      }
    },
    {
      keepPreviousData: false,
      onError: (err) => {
        console.log('on error', err);
      },
    }
  );

  return { isLoading: isFetching, data, error };
};

export default useWagmiAccountQuery;
