import { gql } from '@apollo/client';

export const MINING_TOOL_METADATA_KEY = 'miningToolMetadata';
export const getMiningToolMetadataFragment = (key = MINING_TOOL_METADATA_KEY) => gql`
  fragment ${key} on MiningToolMetadata {
    templateId
    name
    cat
    rarity
    supply
    weight
    cd
    mp
    luck
  }
`;
