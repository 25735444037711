const AppSizes = {
  // use for app
  Icon: '40px',
  MenuIcon: '24px',
  NFTMenuIcon: '30px',
  ButtonMinWidth: '100px',
  BannerMaxWidth: '70%',
};
const sizes = {}; // use for theme

export default sizes;
export { AppSizes };
