import React from 'react';
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Colors } from 'src/shared';
import { appUtils, timeUtils } from 'src/common';
import { IFixedPriceSaleNFT } from 'src/gql/types';
import { FixedPriceSaleLabel } from 'src/components';

export interface NFTItemProps {
  item: IFixedPriceSaleNFT;
  rootPath: string;
}

const SaleHistoryItem = (props: NFTItemProps) => {
  const { t } = useTranslation();
  const { item, rootPath } = props;
  const hoverBg = useColorModeValue(Colors.light.cardBg, Colors.dark.cardBg);
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const priceInfo = item.sellPrice;
  const linkTo = appUtils.getNFTDetailUrl(item.id, rootPath);

  return (
    <Box w="full" overflow="hidden">
      <Link to={{ pathname: linkTo, state: { currentFixedPriceSaleNFT: item } }}>
        <Flex
          w="full"
          p={[2, 4]}
          rounded="md"
          overflow="hidden"
          bg={'transparent'}
          _hover={{
            bg: hoverBg,
          }}
          alignItems="center"
        >
          <HStack alignItems="flex-start" flex={1} pr={2}>
            <Flex pos="relative" p={0} flex={0.9} flexDirection="column">
              <Text color={descriptionColor} pb={2}>
                {t('Component:SaleHistoryList.Header.Buyer')}
              </Text>
              <Text color={textColor} pb={2}>
                {appUtils.shortenHash((item?.buyer?.address as string) || '')}
              </Text>
            </Flex>
            <Flex pos="relative" p={0} flex={0.9} flexDirection="column">
              <Text color={descriptionColor} pb={2}>
                {t('Component:SaleHistoryList.Header.Seller')}
              </Text>
              <Text color={textColor} pb={2}>
                {appUtils.shortenHash((item?.seller?.address as string) || '')}
              </Text>
            </Flex>
            <Flex pos="relative" p={0} flex={1.2} flexDirection="column" alignItems={'flex-end'}>
              <FixedPriceSaleLabel
                pb={2}
                data={{
                  price: priceInfo?.origin,
                  priceCurrency: priceInfo?.originalCurrency,
                }}
              />
              <Text
                color={textColor}
                pb={2}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {t('Component:SaleHistoryList.Info.Quantity', { quantity: item?.amount })}
              </Text>
              <Text
                color={textColor}
                pb={2}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {timeUtils.displayTimeFromNow(item.sellTime)}
              </Text>
            </Flex>
          </HStack>
          <ChevronRightIcon h={8} w={8} color={descriptionColor} mr={[-2, -4]} />
        </Flex>
      </Link>
    </Box>
  );
};

export default SaleHistoryItem;
