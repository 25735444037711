import React, { useMemo } from 'react';
import { DefaultLimit } from 'src/globals/constants';
import { Flex, Spinner, Table, Tbody, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import { Pagination, EmptyView } from 'src/components';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import useUserActivities from 'src/hooks/useUserActivities';
import { styles } from '../styles';
import UserActivityRow from './UserActivityRow';

interface UserActivityListProps {}

const UserActivityList = (props: UserActivityListProps) => {
  const { t } = useTranslation();
  const metaMaskWalletAddress = useCurrentAccount();

  const { userActivitiesQuery } = useUserActivities({ ownerId: metaMaskWalletAddress });

  const loadingActivities = userActivitiesQuery.isLoading || userActivitiesQuery.isFetching;

  const userActivities = useMemo(
    () => userActivitiesQuery.data?.records || [],
    [userActivitiesQuery.data?.records]
  );

  const userActivitiesTotal = userActivitiesQuery.data?.total || 0;

  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const emptyColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);
  const pageTotal = Math.ceil(userActivitiesTotal / DefaultLimit);
  return (
    <Flex flex={{ base: 1 }} flexDirection="column" pos="relative" overflowX={'auto'}>
      {Boolean(userActivities.length) && (
        <Table width={'full'}>
          <Thead>
            <Tr>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Transaction')}</Th>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Item')}</Th>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Type')}</Th>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Action')}</Th>
              <Th {...styles.column}>{t('Component:UserActivity.Header.Time')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userActivities.map((activity, index: number) => (
              <UserActivityRow
                key={index}
                activity={activity}
                currentAccountAddress={metaMaskWalletAddress}
              />
            ))}
          </Tbody>
        </Table>
      )}

      {!loadingActivities && !userActivities.length && (
        <EmptyView description={t('Component:UserActivityList.NoActivity')} mt="4" />
      )}

      {/* {Boolean(userActivities.length) && <Pagination pageTotal={pageTotal} />} */}
      <Pagination pageTotal={pageTotal} />

      {loadingActivities && (
        <Spinner
          className="spinner"
          thickness="5px"
          speed="0.65s"
          emptyColor={emptyColor}
          color={primaryColor}
          size="xl"
          pos="absolute"
        />
      )}
    </Flex>
  );
};

export default UserActivityList;
