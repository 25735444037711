import React from 'react';
import {
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { appUtils } from 'src/common';
import { CheckMark } from 'src/components';

interface ITransactionSubmittedModalProps {
  isOpen: boolean;
  onClose: () => void;
  txid?: string;
}

const TransactionSubmittedModal = ({ isOpen, onClose, txid }: ITransactionSubmittedModalProps) => {
  const { t } = useTranslation();
  const transactionUrl = appUtils.getTransactionExplorerLink(txid);

  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor={modalBackgroundColor}>
        <ModalHeader textAlign={'center'}>
          {t('Component:TransactionSubmittedModal.Title')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign={'center'}>
          <Flex flexDirection="column" alignItems="center">
            <CheckMark />
            <Text mt={5}>{t('Component:TransactionSubmittedModal.Description')}</Text>
            <Link my={5} href={transactionUrl} isExternal>
              {t('Component:TransactionSubmittedModal.ViewOn')}{' '}
              <ExternalLinkIcon mx="2px" mb="4px" />
            </Link>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TransactionSubmittedModal;
