import React from 'react';
import { Box, forwardRef, useColorModeValue, BoxProps } from '@chakra-ui/react';
import { Colors } from 'src/shared';

interface LabelProps extends BoxProps {
  label?: string;
}

const Label = forwardRef((props: LabelProps, ref) => {
  const { label, ...remainingProps } = props;

  const defaultBg = useColorModeValue(Colors.light.iconLabelBg, Colors.dark.iconLabelBg);

  return (
    <Box
      ref={ref}
      px="5px"
      py="3px"
      height="inherit"
      bg={defaultBg}
      rounded="full"
      alignSelf="flex-start"
      fontWeight="bold"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      textAlign="center"
      {...remainingProps}
    >
      {label}
    </Box>
  );
});

export default Label;
