import { Center, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ProfileLayout } from 'src/layouts';
import React from 'react';
import ApprovalSettingsPanel from '../../features/approvalSettings/components/ApprovalSettingsPanel';
import { Colors } from '../../shared';

const ApprovalSettings = () => {
  const { t } = useTranslation();

  const defaultColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  return (
    <ProfileLayout>
      <Flex flex={{ base: 1 }} p={{ base: 2, md: 4 }} flexDirection={'column'}>
        <Center my={10}>
          <Text color={defaultColor}>{t('Component:ApprovalSettings:Description')}</Text>
        </Center>
        <ApprovalSettingsPanel />
      </Flex>
    </ProfileLayout>
  );
};

export default ApprovalSettings;
