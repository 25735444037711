import { Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ProfileLayout } from 'src/layouts';
import React from 'react';
import { AccountSettingsPanel } from '../../features/accountSettings';

const AccountSettings = () => {
  const { t } = useTranslation();

  return (
    <ProfileLayout>
      <Flex flex={{ base: 1 }} p={{ base: 2, md: 4 }} flexDirection={'column'}>
        <Heading as="h1" color="heading">
          {t('Term:AccountSettings')}
        </Heading>
        <AccountSettingsPanel />
      </Flex>
    </ProfileLayout>
  );
};

export default AccountSettings;
