import {
  IAppVariable,
  IFilter,
  IFixedPriceSaleNFTVariable,
  IOrderByKeyInput,
  TNFTType,
} from 'src/gql/types';
import * as GQLApi from 'src/gql';
import { FixedPriceSaleStatus, QueryKeys } from 'src/globals/constants';
import { useQuery } from '@tanstack/react-query';
import { getFilterVariableByType, getOrderByVariableByType } from 'src/gql/common';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { coreUtils } from 'src/common';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useAvailableFixedPriceSaleNFTsForSale = (props: { nftType: TNFTType }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [variables, setVariables] = useState<IAppVariable | null>(null);

  useEffect(() => {
    const queryParams = queryString.parse(search);
    const variables = coreUtils.standardizeVariablesForQuery(queryParams);
    setVariables(variables);
  }, [search, history, setVariables]);

  const availableFixedPriceSaleNFTsForSaleQuery = useQuery(
    [QueryKeys.availableNftForSale, props.nftType, variables],
    () => {
      if (isNil(variables)) {
        throw new Error('Variables is null');
      }
      const { filter: filterByNFTType, orderBy: orderByNFTType, ...remainingVariables } = variables;

      const orderBy = isNil(orderByNFTType)
        ? undefined
        : getOrderByVariableByType(props.nftType, orderByNFTType as IOrderByKeyInput);
      const filter = isNil(filterByNFTType)
        ? undefined
        : getFilterVariableByType(props.nftType, filterByNFTType as IFilter);
      const newVariable: IFixedPriceSaleNFTVariable = {
        ...remainingVariables,
        filter,
        orderBy,
        status: FixedPriceSaleStatus.AVAILABLE,
        type: props.nftType,
      };
      return GQLApi.getFixedPriceSalesByNFTType(props.nftType, newVariable);
    },
    {
      enabled: !isNil(variables),
      keepPreviousData: true,
    }
  );
  return {
    availableFixedPriceSaleNFTsForSaleQuery,
    variables,
  };
};

export default useAvailableFixedPriceSaleNFTsForSale;
