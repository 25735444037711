import { DeepPartial } from '@chakra-ui/react';
import {
  IUserActivity,
  SaleDoneRawPayLoad,
  TransferBatchRawPayLoad,
  TransferRawPayLoad,
} from 'src/gql/user-activity/types';
import { SaleTransactionEvent } from 'src/globals/constants';

export const getActivityLabel = (
  params?: DeepPartial<IUserActivity>,
  currentAccountAddress?: string
) => {
  const { eventName } = params || {};
  switch (eventName) {
    case SaleTransactionEvent.SaleDone: {
      const isSeller = currentAccountAddress === (params?.rawPayload as SaleDoneRawPayLoad)?.seller;
      return isSeller
        ? 'Component:UserActivity.Label.SaleDoneSellerSide'
        : 'Component:UserActivity.Label.SaleDoneBuyerSide';
    }
    case SaleTransactionEvent.Transfer:
    case SaleTransactionEvent.TransferSingle: {
      const isSender = currentAccountAddress === (params?.rawPayload as TransferRawPayLoad)?.from;
      return isSender
        ? 'Component:UserActivity.Label.TransferSenderSide'
        : 'Component:UserActivity.Label.TransferReceiverSide';
    }
    case SaleTransactionEvent.TransferBatch: {
      const isSender =
        currentAccountAddress === (params?.rawPayload as TransferBatchRawPayLoad)?.from;
      return isSender
        ? 'Component:UserActivity.Label.TransfersSenderSide'
        : 'Component:UserActivity.Label.TransfersReceiverSide';
    }
    case SaleTransactionEvent.BuyToken:
    case SaleTransactionEvent.SalePriceChanged:
    case SaleTransactionEvent.SaleCanceled:
    case SaleTransactionEvent.SaleAdded:
    case SaleTransactionEvent.Approval:
    case SaleTransactionEvent.ApprovalForAll:
    case SaleTransactionEvent.ChangeTax:
    case SaleTransactionEvent.ModifySlime:
    case SaleTransactionEvent.RegisterSlime:
    case SaleTransactionEvent.UnregisterSlime:
      return `Component:UserActivity.Label.${eventName}`;
    default:
      return 'Component:UserActivity.Label.Unknown';
  }
};
