import * as GQLApi from 'src/gql';
import { FixedPriceSaleStatus, QueryKeys } from 'src/globals/constants';
import { useQuery } from '@tanstack/react-query';
import { IFixedPriceSaleNFTVariable, OrderByDirection, OrderByKey, TNFTType } from 'src/gql/types';
import { getOrderByVariableByType } from 'src/gql/common';
import { DefaultFixedPriceSaleNFTVariable } from 'src/gql/constants';

const useDashboardRecently = (props: { nftType: TNFTType; status: FixedPriceSaleStatus }) => {
  const dashboardRecentlyQuery = useQuery(
    [QueryKeys.dashboardRecently, props.nftType, props.status],
    () => {
      const orderBy = getOrderByVariableByType(props.nftType, {
        [OrderByKey.UPDATED_AT]: OrderByDirection.DESC,
      });
      const variable: IFixedPriceSaleNFTVariable = {
        ...DefaultFixedPriceSaleNFTVariable,
        orderBy,
      };
      return GQLApi.getFixedPriceSaleByStatus(props.nftType, props.status, variable);
    },
    {
      keepPreviousData: true,
    }
  );
  return {
    dashboardRecentlyQuery,
  };
};

export default useDashboardRecently;
