import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from 'src/components/ConfirmModal';

interface IConfirmCancelSellModalProps {
  isOpen: boolean;
  isConfirmLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmCancelSellModal = ({
  isOpen,
  isConfirmLoading,
  onConfirm,
  onCancel,
}: IConfirmCancelSellModalProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmModal
      isConfirmLoading={isConfirmLoading}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={t('Component:CancelSellButton.ConfirmCancelTitle')}
      description={t('Component:CancelSellButton.ConfirmCancelDescription')}
    />
  );
};

export default ConfirmCancelSellModal;
