import { Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppSizes, Colors, Resources } from 'src/shared';
import OutlineButton from 'src/components/Button/OutlineButton';
import useDoConnectWagmi from 'src/hooks/useDoConnectWagmi';
import { WalletType } from 'src/types/Wallet';
import { useState } from 'react';
import useChangeWagmiNetwork from 'src/hooks/useChangeWagmiNetwork';
import useDisconnectWagmi from 'src/hooks/useDisconnectWagmi';
import useAuth from 'src/hooks/useAuth';
import MetamaskProvider from 'src/metamask-provider';
import MetaMaskNotInstallModal from './MetaMaskNotInstallModal';

const ConnectButton = () => {
  const [isOpenInstallMetaMaskModal, setIsOpenInstallMetaMaskModal] = useState(false);
  const [, , isWalletConnected, isValidChainId] = useAuth();
  const [canChangeNetwork, setCanChangeNetwork] = useState(true);
  const { isLoading: isLoadingConnectWagmi, doChangeWagmiNetwork } = useChangeWagmiNetwork();
  const { isLoading: isDisconnecting, doDisconnectWagmi } = useDisconnectWagmi();
  const { t } = useTranslation();
  const color = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  const { isLoading: isConnectingWalletConnect, doConnect: doConnectWalletConnect } =
    useDoConnectWagmi(WalletType.WAGMI);
  const { isLoading: isConnectingMetaMask, doConnect: doConnectMetaMask } = useDoConnectWagmi(
    WalletType.WAGMI_METAMASK
  );

  const onPressConnectMetaMask = async () => {
    if (!MetamaskProvider.checkIsInstalled()) {
      // popup install metamask modal
      setIsOpenInstallMetaMaskModal(true);
      return;
    }
    await doConnectMetaMask();
  };

  const onPressChangeNetwork = async () => {
    const timeoutId = setTimeout(() => {
      setCanChangeNetwork(false);
    }, 3000);
    const changeSuccessfully = await doChangeWagmiNetwork();
    clearTimeout(timeoutId);
    if (!changeSuccessfully) {
      setCanChangeNetwork(false);
    }
  };

  const isDisable = isConnectingWalletConnect || isConnectingMetaMask;

  if (isWalletConnected && !isValidChainId) {
    return (
      <>
        {canChangeNetwork && (
          <OutlineButton
            color={color}
            isLoading={isLoadingConnectWagmi}
            onClick={onPressChangeNetwork}
          >
            {t('Component:ModalMetaMaskNotConnectBSC.SwitchToBSCNetwork')}
          </OutlineButton>
        )}
        {!canChangeNetwork && (
          <OutlineButton color={color} isLoading={isDisconnecting} onClick={doDisconnectWagmi}>
            {t('Component:ModalMetaMaskNotConnectBSC.Disconnect')}
          </OutlineButton>
        )}
      </>
    );
  }

  return (
    <>
      <Flex direction="column" gap="5">
        <OutlineButton
          color={color}
          isLoading={isConnectingMetaMask}
          disabled={isDisable}
          onClick={onPressConnectMetaMask}
        >
          <Image boxSize={AppSizes.MenuIcon} src={Resources.svgs.metamask} marginRight={3} />
          <Text>{t('Component:MetaMaskConnect.ConnectBtn')}</Text>
        </OutlineButton>
        <OutlineButton
          color={color}
          isLoading={isConnectingWalletConnect}
          disabled={isDisable}
          onClick={doConnectWalletConnect}
        >
          <Image boxSize="34px" src={Resources.svgs.walletConnect} marginRight={3} />
          <Text>{t('Component:WalletConnect.ConnectBtn')}</Text>
        </OutlineButton>
      </Flex>
      <MetaMaskNotInstallModal
        isOpen={isOpenInstallMetaMaskModal}
        onClose={() => {
          setIsOpenInstallMetaMaskModal(false);
        }}
      />
    </>
  );
};

export default ConnectButton;
