import * as GQLApi from 'src/gql';
import { QueryKeys } from 'src/globals/constants';
import { useQuery } from '@tanstack/react-query';

const useMarketplaceSummaries = () => {
  const marketplaceSummariesQuery = useQuery([QueryKeys.marketplaceSummaries], () => {
    return GQLApi.getMarketplaceSummaries();
  });
  return {
    marketplaceSummariesQuery,
  };
};

export default useMarketplaceSummaries;
