import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';
import * as approvalService from 'src/services/approval';

const useApprovalStatus = (approvalRelationKey: string, address?: string) => {
  const { isFetching, data, error } = useQuery<boolean, string>(
    [QueryKeys.approvalSettingQuery, address, approvalRelationKey],
    async () => {
      if (!address) return false;
      return approvalService.getApprovalStatus(address, approvalRelationKey);
    },
    {
      keepPreviousData: false,
    }
  );

  return { isLoading: isFetching, data, error };
};

export default useApprovalStatus;
