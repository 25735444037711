import Component from './translation/component.json';
import Term from './translation/term.json';
import Common from './translation/common.json';
import PageName from './translation/page-name.json';
import SEO from './translation/seo.json';
import Skills from './translation/skills.json';

const lang = { Component, Term, Common, PageName, SEO, Skills };

export default lang;
