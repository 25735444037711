import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ACCOUNT_SERVICE_API } from '../globals/configs';

let _token: string | null;
let _deviceId: string | null;

export const setToken = (token: string) => {
  _token = token;
  localStorage.setItem('token', _token);
};

export const removeToken = () => {
  _token = null;
  localStorage.removeItem('token');
};

export const getToken = () => {
  if (!_token) {
    _token = localStorage.getItem('token');
  }
  return _token;
};

export const getDeviceId = () => {
  if (_deviceId) {
    return _deviceId;
  }
  _deviceId = uuidv4();
  window.localStorage.setItem('deviceId', _deviceId as string);

  return _deviceId;
};

const requestAuthInterceptor = (req: AxiosRequestConfig): AxiosRequestConfig => {
  // TODO: handle auth _token here
  if (getToken()) {
    req.headers = req.headers || {};
    req.headers.Authorization = `Bearer ${getToken()}`;
  }
  return req;
};

const responseRejectInterceptor = (res: any): AxiosResponse => {
  if (res.status === 401) {
    removeToken();
  }
  throw res;
};

const responseFulfilledInterceptor = (res: any): AxiosResponse => {
  return res.data;
};

const instance = axios.create({
  baseURL: ACCOUNT_SERVICE_API,
});

instance.interceptors.request.use(requestAuthInterceptor);
instance.interceptors.response.use(responseFulfilledInterceptor, responseRejectInterceptor);

export default instance;
