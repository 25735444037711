import React from 'react';
import { Box, Button, Tooltip, useColorModeValue, Flex, VStack, Text } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import { appUtils } from 'src/common';

interface IWalletAddressInMobileViewProps {
  onClickCopyAddress: () => void;
  walletAddress?: string;
  isLoading?: boolean;
  hasCopied: boolean;
}

const WalletAddressInMobileView: React.FC<IWalletAddressInMobileViewProps> = ({
  walletAddress,
  onClickCopyAddress,
  isLoading,
  hasCopied,
}) => {
  const { t } = useTranslation();
  const activeBg = useColorModeValue(Colors.light.bg, Colors.dark.bg);

  if (isLoading || !walletAddress) {
    return <Button isLoading bg={activeBg} width={50} />;
  }
  return (
    <Box
      padding={2}
      as="span"
      textAlign="center"
      borderRadius={20}
      borderWidth={1}
      paddingY={5}
      paddingX={2}
      backgroundColor={activeBg}
    >
      <Flex flexFlow="column" justifyContent="center">
        <VStack>
          <Text>{t('Component:WalletAddress:MyWallet')}</Text>
        </VStack>
        <VStack>
          <span>
            <Tooltip label={walletAddress}>
              <span>{walletAddress && appUtils.shortenHash(walletAddress)}</span>
            </Tooltip>
            <Tooltip label={hasCopied ? t('Copied') : t('Copy')} closeDelay={1000}>
              <span style={{ cursor: 'pointer' }} onClick={onClickCopyAddress}>
                <CopyIcon mx="2px" mb="4px" ml="4px" />
              </span>
            </Tooltip>
          </span>
        </VStack>
      </Flex>
    </Box>
  );
};

export default WalletAddressInMobileView;
