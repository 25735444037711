import React, { SVGProps } from 'react';

const MiningPowerIcon = (props: SVGProps<SVGSVGElement>) => {
  const { color = '#F9B212', ...remainingProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...remainingProps}>
      <path
        d="M32.16 32.75a64.61 64.61 0 0 0-15.3 3.58 112.74 112.74 0 0 1 15.3-3.58ZM34.61 32.11h.62v-3a4.68 4.68 0 0 0-.67 3ZM33.94 33c1.46.43-.52 4.11-.52 4.11h2.28v-4.44a4.75 4.75 0 0 0-1.76.33Z"
        fill={color}
      />
      <path
        d="M43.22 40.66c-2-1.74-6-5.38-10.81-10.2-2.82-2.82-2.3-3.36-5.91-7.13l1-1.11v-.56a2.56 2.56 0 0 0-1.41-2.3l-.11-.07a8 8 0 0 1 1.29-1.56A50.92 50.92 0 0 1 35 11.42L42.51 4l-6.23 2.17c-7.53 2.62-11.86 6.67-15.5 9.61l-.29-.29a4.56 4.56 0 0 0-3-1.51 2.17 2.17 0 0 0-1.58.63l-1.3 1.29a2.21 2.21 0 0 0-.61 1.59 4.58 4.58 0 0 0 1.51 3l.29.28c-2.95 3.64-7 8-9.62 15.5L4 42.49l7.18-7.18.24-.31a52.62 52.62 0 0 1 6.3-7.63 8.14 8.14 0 0 1 1.55-1.29l.08.12a2.57 2.57 0 0 0 2.29 1.4h.57l1.1-1.09c3.78 3.64 4.32 3.11 7.14 5.93 4.82 4.82 8.46 8.85 10.2 10.82A2.09 2.09 0 0 0 42 44a.7.7 0 0 0 .54-.2l1.25-1.25A.7.7 0 0 0 44 42a2 2 0 0 0-.78-1.34Z"
        fill={color}
      />
    </svg>
  );
};

export default MiningPowerIcon;
