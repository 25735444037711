import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppSizes, Colors } from 'src/shared';

interface IConfirmModalProps {
  isOpen: boolean;
  isConfirmLoading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  cancelText?: string;
  confirmText?: string;
}

const ConfirmModal = ({
  isOpen,
  onConfirm,
  onCancel,
  isConfirmLoading,
  title,
  description,
  cancelText,
  confirmText,
}: IConfirmModalProps) => {
  const { t } = useTranslation();
  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );
  const defaultBg = useColorModeValue(Colors.dark.primary, Colors.dark.primary);
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent backgroundColor={modalBackgroundColor}>
        <ModalHeader textAlign={'center'}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} textAlign={'center'}>
          {description}
        </ModalBody>

        <ModalFooter mx={'auto'}>
          <Button
            borderRadius={'full'}
            bg={defaultBg}
            mr={3}
            type="submit"
            isLoading={isConfirmLoading}
            onClick={onConfirm}
            minWidth={AppSizes.ButtonMinWidth}
          >
            {confirmText || t('Confirm')}
          </Button>
          <Button minWidth={AppSizes.ButtonMinWidth} borderRadius={'full'} onClick={onCancel}>
            {cancelText || t('Cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
