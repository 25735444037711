import React, { PropsWithChildren } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Flex, Text, useColorModeValue, FlexProps } from '@chakra-ui/react';
import { Colors } from 'src/shared';

interface InfoItemProps extends PropsWithChildren<FlexProps> {
  text?: string;
  title?: string;
}

const InfoItem = (props: InfoItemProps) => {
  const { text, title = '', children, ...remainingProps } = props;
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const renderElement = () => {
    if (isEmpty(text)) return children;
    return (
      <Text py={'3px'} color={textColor} fontWeight={'bold'}>
        {text}
      </Text>
    );
  };
  return (
    <Flex pos={'relative'} p={0} flex={1} flexDirection={'column'} {...remainingProps}>
      {!isEmpty(title) && (
        <Text color={descriptionColor} pb={2}>
          {title}
        </Text>
      )}
      {renderElement()}
    </Flex>
  );
};

export default InfoItem;
