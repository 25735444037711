import React, { useCallback, useEffect, useState } from 'react';
import Select, { SelectItemRenderer, SelectRenderer } from 'react-dropdown-select';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import omit from 'lodash/omit';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { Box, BoxProps, Text, useColorModeValue } from '@chakra-ui/react';
import { QueryString, SortLabel, SortType } from 'src/globals/constants';
import { Colors } from 'src/shared';
import get from 'lodash/get';
import { isDefaultSortType } from '../common';

interface SortingSelectorProps extends BoxProps {
  options: SortType[];
}

const SortingSelector = (props: SortingSelectorProps) => {
  const { options, ...remainingProps } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const [sortType, setSortType] = useState<string>(SortType.DEFAULT);
  const { search, pathname } = useLocation();
  const queryParams = queryString.parse(search);

  const activeText = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const text = useColorModeValue(Colors.light.text, Colors.dark.text);
  const bg = useColorModeValue(Colors.light.bg, Colors.dark.bg);

  useEffect(() => {
    const sortQuery = get(queryParams, QueryString.SORT, SortType.DEFAULT);
    setSortType(sortQuery as string);
  }, [queryParams]);

  const updateSortingOnURL = useCallback(
    (sortType: string) => {
      const newQueryParams = isDefaultSortType(sortType)
        ? omit(queryParams, [QueryString.SORT])
        : { ...queryParams, [QueryString.SORT]: sortType };
      const querySearch = queryString.stringify(newQueryParams);
      history.push(`${pathname}?${querySearch}`);
    },
    [queryParams, history, pathname]
  );

  const sortingOptions = map(options, (key: string) => ({
    label: t(SortLabel[key as SortType]),
    value: key,
  }));

  const getSelectOption = () => {
    const selectOption = find(sortingOptions, (op) => isEqual(op.value, sortType));
    return selectOption || sortingOptions[0];
  };

  const contentRenderer = ({ state }: SelectRenderer<{ label: string; value: string }>) => {
    return (
      <Text py={2} px={4}>
        {state.values[0].label}
      </Text>
    );
  };

  const itemRenderer = ({
    item,
    methods,
  }: SelectItemRenderer<{ label: string; value: string }>) => {
    return (
      <Box
        py={2}
        px={4}
        color={'body'}
        _hover={{ backgroundColor: methods.isSelected(item) ? 'mainBg' : 'active' }}
        bg={bg}
        onClick={() => methods.addItem(item)}
        cursor="pointer"
      >
        <Text color={methods.isSelected(item) ? activeText : text}>{item.label}</Text>
      </Box>
    );
  };
  return (
    <Box {...remainingProps}>
      <Select
        multi={false}
        options={sortingOptions}
        values={[getSelectOption()]}
        onChange={(values) => updateSortingOnURL(values[0].value)}
        contentRenderer={contentRenderer}
        itemRenderer={itemRenderer}
        className={'sort-by'}
      />
    </Box>
  );
};

export default SortingSelector;
