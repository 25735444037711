import * as GQLApi from 'src/gql';
import { QueryKeys } from 'src/globals/constants';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { coreUtils } from 'src/common';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { IGetUserActivitiesVariable } from 'src/gql/user-activity/types';

const useUserActivities = (props: { ownerId?: string }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [variables, setVariables] = useState<IGetUserActivitiesVariable | null>(null);

  useEffect(() => {
    const queryParams = queryString.parse(search);
    const variables = coreUtils.standardizeVariablesForQuery(queryParams);
    if (!props.ownerId) {
      return;
    }

    setVariables({
      ...variables,
      user: props.ownerId,
    });
  }, [search, history, setVariables, props.ownerId]);

  const userActivitiesQuery = useQuery(
    [QueryKeys.userActivities, props.ownerId, variables],
    () => {
      if (isNil(variables)) {
        throw new Error('Variables is null');
      }
      return GQLApi.getUserActivities(variables);
    },
    {
      enabled: !isNil(variables),
      keepPreviousData: true,
    }
  );
  return {
    userActivitiesQuery,
    variables,
  };
};

export default useUserActivities;
