import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'src/hooks';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import useFTCBalanceQuery from 'src/hooks/useFTCBalanceQuery';
import BalanceInDesktopView from './BalanceInDesktopView';
import BalanceInMobileView from './BalanceInMobileView';

interface IBalanceProps {
  isDesktopView: boolean;
}

const Balance = ({ isDesktopView }: IBalanceProps) => {
  const dispatch = useDispatch();

  const [signedIn] = useAuth();
  const metaMaskWalletAddress = useCurrentAccount();
  const { isLoading: isLoadingFTCBalance, data: ftcBalance } =
    useFTCBalanceQuery(metaMaskWalletAddress);
  const balance = useMemo(() => {
    if (!ftcBalance) {
      return '0';
    }
    return ftcBalance.toString();
  }, [ftcBalance]);

  useEffect(() => {
    if (signedIn && metaMaskWalletAddress) {
      // dispatch(WalletAction.getFTCBalance());
    }
  }, [dispatch, signedIn, metaMaskWalletAddress]);

  if (!signedIn) {
    return null;
  }

  if (isDesktopView)
    return <BalanceInDesktopView balance={balance} isLoading={isLoadingFTCBalance} />;

  return <BalanceInMobileView balance={balance} isLoading={isLoadingFTCBalance} />;
};

export default Balance;
