import React, { ReactElement } from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { Colors } from 'src/shared';
import { HEADER_HEIGHT } from 'src/globals/constants';
import { useGetSecondaryHeaderBarHeight } from 'src/hooks';
import { ProfileLayout } from '../ProfileLayout';
import DashboardNav from '../DashboardLayout/DashboardNav';
import { MyNFTMenuLinks } from '../constants';
import ToggleFilterMenu from './ToggleFilterButton';
import FilterSection from './FilterSection';

interface InventoryLayoutProps {
  children: ReactElement | ReactElement[];
  renderFilterContent?: () => ReactElement;
}
const InventoryLayout = (props: InventoryLayoutProps) => {
  const { children, renderFilterContent } = props;
  const borderColor = useColorModeValue(Colors.light.divider, Colors.dark.divider);
  const bg = useColorModeValue(Colors.light.secondaryBg, Colors.dark.secondaryBg);
  const filterBg = useColorModeValue(Colors.light.filterBg, Colors.dark.filterBg);
  const secondaryHeaderBarHeight = useGetSecondaryHeaderBarHeight();

  return (
    <ProfileLayout>
      <Flex
        h={secondaryHeaderBarHeight}
        pos="sticky"
        zIndex="sticky"
        direction="row"
        top={HEADER_HEIGHT}
        w="full"
        borderBottom={1}
        borderStyle="solid"
        borderColor={borderColor}
        bg={bg}
        alignItems="center"
        justifyContent="space-between"
      >
        <DashboardNav menuLinks={MyNFTMenuLinks} flex={1} />
        {!!renderFilterContent && <ToggleFilterMenu mr={4} borderStyle="solid" bg={filterBg} />}
      </Flex>
      <Flex
        flex={{ base: 1 }}
        flexDirection="row"
        pos="relative"
        height={`calc(100vh - ${secondaryHeaderBarHeight} - ${HEADER_HEIGHT})`}
      >
        {children}
        {!!renderFilterContent && <FilterSection>{renderFilterContent()}</FilterSection>}
      </Flex>
    </ProfileLayout>
  );
};

export default InventoryLayout;
