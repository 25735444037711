import { useCallback, useState } from 'react';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import * as marketService from 'src/services/market';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';

const useAddNFTToFixedPriceSaleMarket = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [isAddingNFTToFixedPriceSaleMarket, setIsAddingNFTToFixedPriceSaleMarket] = useState<
    boolean | undefined
  >();
  const queryClient = useQueryClient();
  const onAddNFTToFixedPriceSaleMarket = useCallback(
    (
      tokenId: number,
      price: string,
      amount: number,
      onTransactionSubmitted?: (txid: string) => void
    ) => {
      setError(undefined);
      setIsAddingNFTToFixedPriceSaleMarket(true);
      marketService
        .addNFTToFixedPriceSaleMarket(
          coreUtils.getMarketContractByNftType(nftType),
          tokenId,
          coreUtils.getTokenFormatByNftType(nftType),
          price,
          amount
        )

        .then((hash) => {
          if (onTransactionSubmitted) {
            onTransactionSubmitted(hash);
          }
          queryClient.invalidateQueries([QueryKeys.availableNftForSale]);
          queryClient.invalidateQueries([QueryKeys.myNfts]);
          queryClient.invalidateQueries([QueryKeys.myNftDetails]);
          queryClient.invalidateQueries([QueryKeys.userActivities]);
        })
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsAddingNFTToFixedPriceSaleMarket(false);
        });
    },
    [nftType, queryClient]
  );

  return [isAddingNFTToFixedPriceSaleMarket, onAddNFTToFixedPriceSaleMarket, error];
};

export default useAddNFTToFixedPriceSaleMarket;
