import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { Colors } from 'src/shared';

interface ApprovalSettingsSectionProps {
  sectionTitle: string;
  children: React.ReactNode;
}

const ApprovalSettingsSection = ({ sectionTitle, children }: ApprovalSettingsSectionProps) => {
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);

  return (
    <Flex flexDirection="column" pos="relative" overflowX={'auto'} mt={14}>
      <Text color={textColor} fontWeight="bold">
        {sectionTitle}:
      </Text>
      <Box>{children}</Box>
    </Flex>
  );
};
export default ApprovalSettingsSection;
