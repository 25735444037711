import { useEffect, useState } from 'react';
import {
  Box,
  Text,
  useColorModeValue,
  Checkbox,
  CheckboxGroup,
  Wrap,
  WrapItem,
  VStack,
} from '@chakra-ui/react';
import { Colors } from 'src/shared';

interface SelectionProps {
  options: { label: any; value: any }[];
  currentValue: number[];
  onChange: (value: number[]) => void;
  title?: string;
}

const Selection = (props: SelectionProps) => {
  const { currentValue, onChange, title = '', options } = props;

  const [value, setValue] = useState<string[]>(currentValue.map((e) => e.toString()));
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  useEffect(() => {
    setValue(currentValue.map((e) => e.toString()));
  }, [currentValue]);

  return (
    <Box pos="relative" w="full" padding={4}>
      <VStack spacing={2} align="flex-start">
        <Text marginLeft={'-6px'} color={descriptionColor}>
          {title}
        </Text>
        <CheckboxGroup
          defaultValue={[]}
          value={value.map((e) => e.toString())}
          onChange={(v) => {
            setValue(v as string[]);
            onChange(v.map((e) => Number(e)));
          }}
        >
          <Wrap spacing={5}>
            {options.map(({ label, value }, index) => (
              <WrapItem key={index}>
                <Checkbox value={value.toString()}>{label}</Checkbox>
              </WrapItem>
            ))}
          </Wrap>
        </CheckboxGroup>
      </VStack>
    </Box>
  );
};

export default Selection;
