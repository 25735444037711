import { useCallback, useState } from 'react';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import * as marketService from 'src/services/market';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';

const useTransferNFTAsGift = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [isTransferring, setIsTransferring] = useState<boolean | undefined>();
  const currentAccount = useCurrentAccount();
  const queryClient = useQueryClient();
  const onTransferNFT = useCallback(
    (toAddress: string, tokenId: number, amount: number, onGiftDone?: (txid: string) => void) => {
      if (!currentAccount) {
        return;
      }
      setError(undefined);
      setIsTransferring(true);
      marketService
        .transferNFT(
          coreUtils.getNftContractByNftType(nftType),
          currentAccount,
          toAddress,
          tokenId,
          amount,
          coreUtils.getTokenFormatByNftType(nftType)
        )
        .then((hash) => {
          if (onGiftDone) {
            onGiftDone(hash);
          }
          queryClient.invalidateQueries([QueryKeys.myNfts]);
          queryClient.invalidateQueries([QueryKeys.myNftDetails]);
          queryClient.invalidateQueries([QueryKeys.userActivities]);
        })
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsTransferring(false);
        });
    },
    [currentAccount, nftType, queryClient]
  );

  return [isTransferring, onTransferNFT, error];
};

export default useTransferNFTAsGift;
