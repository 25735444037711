export const DefaultNFTSummary = {
  miningTool: {
    category: {
      dataType: 'number',
      values: ['1', '2', '4'],
    },
    rarity: {
      dataType: 'number',
      values: ['1', '2', '3', '4', '5'],
    },
    mp: {
      max: 650,
      min: 0,
      unit: '%',
    },
    cd: {
      min: 18000,
      max: 210000,
      unit: 's',
    },
    luck: {
      max: 2500,
      min: 0,
      unit: '%',
    },
  },
  land: {
    mp: {
      min: 0,
      max: 650,
      unit: null,
    },
    cd: {
      max: 210000,
      min: 18000,
      unit: null,
    },
    luck: {
      min: 0,
      max: 2500,
      unit: null,
    },
  },
  gene: {
    geneClass: {
      dataType: 'number',
      values: ['1', '2', '3', '4', '5', '6'],
    },
    part: {
      dataType: 'number',
      values: ['1', '2', '3', '4', '5'],
    },
    rarity: {
      values: ['1', '2', '3', '4', '5'],
      dataType: 'number',
    },
  },
};
