import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useColorModeValue,
  ButtonProps,
  Text,
  HStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AppSizes, Colors } from 'src/shared';
import { INFT, TNFTType } from 'src/gql/types';
import { NFTFormat } from '../../../globals/constants';
import useNftName from '../../../hooks/useNftName';

interface IPurchaseConfirmationModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  isApproved: boolean;
  isBuying: boolean;
  isApproving: boolean;
  onClose: () => void;
  onBuy: () => void;
  onApprove: () => void;
  currentNFT?: INFT | null;
  defaultPrice?: string;
  defaultAmount?: string;
}

const PurchaseConfirmationModal = ({
  isOpen,
  onClose,
  currentNFT,
  onBuy,
  onApprove,
  isLoading,
  isApproved,
  isBuying,
  isApproving,
  defaultPrice = '0',
  defaultAmount = '0',
}: IPurchaseConfirmationModalProps) => {
  const { t } = useTranslation();

  const descriptionText = t('Component:PurchaseConfirmationModal.Description', {
    token: t('Term:Marketplace.FTCToken'),
  });
  const primaryBg = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const secondaryBg = useColorModeValue(Colors.light.secondary, Colors.dark.secondary);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const borderColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );

  let approveButtonStyle: ButtonProps = {
    ...styles.button,
  };
  if (!isApproved) {
    approveButtonStyle = { ...approveButtonStyle, bg: primaryBg };
  }

  let buyNowButtonStyle: ButtonProps = {
    ...styles.button,
    mr: 3,
  };
  if (isApproved) {
    buyNowButtonStyle = { ...buyNowButtonStyle, bg: secondaryBg };
  }

  const nftName = useNftName({
    item: currentNFT || undefined,
    nftType: currentNFT?.type as TNFTType | undefined,
  });

  const isERC721 = currentNFT?.format === NFTFormat.ERC721;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor={modalBackgroundColor}>
        <ModalHeader textAlign={'center'}>{nftName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack} pb={6} spacing={4}>
          {!isApproved && (
            <Text color={descriptionColor} textAlign={'center'}>
              {descriptionText}
            </Text>
          )}
          <HStack spacing={4} alignItems="flex-start">
            <FormControl flex={isERC721 ? 1 : 0.6}>
              <FormLabel htmlFor="priceInput">
                {t('Component:PlaceOrderModal.Input.Price')}:
              </FormLabel>
              <InputGroup>
                <InputLeftAddon children="FTC" borderColor={borderColor} />
                <Input
                  borderColor={borderColor}
                  defaultValue={defaultPrice}
                  disabled
                  placeholder={t('Component:PlaceOrderModal.Holder.Price')}
                  type="number"
                />
              </InputGroup>
            </FormControl>
            {!isERC721 && (
              <FormControl flex={0.4}>
                <FormLabel htmlFor="amountInput">
                  {t('Component:PlaceOrderModal.Input.Amount')}:
                </FormLabel>
                <Input
                  borderColor={borderColor}
                  defaultValue={defaultAmount}
                  disabled
                  placeholder={t('Component:PlaceOrderModal.Holder.Amount')}
                  type="number"
                />
              </FormControl>
            )}
          </HStack>
        </ModalBody>

        <ModalFooter mx={'auto'}>
          <Button
            {...buyNowButtonStyle}
            onClick={onBuy}
            isLoading={isBuying}
            disabled={isLoading || !isApproved}
          >
            {t('Common:BuyNow')}
          </Button>
          {!isApproved && (
            <Button
              {...approveButtonStyle}
              onClick={onApprove}
              isLoading={isApproving}
              disabled={isLoading || isApproved}
            >
              {t('Common:Approve')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PurchaseConfirmationModal;

const styles = {
  button: {
    borderRadius: 'full' as 'full',
    minWidth: AppSizes.ButtonMinWidth,
  },
};
