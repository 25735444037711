import React, { memo, useMemo } from 'react';
import { Box, Heading, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Colors } from 'src/shared';
import { appUtils, coreUtils } from 'src/common';
import { IFixedPriceSaleNFT, IGeneMetadata, ISlimeMetadata } from 'src/gql/types';
import {
  FixedPriceSaleStatus,
  NFTType,
  RarityType,
  SLIME_FRAME_BY_RARITY,
} from 'src/globals/constants';
import { FixedPriceSaleLabel, PrimaryButton, QuantityLabel } from 'src/components';
import useNftName from '../../../hooks/useNftName';
import useCurrentAccount from 'src/hooks/useWalletAddress';
import { useTranslation } from 'react-i18next';

export interface NFTItemProps {
  item: IFixedPriceSaleNFT;
  rootPath: string;
}

const FixedPriceSaleNFTItem = (props: NFTItemProps) => {
  const { item, rootPath } = props;
  const borderColor = useColorModeValue(Colors.light.divider, Colors.dark.divider);
  const bg = useColorModeValue(Colors.light.cardBg, Colors.dark.cardBg);
  const hoverColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const priceInfo = coreUtils.getPriceInfoByStatus(item);
  const nftType = get(item, 'item.type', '') as NFTType;
  const nftTitle = useNftName({ item: item.item, nftType, includeIndex: true });
  const currentAccount = useCurrentAccount();
  const { t } = useTranslation();

  const isOwned = item?.seller.address === currentAccount;

  const isAvailableOnSale = item?.status === FixedPriceSaleStatus.AVAILABLE;
  const nftItemImage = useMemo(() => {
    return coreUtils.getNftImage(
      nftType,
      item.tokenId,
      item.templateId,
      !!(item.item?.metadata as IGeneMetadata)?.extractedData,
      (item.item?.metadata as IGeneMetadata)?.extractedData?.variant,
      item.item.updatedAt
    );
  }, [item, nftType]);

  const slimeBackgroundImage = useMemo(() => {
    if (nftType === NFTType.SLIME && item) {
      const slimeMetadata = item.item?.metadata as ISlimeMetadata;
      return SLIME_FRAME_BY_RARITY[slimeMetadata.faceDetails.rarity as RarityType] || null;
    }
    return null;
  }, [nftType, item]);

  const boxImageStyle = {
    backgroundImage: nftType === NFTType.SLIME ? `url(${slimeBackgroundImage})` : '',
    backgroundSize: nftType === NFTType.SLIME ? 'cover' : '',
    backgroundColor: nftType === NFTType.SLIME ? 'rgba(0, 0, 0, 0.5)' : '',
    borderRadius: nftType === NFTType.SLIME ? '20%' : '0px',
    position: 'relative' as 'relative',
  };

  const linkTo = appUtils.getNFTDetailUrl(item.id, rootPath);
  return (
    <Box
      maxW={nftType === NFTType.LAND ? '350px' : '260px'}
      w={'full'}
      p={[2, 4]}
      overflow={'hidden'}
    >
      <Link to={{ pathname: linkTo, state: { currentFixedPriceSaleNFT: item } }}>
        <Box
          w={'full'}
          bg={bg}
          p={[2, 4]}
          rounded={'borderItem'}
          overflow={'hidden'}
          border={1}
          borderStyle={'solid'}
          borderColor={borderColor}
          _hover={{
            borderColor: hoverColor,
          }}
        >
          <Box mb={[2, 4]}>
            {nftType === NFTType.LAND ? (
              <Image {...styles.landImage} src={nftItemImage} />
            ) : (
              <Box {...boxImageStyle}>
                <Image {...styles.image} src={nftItemImage} />
                {isOwned && (
                  <Box position="absolute" right={4} bottom={4}>
                    <PrimaryButton borderRadius="full" disabled>
                      <Text>{t('Common:YourNft')}</Text>
                    </PrimaryButton>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          <Box pt={2}>
            <Stack spacing={0} align={'left'} mb={2}>
              <Heading fontSize={'l'} fontWeight={700} fontFamily={'body'} isTruncated>
                {nftTitle}
              </Heading>
            </Stack>

            <Stack direction={'row'} justify={'space-between'} alignItems={'center'} spacing={6}>
              <QuantityLabel quantity={item?.amount} />
              {isAvailableOnSale && (
                <FixedPriceSaleLabel
                  data={{
                    price: priceInfo?.origin,
                    priceCurrency: priceInfo?.originalCurrency,
                  }}
                />
              )}
            </Stack>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

const styles = {
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as 'contain',
  },
  landImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as 'contain',
    borderRadius: '4px',
    border: '1px solid white',
  },
};

export default memo(FixedPriceSaleNFTItem);
