import React from 'react';
import { InventoryLayout } from 'src/layouts';
import { NFTDetail } from 'src/features/nftDetail';
import { TNFTType } from 'src/gql/types';

interface MyNFTDetailProps {
  nftType: TNFTType;
}
const MyNFTDetail = ({ nftType }: MyNFTDetailProps) => {
  return (
    <InventoryLayout>
      <NFTDetail nftType={nftType} />
    </InventoryLayout>
  );
};

export default MyNFTDetail;
