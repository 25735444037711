import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { QueryKeys } from 'src/globals/constants';
import * as wagmiAuthService from 'src/services/wagmiAuth';

const useChangeWagmiNetwork = () => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const doChangeWagmiNetwork = async () => {
    setIsLoading(true);
    try {
      await wagmiAuthService.changeWagmiToRightNetwork();
      queryClient.invalidateQueries([QueryKeys.wagmiAccountQueryKey]);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, doChangeWagmiNetwork };
};

export default useChangeWagmiNetwork;
