import { Box, Text, VStack, Image, HStack, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { coreUtils } from '../../../../common';

interface SkillPropsType {
  title: string;
  skillImage: string;
  rarity?: number;
  skillName?: string;
  skillDescription?: string;
}

const Skill = ({ title, skillImage, rarity, skillName, skillDescription }: SkillPropsType) => {
  const { t } = useTranslation();
  const rarityAttr = useMemo(() => {
    if (rarity) {
      return coreUtils.getAttributesByRarity(rarity);
    }
    return null;
  }, [rarity]);
  const rarityColor = useColorModeValue(rarityAttr?.light, rarityAttr?.dark);

  return (
    <Box p={3} shadow="md" borderWidth="1px" rounded={5} width="100%">
      <HStack>
        <Box shadow="md" borderWidth="3px" borderColor={rarityColor}>
          <Image src={skillImage} alt="skill" w="60px" />
        </Box>
        <VStack width="100%" alignItems="flex-start">
          <HStack justifyContent="space-between" width="100%">
            <HStack>
              {title && <Text width="fit-content">{title}</Text>}
              <Text color={rarityColor}>{skillName}</Text>
            </HStack>
            <Text fontWeight="bold" color={rarityColor}>
              {t(rarityAttr?.label || '')}
            </Text>
          </HStack>
          <Text>{skillDescription}</Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export default Skill;
