import React from 'react';
import { Box, Button, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import { appUtils } from 'src/common';

interface IWalletAddressInDesktopViewProps {
  onClickCopyAddress: () => void;
  walletAddress?: string;
  isLoading?: boolean;
  hasCopied: boolean;
}

const WalletAddressInDesktopView: React.FC<IWalletAddressInDesktopViewProps> = ({
  onClickCopyAddress,
  walletAddress,
  isLoading,
  hasCopied,
}) => {
  const { t } = useTranslation();
  const defaultBg = useColorModeValue(Colors.light.bg, Colors.dark.bg);

  if (isLoading || !walletAddress) {
    return <Button isLoading bg={defaultBg} />;
  }
  return (
    <Box padding={2} as="span" display="flex">
      <span>
        <Tooltip label={walletAddress}>
          <span>{walletAddress && appUtils.shortenHash(walletAddress)}</span>
        </Tooltip>
        <Tooltip label={hasCopied ? t('Copied') : t('Copy')} closeDelay={1000}>
          <span style={{ cursor: 'pointer' }} onClick={onClickCopyAddress}>
            <CopyIcon mx="2px" mb="4px" ml="4px" />
          </span>
        </Tooltip>
      </span>
    </Box>
  );
};

export default WalletAddressInDesktopView;
