import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';
import * as miningToolService from 'src/services/miningTool';

const useCurrentMinningToolIdsQuery = (address?: string) => {
  const { isFetching, data, error } = useQuery<number[], string>(
    [QueryKeys.currentMinningToolIds, address],
    () => {
      if (!address) throw new Error('Address is required');
      return miningToolService.getCurrentMiningTools(address);
    },
    {
      keepPreviousData: false,
      enabled: !!address,
    }
  );

  return { isLoading: isFetching, data, error };
};

export default useCurrentMinningToolIdsQuery;
