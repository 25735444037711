import React from 'react';
import {
  useColorModeValue,
  Image,
  useTab,
  chakra,
  createStylesContext,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { AppSizes, Colors } from 'src/shared';
import { SECONDARY_HEADER_HEIGHT } from 'src/globals/constants';
import { useTranslation } from 'react-i18next';
import { appUtils } from 'src/common';
import { INFTTab } from '../constants';

const [StylesProvider, useStyles] = createStylesContext('Menu');

export interface DashboardNavLinkProps {
  item: INFTTab;
}

// @ts-ignore
const StyledTab = chakra('button', { themeKey: 'Tabs.Tab' });

const RecentlyTab = React.forwardRef((props: DashboardNavLinkProps, ref) => {
  // @ts-ignore
  const tabProps = useTab({ ...props });
  const isSelected = !!tabProps['aria-selected'];
  const { item } = props;
  const { label } = item;
  const { t } = useTranslation();
  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  const tabStyle = {
    ...styles.tab,
    color: descriptionColor,
    _selected: {
      color: primaryColor,
      bg: 'transparent',
      borderBottomColor: primaryColor,
    },
  };
  const name = t(label);
  const icon = appUtils.getNFTIconByLabel(label);

  // 3. Hook into the Tabs `size`, `variant`, props
  const chakraStyles = useStyles();
  return (
    <StyledTab __css={{ ...chakraStyles.tab, ...tabStyle }} {...tabProps}>
      {!!icon && (
        <Image
          src={icon}
          width={AppSizes.NFTMenuIcon}
          alt="icon"
          filter={!!isSelected ? 'unset' : 'grayscale(1)'}
          mr={2}
        />
      )}
      {name}
    </StyledTab>
  );
});

const RecentlyTabContainer = React.forwardRef((props: DashboardNavLinkProps, ref) => {
  const styles = useMultiStyleConfig('Menu', {});

  return (
    <StylesProvider value={styles}>
      <RecentlyTab {...props} />
    </StylesProvider>
  );
});

export default RecentlyTabContainer;

const styles = {
  tab: {
    minWidth: '150px',
    height: SECONDARY_HEADER_HEIGHT,
    p: 2,
    minHeight: '50px',
    fontWeight: 700,
    fontSize: 'md',
    borderBottom: '4px solid',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    _focus: {
      boxShadow: 'none' as 'none',
    },
  },
};
