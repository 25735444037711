import Web3 from 'web3';
import { ParsedQuery } from 'query-string';
import has from 'lodash/has';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {
  DefaultLimit,
  GeneClassType,
  GenePartType,
  NFTType,
  QueryString,
  RarityType,
  ToolCategoryType,
  FixedPriceSaleStatus,
  DefaultMiningToolMetadata,
  DefaultGeneMetadata,
  DefaultLandMetadata,
  RoutePath,
  DefaultSlimeMetadata,
  SortType,
} from 'src/globals/constants';
import {
  IFilter,
  IFixedPriceSaleNFT,
  IMarketPrice,
  INFT,
  IOrderByKeyInput,
  IPaginationInput,
  ISlimeMetadata,
  IUserMeta,
  SkillType,
  SlimeClass,
  SlimePart,
  TNFTMetadata,
  TNFTType,
} from 'src/gql/types';
import {
  GACHA_BOX_RESOURCE_URL,
  GENE_RESOURCE_URL,
  LAND_RESOURCE_URL,
  MINING_TOOL_RESOURCE_URL,
  SLIME_RESOURCE_URL,
  SLIME_SKILL_RESOURCE_URL,
  TOOL_MATERIAL_RESOURCE_URL,
} from 'src/globals/configs';
import { Colors } from 'src/shared';
import { ContractType, TokenFormat } from 'src/metamask-provider/type';
import BN from 'bn.js';
import { contractNames, getContractAddress } from 'src/shared/contracts';
import { IRequestFilterBase } from '../globals/types';
import getSlimeSkins from './get-slime-skins';
import * as appUtils from './utils';
import { SlimeMythicalSkillIds, SlimeNormalSkillIds } from './slimeSkillIds';

export const standardizeURLPagination = (skip = 0, limit = DefaultLimit): { page: number } => {
  const pageNumber = skip / limit + 1;
  return { page: pageNumber };
};

export const standardizeAPIPagination = (
  pageNumber = 0,
  limit = DefaultLimit
): IPaginationInput => {
  const skip = (pageNumber - 1) * limit;
  return { skip, limit };
};

export const standardizeAPIOrderByInput = ({
  key,
  order,
}: {
  key: string;
  order: 'asc' | 'desc';
}): IOrderByKeyInput => {
  return { [key]: order };
};

// Function use to standardize value when getting info from BE to show on web
export const standardizeDataToShowed = (value?: number) => {
  if (value) return value / 100; // because data on contract not use float number, so must be / 100 before to show
  return 0;
};

export const padTemplateId = (templateId: BN | number) => {
  return templateId.toString(16).padStart(8, '0');
};

// Function use to standardize value when querying NFTData
export const standardizeValueToNFTData = (value?: number) => {
  if (value) return value * 100;
  return 0;
};

export const standardizeMultipleSelectionData = (
  data: string | string[],
  convertData = appUtils.convertStringToNumber
) => {
  if (isEmpty(data)) {
    return undefined;
  }
  if (!Array.isArray(data)) {
    data = [data];
  }
  return orderBy(map(data, (e) => convertData(e)));
};

export const standardizeRangeSelectionData = (
  data: string | string[],
  convertData = appUtils.convertStringToNumber
) => {
  if (isEmpty(data) || !Array.isArray(data)) {
    return undefined;
  }
  return orderBy(map(data, (e) => convertData(e)));
};

// Function use to get filter Data because standardizeVariablesForQuery may change NFTData to query
export const standardizeFilterToQuery = (
  queryParams: ParsedQuery<string>,
  filterBase?: IRequestFilterBase
): IFilter => {
  const filter: IFilter = {};
  if (has(filterBase, QueryString.SEARCH_QUERY) && has(queryParams, QueryString.SEARCH_QUERY)) {
    const searchString = get(queryParams, QueryString.SEARCH_QUERY, '');
    if (!isEmpty(searchString)) {
      filter.search = searchString as string;
    }
  }
  if (has(filterBase, QueryString.CATEGORY) && has(queryParams, QueryString.CATEGORY)) {
    filter.cat = standardizeMultipleSelectionData(
      (queryParams?.[QueryString.CATEGORY] as string) ?? []
    );
  }
  if (has(filterBase, QueryString.RARITY) && has(queryParams, QueryString.RARITY)) {
    filter.rarity = standardizeMultipleSelectionData(
      queryParams?.[QueryString.RARITY] ? (queryParams?.[QueryString.RARITY] as string) : []
    );
  }
  if (has(filterBase, QueryString.MINING_POWER) && has(queryParams, QueryString.MINING_POWER)) {
    filter.mp = standardizeRangeSelectionData(
      queryParams?.[QueryString.MINING_POWER]
        ? (queryParams?.[QueryString.MINING_POWER] as string)
        : []
    );
  }
  if (has(filterBase, QueryString.LUCK) && has(queryParams, QueryString.LUCK)) {
    filter.luck = standardizeRangeSelectionData(
      queryParams?.[QueryString.LUCK] ? (queryParams?.[QueryString.LUCK] as string) : []
    );
  }
  if (has(filterBase, QueryString.COOLDOWN) && has(queryParams, QueryString.COOLDOWN)) {
    filter.cd = standardizeRangeSelectionData(
      queryParams?.[QueryString.COOLDOWN] ? (queryParams?.[QueryString.COOLDOWN] as string) : []
    );
  }
  if (has(filterBase, QueryString.PART) && has(queryParams, QueryString.PART)) {
    filter.part = standardizeMultipleSelectionData(
      queryParams?.[QueryString.PART] ? (queryParams?.[QueryString.PART] as string) : []
    );
  }
  if (has(filterBase, QueryString.GENE_CLASS) && has(queryParams, QueryString.GENE_CLASS)) {
    filter.geneClass = standardizeMultipleSelectionData(
      queryParams?.[QueryString.GENE_CLASS] ? (queryParams?.[QueryString.GENE_CLASS] as string) : []
    );
  }
  if (has(filterBase, QueryString.CLASS) && has(queryParams, QueryString.CLASS)) {
    filter.class = standardizeMultipleSelectionData(
      queryParams?.[QueryString.CLASS] ? (queryParams?.[QueryString.CLASS] as string) : []
    );
  }
  return filter;
};

export const standardizeVariablesForQuery = (queryParams: ParsedQuery) => {
  const sortType = get(queryParams, QueryString.SORT, SortType.NEWEST);
  const pageNumber = Number(get(queryParams, QueryString.PAGE, 1));
  const sortByType = appUtils.getSortByType(sortType as string);
  const orderBy = sortByType && standardizeAPIOrderByInput(sortByType);
  let filter: IFilter = {};
  if (has(queryParams, QueryString.SEARCH_QUERY)) {
    const searchString = get(queryParams, QueryString.SEARCH_QUERY, '');
    if (!isEmpty(searchString)) {
      filter.search = searchString as string;
    }
  }
  if (has(queryParams, QueryString.CATEGORY)) {
    filter.cat = standardizeMultipleSelectionData(
      queryParams?.[QueryString.CATEGORY] ? (queryParams?.[QueryString.CATEGORY] as string) : []
    );
  }
  if (has(queryParams, QueryString.RARITY)) {
    filter.rarity = standardizeMultipleSelectionData(
      queryParams?.[QueryString.RARITY] ? (queryParams?.[QueryString.RARITY] as string) : []
    );
  }
  if (has(queryParams, QueryString.MINING_POWER)) {
    filter.mp = standardizeRangeSelectionData(
      queryParams?.[QueryString.MINING_POWER]
        ? (queryParams?.[QueryString.MINING_POWER] as string)
        : [],
      (i) => {
        const value = appUtils.convertStringToNumber(i);
        return standardizeValueToNFTData(value);
      }
    );
  }
  if (has(queryParams, QueryString.LUCK)) {
    filter.luck = standardizeRangeSelectionData(
      queryParams?.[QueryString.LUCK] ? (queryParams?.[QueryString.LUCK] as string) : [],
      (i) => {
        const value = appUtils.convertStringToNumber(i);
        return standardizeValueToNFTData(value);
      }
    );
  }
  if (has(queryParams, QueryString.COOLDOWN)) {
    filter.cd = standardizeRangeSelectionData(
      queryParams?.[QueryString.COOLDOWN] ? (queryParams?.[QueryString.COOLDOWN] as string) : [],
      (i) => {
        const value = appUtils.convertStringToNumber(i);
        return standardizeValueToNFTData(value);
      }
    );
  }
  if (has(queryParams, QueryString.PART)) {
    filter.part = standardizeMultipleSelectionData(
      queryParams?.[QueryString.PART] ? (queryParams?.[QueryString.PART] as string) : []
    );
  }
  if (has(queryParams, QueryString.GENE_CLASS)) {
    filter.geneClass = standardizeMultipleSelectionData(
      queryParams?.[QueryString.GENE_CLASS] ? (queryParams?.[QueryString.GENE_CLASS] as string) : []
    );
  }
  if (has(queryParams, QueryString.CLASS)) {
    filter.class = standardizeMultipleSelectionData(
      queryParams?.[QueryString.CLASS] ? (queryParams?.[QueryString.CLASS] as string) : []
    );
  }
  return {
    pagination: standardizeAPIPagination(pageNumber),
    orderBy,
    filter,
  };
};

export const getOriginalTemplateId = (templateId: BN): BN => {
  return new BN(templateId).and(new BN('FFFFFF', 16));
};

export const getImageByTemplateId = (templateId: number, nftType: TNFTType) => {
  const mappingTemplateId = templateId.toString(16).toLowerCase();

  switch (nftType) {
    case NFTType.MINING_TOOL:
      return `${MINING_TOOL_RESOURCE_URL}/tool_${mappingTemplateId}.webp`;
    case NFTType.GENE:
      return `${GENE_RESOURCE_URL}/gene_${padTemplateId(templateId)}.webp`;
    default:
      throw new Error(`Please implement getImageByTemplateId with type ${nftType}`);
  }
};

export const getImageByTokenId = (tokenId: number, nftType: TNFTType, updatedDate?: number) => {
  switch (nftType) {
    case NFTType.LAND:
      return `${LAND_RESOURCE_URL}/land_${tokenId}.webp`;
    case NFTType.SLIME: {
      const shouldForceLoadNew = updatedDate && new Date().getTime() / 1000 - updatedDate < 60 * 60;
      const dummy = shouldForceLoadNew ? `?dummy=${Math.random()}` : '';
      return `${SLIME_RESOURCE_URL}/thumbnail/${tokenId.toString(10)}.webp${dummy}`;
    }
    case NFTType.TOOL_MATERIAL:
      return `${TOOL_MATERIAL_RESOURCE_URL}/${tokenId}.webp`;
    case NFTType.GACHA: {
      return `${GACHA_BOX_RESOURCE_URL}/gachabox.webp`;
    }
    default:
      throw new Error(`Please implement getImageByTokenId with type ${nftType}`);
  }
};

export const getExtractedGeneImage = (templateId: number, variant: number) => {
  return `${GENE_RESOURCE_URL}/exgene_${templateId.toString(16)}_${variant}.webp`;
};

export const getNftImage = (
  nftType: TNFTType,
  tokenId: number,
  templateId: number,
  extractedGene?: boolean,
  variant?: number,
  updatedDate?: number
) => {
  if (extractedGene) {
    if (variant) {
      return getExtractedGeneImage(templateId, variant);
    }
    return '#';
  }
  if (
    nftType === NFTType.LAND ||
    nftType === NFTType.SLIME ||
    nftType === NFTType.TOOL_MATERIAL ||
    nftType === NFTType.GACHA
  ) {
    return getImageByTokenId(tokenId, nftType, updatedDate);
  }
  return getImageByTemplateId(templateId, nftType);
};

export const getAttributesByCatId = (
  rarity: number
): { label: string; light: string; dark: string } | null => {
  switch (rarity) {
    case ToolCategoryType.HYBRID: {
      return {
        label: 'Term:Hybrid',
        light: Colors.light.catHybrid,
        dark: Colors.dark.catHybrid,
      };
    }
    case ToolCategoryType.LUCK: {
      return {
        label: 'Term:Luck',
        light: Colors.light.catLuck,
        dark: Colors.dark.catLuck,
      };
    }
    case ToolCategoryType.MINING_POWER:
      return {
        label: 'Term:MiningPower',
        light: Colors.light.catMiningPower,
        dark: Colors.dark.catMiningPower,
      };
    default:
  }
  return null;
};

export const getAttributesByRarity = (
  rarity: number
): { label: string; light: string; dark: string } | null => {
  switch (rarity) {
    case RarityType.LEGENDARY: {
      return {
        label: 'Term:Rarity.Legendary',
        light: Colors.light.rarityLegendary,
        dark: Colors.dark.rarityLegendary,
      };
    }
    case RarityType.EPIC: {
      return {
        label: 'Term:Rarity.Epic',
        light: Colors.light.rarityEpic,
        dark: Colors.dark.rarityEpic,
      };
    }
    case RarityType.RARE: {
      return {
        label: 'Term:Rarity.Rare',
        light: Colors.light.rarityRare,
        dark: Colors.dark.rarityRare,
      };
    }
    case RarityType.UNCOMMON: {
      return {
        label: 'Term:Rarity.Uncommon',
        light: Colors.light.rarityUncommon,
        dark: Colors.dark.rarityUncommon,
      };
    }
    case RarityType.COMMON:
      return {
        label: 'Term:Rarity.Common',
        light: Colors.light.rarityCommon,
        dark: Colors.dark.rarityCommon,
      };
    default:
  }
  return null;
};

export const getAttributesByGeneClass = (
  geneClass: number
): { label: string; light: string; dark: string } | null => {
  switch (geneClass) {
    case GeneClassType.GENERIC: {
      return {
        label: 'Term:GeneClass.Generic',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GeneClassType.CRUSADER: {
      return {
        label: 'Term:GeneClass.Crusader',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GeneClassType.WIZARD: {
      return {
        label: 'Term:GeneClass.Wizard',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GeneClassType.ASSASSIN: {
      return {
        label: 'Term:GeneClass.Assassin',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GeneClassType.SAINT:
      return {
        label: 'Term:GeneClass.Saint',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    case GeneClassType.SHAMAN:
      return {
        label: 'Term:GeneClass.Shaman',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    default:
  }
  return null;
};

export const getAttributesByPart = (
  part: number
): { label: string; light: string; dark: string } | null => {
  switch (part) {
    case GenePartType.BODY: {
      return {
        label: 'Term:Part.Body',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GenePartType.HEAD: {
      return {
        label: 'Term:Part.Head',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GenePartType.LEFT_ARM: {
      return {
        label: 'Term:Part.LeftArm',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GenePartType.RIGHT_ARM: {
      return {
        label: 'Term:Part.RightArm',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    }
    case GenePartType.SUB:
      return {
        label: 'Term:Part.Sub',
        light: Colors.light.label,
        dark: Colors.dark.label,
      };
    default:
  }
  return null;
};

export const getMarketContractByNftType = (nftType: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return contractNames.toolmarket;
    case NFTType.GENE:
      return contractNames.genemarket;
    case NFTType.SLIME:
      return contractNames.slimemarket;
    case NFTType.LAND:
      return contractNames.landmarket;
    case NFTType.GACHA:
      return contractNames.gachaboxmarket;
    case NFTType.TOOL_MATERIAL:
      return contractNames.toolmaterialmarket;
    default:
      throw new Error(`getMarketContractByNftType Unsupported NFT type ${nftType}`);
  }
};

export const getNftContractByNftType = (nftType: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return contractNames.toolnft;
    case NFTType.GENE:
      return contractNames.genenft;
    case NFTType.SLIME:
      return contractNames.slimenft;
    case NFTType.LAND:
      return contractNames.landnft;
    case NFTType.GACHA:
      return contractNames.gachabox;
    case NFTType.TOOL_MATERIAL:
      return contractNames.toolmaterial;
    default:
      throw new Error(`getNftContractByNftType Unsupported NFT type ${nftType}`);
  }
};

export const getPairOfContractsByNftType = (
  nftType: TNFTType
): { tokenApprovalKey: string; nftApprovalKey: string } => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return {
        tokenApprovalKey: 'FTCToMiningToolMarket',
        nftApprovalKey: 'MiningToolToMiningToolMarket',
      };
    case NFTType.GENE:
      return {
        tokenApprovalKey: 'FTCToGeneMarket',
        nftApprovalKey: 'GeneToGeneMarket',
      };
    case NFTType.SLIME:
      return {
        tokenApprovalKey: 'FTCToSlimeMarket',
        nftApprovalKey: 'SlimeToSlimeMarket',
      };
    case NFTType.LAND:
      return {
        tokenApprovalKey: 'FTCToLandMarket',
        nftApprovalKey: 'LandToLandMarket',
      };
    case NFTType.GACHA:
      return {
        tokenApprovalKey: 'FTCToGachaBoxMarket',
        nftApprovalKey: 'GachaBoxToGachaBoxMarket',
      };
    case NFTType.TOOL_MATERIAL:
      return {
        tokenApprovalKey: 'FTCToToolMaterialMarket',
        nftApprovalKey: 'ToolMaterialToToolMaterialMarket',
      };
    default:
      throw new Error(`getPairOfContractsByNftType Unsupported NFT type ${nftType}`);
  }
};

export const getTokenFormatByNftType = (nftType: TNFTType): TokenFormat => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
    case NFTType.GENE:
    case NFTType.SLIME:
    case NFTType.LAND:
      return TokenFormat.ERC721;
    case NFTType.GACHA:
    case NFTType.TOOL_MATERIAL:
      return TokenFormat.ERC1155;
    default:
      throw new Error(`getTokenFormatByNftType Unsupported NFT type ${nftType}`);
  }
};

export const getPriceInfoByStatus = (fixedPriceSaleNFT: IFixedPriceSaleNFT): IMarketPrice => {
  const hasSold = fixedPriceSaleNFT?.status === FixedPriceSaleStatus.BOUGHT;
  return hasSold ? fixedPriceSaleNFT?.buyPrice : fixedPriceSaleNFT?.sellPrice;
};

export const getOwnerInfoByStatus = (fixedPriceSaleNFT: IFixedPriceSaleNFT): IUserMeta => {
  const hasSold = fixedPriceSaleNFT?.status === FixedPriceSaleStatus.BOUGHT;
  return hasSold ? fixedPriceSaleNFT?.buyer : fixedPriceSaleNFT?.seller;
};

export const getDefaultMetadataByNftType = (nftType: TNFTType): TNFTMetadata => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return DefaultMiningToolMetadata;
    case NFTType.GENE:
      return DefaultGeneMetadata;
    case NFTType.SLIME:
      return DefaultSlimeMetadata;
    case NFTType.LAND:
      return DefaultLandMetadata;
    case NFTType.GACHA:
    case NFTType.TOOL_MATERIAL:
      return null;
    default:
      throw new Error(`Please implement getDefaultMetadataByNftType with type ${nftType}`);
  }
};

export const getNFTTokenNameByNftType = (nftType?: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return 'Term:Marketplace.MiningToolNFTToken';
    case NFTType.GENE:
      return 'Term:Marketplace.GeneNFTToken';
    case NFTType.SLIME:
      return 'Term:Marketplace.SlimeNFTToken';
    case NFTType.LAND:
      return 'Term:Marketplace.LandNFTToken';
    case NFTType.GACHA:
      return 'Term:Marketplace.GachaNFTToken';
    case NFTType.TOOL_MATERIAL:
      return 'Term:Marketplace.ToolMaterialNFTToken';
    default:
      return 'Term:Unknown';
  }
};

export const getNFTTypeNameByNftType = (nftType?: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return 'Term:MiningTool';
    case NFTType.GENE:
      return 'Term:Gene';
    case NFTType.SLIME:
      return 'Term:Slime';
    case NFTType.LAND:
      return 'Term:Land';
    case NFTType.GACHA:
      return 'Term:GachaBox';
    case NFTType.TOOL_MATERIAL:
      return 'Term:ToolMaterial';
    default:
      return 'Term:Unknown';
  }
};

export const getNFTTypeByContractAddress = (contractAddress: string): TNFTType => {
  switch (contractAddress) {
    case getContractAddress(contractNames.toolnft):
    case getContractAddress(contractNames.toolmarket):
      return NFTType.MINING_TOOL;
    case getContractAddress(contractNames.genenft):
    case getContractAddress(contractNames.genemarket):
      return NFTType.GENE;
    case getContractAddress(contractNames.slimenft):
    case getContractAddress(contractNames.slimemarket):
      return NFTType.SLIME;
    case getContractAddress(contractNames.landnft):
    case getContractAddress(contractNames.landmarket):
      return NFTType.LAND;
    case getContractAddress(contractNames.gachabox):
    case getContractAddress(contractNames.gachaboxmarket):
      return NFTType.GACHA;
    case getContractAddress(contractNames.toolmaterial):
    case getContractAddress(contractNames.toolmaterialmarket):
      return NFTType.TOOL_MATERIAL;
    default:
      throw new Error(
        `Please implement getNFTTypeByContractAddress with address ${contractAddress}`
      );
  }
};

export const getPathByNFTType = (nftType: TNFTType): string => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return RoutePath.MINING_TOOL;
    case NFTType.GENE:
      return RoutePath.GENE;
    case NFTType.SLIME:
      return RoutePath.SLIME;
    case NFTType.LAND:
      return RoutePath.LAND;
    case NFTType.GACHA:
      return RoutePath.GACHA_BOX;
    case NFTType.TOOL_MATERIAL:
      return RoutePath.TOOL_MATERIAL;
    default:
      return RoutePath.ROOT;
  }
};

export const getSlimeSkinsFromSlimeData = (slimeData: INFT): string[] => {
  return getSlimeSkins(slimeData.metadata as ISlimeMetadata);
};

export const getSpineSkin = (skins: string[], slimeSpinePreview: any): any => {
  if (slimeSpinePreview.skeleton) {
    // @ts-ignore
    const newSkin = new spine.Skin('new-skin');
    for (let i = 0; i < skins.length; ++i) {
      const skin = slimeSpinePreview.skeleton.data.findSkin(skins[i]);
      const skinNoOutline = slimeSpinePreview.skeleton.data.findSkin(skins[i] + '_no_outline');

      if (skin) {
        newSkin.addSkin(skin);
      }

      if (skinNoOutline) {
        newSkin.addSkin(skinNoOutline);
      }
    }
    return newSkin;
  }
  return null;
};

export const setSlimeSpineSkin = (slimeSpinePreview: any, spineSkin: any) => {
  slimeSpinePreview.skeleton.setSkin(spineSkin);
  slimeSpinePreview.skeleton.setSlotsToSetupPose();
  slimeSpinePreview.skeleton.updateWorldTransform();
  slimeSpinePreview.setAnimation('idle', true);
};

export const mapPartToString = (part: SlimePart): string => {
  switch (part) {
    case SlimePart.Body:
      return 'body';
    case SlimePart.Sub:
      return 'sub';
    case SlimePart.Head:
      return 'head';
    case SlimePart.LeftArm:
      return 'leftArm';
    case SlimePart.RightArm:
      return 'rightArm';
    case SlimePart.Face:
      return 'face';
  }
};

export const getSkillImageUrl = (key: string): string => {
  return `${SLIME_SKILL_RESOURCE_URL}/${key}.webp`;
};

export const getSkillRarity = (key: string): RarityType => {
  // sm_c2_r1_01
  return parseInt(key.split('_')[2].split('r')[1]) as RarityType;
};

export const getSkillInfoKey = (
  skillType: SkillType,
  klass: SlimeClass,
  skillIndex: number
): string => {
  if (skillType === SkillType.MYTHICAL) {
    // @ts-ignore
    return SlimeMythicalSkillIds[klass][skillIndex];
  }
  // @ts-ignore
  return SlimeNormalSkillIds[klass][skillIndex];
};

export const truncateBN = (value: BN, decimals: number): BN => {
  const d = new BN(10).pow(new BN(18 - decimals));
  return value.divRound(d).mul(d);
};

export const weiFromEther = (value: number): string => {
  return Web3.utils.toWei(value.toString(), 'ether');
};

export const toBN = (value: string | number): BN => {
  return Web3.utils.toBN(value);
};

export const etherFromWei = (value: string | BN, decimals: number = 18): string => {
  let bn = typeof value === 'string' ? toBN(value as string) : value;

  if (decimals < 18 && decimals >= 0) {
    bn = truncateBN(bn, decimals);
  }

  return Web3.utils.fromWei(bn, 'ether');
};
