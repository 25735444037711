import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import { IMarketplaceSummary, INFTsResult, TTimeFrame } from '../types';
import apolloClient from '../apolloClient';
import * as SummaryCommon from './common';
import { getMarketStatistics } from './queries';

const getMarketStatisticsByTimeFrame = async (timeFrame: TTimeFrame) => {
  const variables = {
    timeFrame,
  };
  try {
    const response = await apolloClient.query<INFTsResult>({
      query: getMarketStatistics(),
      variables,
    });
    const data = get(response, `data.getMarketStatistics`);
    return Promise.resolve(data);
  } catch (error) {
    console.error('getMarketplaceSummaryByTimeFrame, error', error);
    return Promise.resolve(undefined);
  }
};

export const getMarketplaceSummaries = async (): Promise<IMarketplaceSummary> => {
  try {
    const timeFrames = ['last24h', 'last7days', 'last30days'];
    const marketplaceSummariesByTime = await Promise.all(
      timeFrames.map(async (timeFrame) => {
        const marketStatistics = await getMarketStatisticsByTimeFrame(timeFrame as TTimeFrame);
        if (!isNil(marketStatistics)) {
          const summaryKey = SummaryCommon.getSummaryKeyByTimeFrame(timeFrame as TTimeFrame);
          const summaryData = SummaryCommon.standardizeMarketplaceSummary(marketStatistics);
          return { [summaryKey]: summaryData };
        }
        return undefined;
      })
    );
    const data = reduce(
      marketplaceSummariesByTime,
      (result, value) => {
        if (value) return { ...result, ...value };
        return result;
      },
      {}
    );
    return Promise.resolve(data as IMarketplaceSummary);
  } catch (error) {
    console.error('getMarketplaceSummaries, error', error);
    return Promise.reject(error);
  }
};
