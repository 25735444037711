import { useQuery } from '@tanstack/react-query';
import * as accountServiceService from '../../../services/accountServices';

const useAccountInformation = () => {
  const { isLoading: isLoadingAccountInfo, data: accountInfo } = useQuery({
    queryKey: ['accountInformation'],
    queryFn: () => accountServiceService.getMe(),
  });

  return { isAuthenticated: !!accountInfo, isLoadingAccountInfo, accountInfo };
};

export default useAccountInformation;
