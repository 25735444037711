import React, { memo } from 'react';
import { BoxProps, Stack, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ISummary } from 'src/gql/types';
import { Colors, Resources } from 'src/shared';
import { appUtils } from 'src/common';
import OverviewCard from './OverviewCard';

export interface OverviewCardProps extends BoxProps {
  data?: ISummary | null;
}

const DashboardOverviewItem = (props: OverviewCardProps) => {
  const { data, ...remainingProps } = props;
  const { t } = useTranslation();
  const statsTotalNFTsColor = useColorModeValue(Colors.dark.statTotal, Colors.dark.statTotal);
  const statsVolumeColor = useColorModeValue(Colors.dark.statVolume, Colors.dark.statVolume);
  const statsAveragePriceColor = useColorModeValue(
    Colors.dark.statAverage,
    Colors.dark.statAverage
  );
  const statsTotalTransactionsColor = useColorModeValue(
    Colors.dark.statTransaction,
    Colors.dark.statTransaction
  );
  const totalVolumeFTC = appUtils.displayPriceWithCurrency(data?.volume);
  const totalVolumeUSD = appUtils.displayPriceWithCurrency(data?.volumeUSD, 'USD');
  const avgPriceFTC = appUtils.displayPriceWithCurrency(data?.avgPrice);
  const avgPriceUSD = appUtils.displayPriceWithCurrency(data?.avgPriceUSD, 'USD');
  if (!data) {
    return null;
  }
  return (
    <Stack spacing={4} direction={{ base: 'column', lg: 'row' }} alignItems="flex-start">
      <OverviewCard
        highlightedText={data.totalSale.toLocaleString('en')}
        title={t('Component:Overview.TotalSale')}
        icon={Resources.svgs.statsTotalNFTs}
        iconStyle={{ bg: statsTotalNFTsColor }}
        bg={'transparent'}
        {...remainingProps}
      />
      <OverviewCard
        highlightedText={totalVolumeFTC}
        secondaryText={totalVolumeUSD}
        title={t('Component:Overview.TotalVolume')}
        icon={Resources.svgs.statsVolume}
        iconStyle={{ bg: statsVolumeColor }}
        bg={'transparent'}
        {...remainingProps}
      />
      <OverviewCard
        highlightedText={avgPriceFTC}
        secondaryText={avgPriceUSD}
        title={t('Component:Overview.AveragePrice')}
        icon={Resources.svgs.statsAveragePrice}
        iconStyle={{ bg: statsAveragePriceColor }}
        bg={'transparent'}
        {...remainingProps}
      />
      <OverviewCard
        highlightedText={data?.totalTransaction.toLocaleString('en')}
        title={t('Component:Overview.TotalTransaction')}
        icon={Resources.svgs.statsTransaction}
        iconStyle={{ bg: statsTotalTransactionsColor }}
        bg={'transparent'}
        {...remainingProps}
      />
    </Stack>
  );
};

export default memo(DashboardOverviewItem);
