module.exports = [
  [
    {
      body: [
        'body/Com/Com_cru_Baseball_body',
        'body/Com/Com_cru_Chicken_body',
        'body/Com/Com_cru_Cow_body',
        'body/Com/Com_cru_Drum_body',
        'body/Com/Com_cru_Fox_body',
        'body/Com/Com_cru_Rocker_body',
        'body/Com/Com_cru_Rugby_body',
        'body/Com/Com_cru_Singer_body',
        'body/Com/Com_cru_Tennis_body',
        'body/Com/Com_cru_Tennis_body',
      ],
      sub: [
        'sub_body/Com/Com_cru_Baseball_sub_body',
        'sub_body/Com/Com_cru_Chicken_sub_body',
        'sub_body/Com/Com_cru_Cow_sub_body',
        'sub_body/Com/Com_cru_Drum_sub_body',
        'sub_body/Com/Com_cru_Fox_sub_body',
        'sub_body/Com/Com_cru_Rocker_sub_body',
        'sub_body/Com/Com_cru_Rugby_sub_body',
        'sub_body/Com/Com_cru_Singer_sub_body',
        'sub_body/Com/Com_cru_Tennis_sub_body',
        'sub_body/Com/Com_cru_Tennis_sub_body',
      ],
      head: [
        'hat/Com/Com_cru_Baseball_hat',
        'hat/Com/Com_cru_Chicken_hat',
        'hat/Com/Com_cru_Cow_hat',
        'hat/Com/Com_cru_Drum_hat',
        'hat/Com/Com_cru_Fox_hat',
        'hat/Com/Com_cru_Rocker_hat',
        'hat/Com/Com_cru_Rugby_hat',
        'hat/Com/Com_cru_Singer_hat',
        'hat/Com/Com_cru_Tennis_hat',
        'hat/Com/Com_cru_Tennis_hat',
      ],
      left_arm: [
        'left_arm/Com/Com_cru_Baseball_l_arm',
        'left_arm/Com/Com_cru_Chicken_l_arm',
        'left_arm/Com/Com_cru_Cow_l_arm',
        'left_arm/Com/Com_cru_Drum_l_arm',
        'left_arm/Com/Com_cru_Fox_l_arm',
        'left_arm/Com/Com_cru_Rocker_l_arm',
        'left_arm/Com/Com_cru_Rugby_l_arm',
        'left_arm/Com/Com_cru_Singer_l_arm',
        'left_arm/Com/Com_cru_Tennis_l_arm',
        'left_arm/Com/Com_cru_Tennis_l_arm',
      ],
      right_arm: [
        'right_arm/Com/Com_cru_Baseball_r_arm',
        'right_arm/Com/Com_cru_Chicken_r_arm',
        'right_arm/Com/Com_cru_Cow_r_arm',
        'right_arm/Com/Com_cru_Drum_r_arm',
        'right_arm/Com/Com_cru_Fox_r_arm',
        'right_arm/Com/Com_cru_Rocker_r_arm',
        'right_arm/Com/Com_cru_Rugby_r_arm',
        'right_arm/Com/Com_cru_Singer_r_arm',
        'right_arm/Com/Com_cru_Tennis_r_arm',
        'right_arm/Com/Com_cru_Tennis_r_arm',
      ],
      face: [
        'face/Com/Com_cru_Baseball_face',
        'face/Com/Com_cru_Chicken_face',
        'face/Com/Com_cru_Cow_face',
        'face/Com/Com_cru_Drum_face',
        'face/Com/Com_cru_Fox_face',
        'face/Com/Com_cru_Rocker_face',
        'face/Com/Com_cru_Rugby_face',
        'face/Com/Com_cru_Singer_face',
        'face/Com/Com_cru_Tennis_face',
        'face/Com/Com_cru_Tennis_face',
      ],
    },
    {
      body: [
        'body/Un/Un_cru_CruClown_body',
        'body/Un/Un_cru_Kitchen_body',
        'body/Un/Un_cru_Skeleton_body',
        'body/Un/Un_cru_Spartan_body',
        'body/Un/Un_cru_Viking_body',
      ],
      sub: [
        'sub_body/Un/Un_cru_CruClown_sub_body',
        'sub_body/Un/Un_cru_Kitchen_sub_body',
        'sub_body/Un/Un_cru_Skeleton_sub_body',
        'sub_body/Un/Un_cru_Spartan_sub_body',
        'sub_body/Un/Un_cru_Viking_sub_body',
      ],
      head: [
        'hat/Un/Un_cru_CruClown_hat',
        'hat/Un/Un_cru_Kitchen_hat',
        'hat/Un/Un_cru_Skeleton_hat',
        'hat/Un/Un_cru_Spartan_hat',
        'hat/Un/Un_cru_Viking_hat',
      ],
      left_arm: [
        'left_arm/Un/Un_cru_CruClown_l_arm',
        'left_arm/Un/Un_cru_Kitchen_l_arm',
        'left_arm/Un/Un_cru_Skeleton_l_arm',
        'left_arm/Un/Un_cru_Spartan_l_arm',
        'left_arm/Un/Un_cru_Viking_l_arm',
      ],
      right_arm: [
        'right_arm/Un/Un_cru_CruClown_r_arm',
        'right_arm/Un/Un_cru_Kitchen_r_arm',
        'right_arm/Un/Un_cru_Skeleton_r_arm',
        'right_arm/Un/Un_cru_Spartan_r_arm',
        'right_arm/Un/Un_cru_Viking_r_arm',
      ],
      face: [
        'face/Un/Un_cru_CruClown_face',
        'face/Un/Un_cru_Kitchen_face',
        'face/Un/Un_cru_Skeleton_face',
        'face/Un/Un_cru_Spartan_face',
        'face/Un/Un_cru_Viking_face',
      ],
    },
    {
      body: [
        'body/R/R_cru_Aquarior_body',
        'body/R/R_cru_IronFist_body',
        'body/R/R_cru_KingPirate_body',
        'body/R/R_cru_PixelKnight_body',
      ],
      sub: [
        'sub_body/R/R_cru_Aquarior_sub_body',
        'sub_body/R/R_cru_IronFist_sub_body',
        'sub_body/R/R_cru_KingPirate_sub_body',
        'sub_body/R/R_cru_PixelKnight_sub_body',
      ],
      head: [
        'hat/R/R_cru_Aquarior_hat',
        'hat/R/R_cru_IronFist_hat',
        'hat/R/R_cru_KingPirate_hat',
        'hat/R/R_cru_PixelKnight_hat',
      ],
      left_arm: [
        'left_arm/R/R_cru_Aquarior_l_arm',
        'left_arm/R/R_cru_IronFist_l_arm',
        'left_arm/R/R_cru_KingPirate_l_arm',
        'left_arm/R/R_cru_PixelKnight_l_arm',
      ],
      right_arm: [
        'right_arm/R/R_cru_Aquarior_r_arm',
        'right_arm/R/R_cru_IronFist_r_arm',
        'right_arm/R/R_cru_KingPirate_r_arm',
        'right_arm/R/R_cru_PixelKnight_r_arm',
      ],
      face: [
        'face/R/R_cru_Aquarior_face',
        'face/R/R_cru_IronFist_face',
        'face/R/R_cru_KingPirate_face',
        'face/R/R_cru_PixelKnight_face',
      ],
    },
    {
      body: [
        'body/E/E_cru_Hellblade_body',
        'body/E/E_cru_LaserGun_body',
        'body/E/E_cru_Wukong_body',
      ],
      sub: [
        'sub_body/E/E_cru_Hellblade_sub_body',
        'sub_body/E/E_cru_LaserGun_sub_body',
        'sub_body/E/E_cru_Wukong_sub_body',
      ],
      head: ['hat/E/E_cru_Hellblade_hat', 'hat/E/E_cru_LaserGun_hat', 'hat/E/E_cru_Wukong_hat'],
      left_arm: [
        'left_arm/E/E_cru_Hellblade_l_arm',
        'left_arm/E/E_cru_LaserGun_l_arm',
        'left_arm/E/E_cru_Wukong_l_arm',
      ],
      right_arm: [
        'right_arm/E/E_cru_Hellblade_r_arm',
        'right_arm/E/E_cru_LaserGun_r_arm',
        'right_arm/E/E_cru_Wukong_r_arm',
      ],
      face: [
        'face/E/E_cru_Hellblade_face',
        'face/E/E_cru_LaserGun_face',
        'face/E/E_cru_Wukong_face',
      ],
    },
    {
      body: ['body/L/L_cru_HolyCrusader_body', 'body/L/L_cru_ThunderGod_body'],
      sub: ['sub_body/L/L_cru_HolyCrusader_sub_body', 'sub_body/L/L_cru_ThunderGod_sub_body'],
      head: ['hat/L/L_cru_HolyCrusader_hat', 'hat/L/L_cru_ThunderGod_hat'],
      left_arm: ['left_arm/L/L_cru_HolyCrusader_l_arm', 'left_arm/L/L_cru_ThunderGod_l_arm'],
      right_arm: ['right_arm/L/L_cru_HolyCrusader_r_arm', 'right_arm/L/L_cru_ThunderGod_r_arm'],
      face: ['face/L/L_cru_HolyCrusader_face', 'face/L/L_cru_ThunderGod_face'],
    },
  ],
  [
    {
      body: [
        'body/Com/Com_wiz_ColletorMage_body',
        'body/Com/Com_wiz_DarkmagicMage_body',
        'body/Com/Com_wiz_FlowerMage_body',
        'body/Com/Com_wiz_FoodMage_body',
        'body/Com/Com_wiz_OceanMage_body',
        'body/Com/Com_wiz_OwlMage_body',
        'body/Com/Com_wiz_PumpskinMage_body',
        'body/Com/Com_wiz_SleepyMage_body',
        'body/Com/Com_wiz_VegetableMage_body',
        'body/Com/Com_wiz_WesternMage_body',
      ],
      sub: [
        'sub_body/Com/Com_wiz_ColletorMage_sub_body',
        'sub_body/Com/Com_wiz_DarkmagicMage_sub_body',
        'sub_body/Com/Com_wiz_FlowerMage_sub_body',
        'sub_body/Com/Com_wiz_FoodMage_sub_body',
        'sub_body/Com/Com_wiz_OceanMage_sub_body',
        'sub_body/Com/Com_wiz_OwlMage_sub_body',
        'sub_body/Com/Com_wiz_PumpskinMage_sub_body',
        'sub_body/Com/Com_wiz_SleepyMage_sub_body',
        'sub_body/Com/Com_wiz_VegetableMage_sub_body',
        'sub_body/Com/Com_wiz_WesternMage_sub_body',
      ],
      head: [
        'hat/Com/Com_wiz_ColletorMage_hat',
        'hat/Com/Com_wiz_DarkmagicMage_hat',
        'hat/Com/Com_wiz_FlowerMage_hat',
        'hat/Com/Com_wiz_FoodMage_hat',
        'hat/Com/Com_wiz_OceanMage_hat',
        'hat/Com/Com_wiz_OwlMage_hat',
        'hat/Com/Com_wiz_PumpskinMage_hat',
        'hat/Com/Com_wiz_SleepyMage_hat',
        'hat/Com/Com_wiz_VegetableMage_hat',
        'hat/Com/Com_wiz_WesternMage_hat',
      ],
      left_arm: [
        'left_arm/Com/Com_wiz_ColletorMage_l_arm',
        'left_arm/Com/Com_wiz_DarkmagicMage_l_arm',
        'left_arm/Com/Com_wiz_FlowerMage_l_arm',
        'left_arm/Com/Com_wiz_FoodMage_l_arm',
        'left_arm/Com/Com_wiz_OceanMage_l_arm',
        'left_arm/Com/Com_wiz_OwlMage_l_arm',
        'left_arm/Com/Com_wiz_PumpskinMage_l_arm',
        'left_arm/Com/Com_wiz_SleepyMage_l_arm',
        'left_arm/Com/Com_wiz_VegetableMage_l_arm',
        'left_arm/Com/Com_wiz_WesternMage_l_arm',
      ],
      right_arm: [
        'right_arm/Com/Com_wiz_ColletorMage_r_arm',
        'right_arm/Com/Com_wiz_DarkmagicMage_r_arm',
        'right_arm/Com/Com_wiz_FlowerMage_r_arm',
        'right_arm/Com/Com_wiz_FoodMage_r_arm',
        'right_arm/Com/Com_wiz_OceanMage_r_arm',
        'right_arm/Com/Com_wiz_OwlMage_r_arm',
        'right_arm/Com/Com_wiz_PumpskinMage_r_arm',
        'right_arm/Com/Com_wiz_SleepyMage_r_arm',
        'right_arm/Com/Com_wiz_VegetableMage_r_arm',
        'right_arm/Com/Com_wiz_WesternMage_r_arm',
      ],
      face: [
        'face/Com/Com_wiz_ColletorMage_face',
        'face/Com/Com_wiz_DarkmagicMage_face',
        'face/Com/Com_wiz_FlowerMage_face',
        'face/Com/Com_wiz_FoodMage_face',
        'face/Com/Com_wiz_OceanMage_face',
        'face/Com/Com_wiz_OwlMage_face',
        'face/Com/Com_wiz_PumpskinMage_face',
        'face/Com/Com_wiz_SleepyMage_face',
        'face/Com/Com_wiz_VegetableMage_face',
        'face/Com/Com_wiz_WesternMage_face',
      ],
    },
    {
      body: [
        'body/Un/Un_wiz_ClownMage_body',
        'body/Un/Un_wiz_DarkSwanMage_body',
        'body/Un/Un_wiz_FireworkMage_body',
        'body/Un/Un_wiz_PokerMage_body',
        'body/Un/Un_wiz_RubikMage_body',
      ],
      sub: [
        'sub_body/Un/Un_wiz_ClownMage_sub_body',
        'sub_body/Un/Un_wiz_DarkSwanMage_sub_body',
        'sub_body/Un/Un_wiz_FireworkMage_sub_body',
        'sub_body/Un/Un_wiz_PokerMage_sub_body',
        'sub_body/Un/Un_wiz_RubikMage_sub_body',
      ],
      head: [
        'hat/Un/Un_wiz_ClownMage_hat',
        'hat/Un/Un_wiz_DarkSwanMage_hat',
        'hat/Un/Un_wiz_FireworkMage_hat',
        'hat/Un/Un_wiz_PokerMage_hat',
        'hat/Un/Un_wiz_RubikMage_hat',
      ],
      left_arm: [
        'left_arm/Un/Un_wiz_ClownMage_l_arm',
        'left_arm/Un/Un_wiz_DarkSwanMage_l_arm',
        'left_arm/Un/Un_wiz_FireworkMage_l_arm',
        'left_arm/Un/Un_wiz_PokerMage_l_arm',
        'left_arm/Un/Un_wiz_RubikMage_l_arm',
      ],
      right_arm: [
        'right_arm/Un/Un_wiz_ClownMage_r_arm',
        'right_arm/Un/Un_wiz_DarkSwanMage_r_arm',
        'right_arm/Un/Un_wiz_FireworkMage_r_arm',
        'right_arm/Un/Un_wiz_PokerMage_r_arm',
        'right_arm/Un/Un_wiz_RubikMage_r_arm',
      ],
      face: [
        'face/Un/Un_wiz_ClownMage_face',
        'face/Un/Un_wiz_DarkSwanMage_face',
        'face/Un/Un_wiz_FireworkMage_face',
        'face/Un/Un_wiz_PokerMage_face',
        'face/Un/Un_wiz_RubikMage_face',
      ],
    },
    {
      body: [
        'body/R/R_wiz_AquaMage_body',
        'body/R/R_wiz_BloodMage_body',
        'body/R/R_wiz_ClockMage_body',
        'body/R/R_wiz_PixelWizard_body',
      ],
      sub: [
        'sub_body/R/R_wiz_AquaMage_sub_body',
        'sub_body/R/R_wiz_BloodMage_sub_body',
        'sub_body/R/R_wiz_ClockMage_sub_body',
        'sub_body/R/R_wiz_PixelWizard_sub_body',
      ],
      head: [
        'hat/R/R_wiz_AquaMage_hat',
        'hat/R/R_wiz_BloodMage_hat',
        'hat/R/R_wiz_ClockMage_hat',
        'hat/R/R_wiz_PixelWizard_hat',
      ],
      left_arm: [
        'left_arm/R/R_wiz_AquaMage_l_arm',
        'left_arm/R/R_wiz_BloodMage_l_arm',
        'left_arm/R/R_wiz_ClockMage_l_arm',
        'left_arm/R/R_wiz_PixelWizard_l_arm',
      ],
      right_arm: [
        'right_arm/R/R_wiz_AquaMage_r_arm',
        'right_arm/R/R_wiz_BloodMage_r_arm',
        'right_arm/R/R_wiz_ClockMage_r_arm',
        'right_arm/R/R_wiz_PixelWizard_r_arm',
      ],
      face: [
        'face/R/R_wiz_AquaMage_face',
        'face/R/R_wiz_BloodMage_face',
        'face/R/R_wiz_ClockMage_face',
        'face/R/R_wiz_PixelWizard_face',
      ],
    },
    {
      body: [
        'body/E/E_wiz_DarkspaceWizard_body',
        'body/E/E_wiz_SandyWizard_body',
        'body/E/E_wiz_ThunderWizard_body',
      ],
      sub: [
        'sub_body/E/E_wiz_DarkspaceWizard_sub_body',
        'sub_body/E/E_wiz_SandyWizard_sub_body',
        'sub_body/E/E_wiz_ThunderWizard_sub_body',
      ],
      head: [
        'hat/E/E_wiz_DarkspaceWizard_hat',
        'hat/E/E_wiz_SandyWizard_hat',
        'hat/E/E_wiz_ThunderWizard_hat',
      ],
      left_arm: [
        'left_arm/E/E_wiz_DarkspaceWizard_l_arm',
        'left_arm/E/E_wiz_SandyWizard_l_arm',
        'left_arm/E/E_wiz_ThunderWizard_l_arm',
      ],
      right_arm: [
        'right_arm/E/E_wiz_DarkspaceWizard_r_arm',
        'right_arm/E/E_wiz_SandyWizard_r_arm',
        'right_arm/E/E_wiz_ThunderWizard_r_arm',
      ],
      face: [
        'face/E/E_wiz_DarkspaceWizard_face',
        'face/E/E_wiz_SandyWizard_face',
        'face/E/E_wiz_ThunderWizard_face',
      ],
    },
    {
      body: ['body/L/L_wiz_FireDragon_body', 'body/L/L_wiz_FrostDragon_body'],
      sub: ['sub_body/L/L_wiz_FireDragon_sub_body', 'sub_body/L/L_wiz_FrostDragon_sub_body'],
      head: ['hat/L/L_wiz_FireDragon_hat', 'hat/L/L_wiz_FrostDragon_hat'],
      left_arm: ['left_arm/L/L_wiz_FireDragon_l_arm', 'left_arm/L/L_wiz_FrostDragon_l_arm'],
      right_arm: ['right_arm/L/L_wiz_FireDragon_r_arm', 'right_arm/L/L_wiz_FrostDragon_r_arm'],
      face: ['face/L/L_wiz_FireDragon_face', 'face/L/L_wiz_FrostDragon_face'],
    },
  ],
  [
    {
      body: [
        'body/Com/Com_as_Bonesaw_body',
        'body/Com/Com_as_Bug_body',
        'body/Com/Com_as_Chainsaw_body',
        'body/Com/Com_as_Chemist_body',
        'body/Com/Com_as_Engineer_body',
        'body/Com/Com_as_Mecha_body',
        'body/Com/Com_as_Meow_body',
        'body/Com/Com_as_Phantom_body',
        'body/Com/Com_as_Pirate_body',
        'body/Com/Com_as_Ramen_body',
      ],
      sub: [
        'sub_body/Com/Com_as_Bonesaw_sub_body',
        'sub_body/Com/Com_as_Bug_sub_body',
        'sub_body/Com/Com_as_Chainsaw_sub_body',
        'sub_body/Com/Com_as_Chemist_sub_body',
        'sub_body/Com/Com_as_Engineer_sub_body',
        'sub_body/Com/Com_as_Mecha_sub_body',
        'sub_body/Com/Com_as_Meow_sub_body',
        'sub_body/Com/Com_as_Phantom_sub_body',
        'sub_body/Com/Com_as_Pirate_sub_body',
        'sub_body/Com/Com_as_Ramen_sub_body',
      ],
      head: [
        'hat/Com/Com_as_Bonesaw_hat',
        'hat/Com/Com_as_Bug_hat',
        'hat/Com/Com_as_Chainsaw_hat',
        'hat/Com/Com_as_Chemist_hat',
        'hat/Com/Com_as_Engineer_hat',
        'hat/Com/Com_as_Mecha_hat',
        'hat/Com/Com_as_Meow_hat',
        'hat/Com/Com_as_Phantom_hat',
        'hat/Com/Com_as_Pirate_hat',
        'hat/Com/Com_as_Ramen_hat',
      ],
      left_arm: [
        'left_arm/Com/Com_as_Bonesaw_l_arm',
        'left_arm/Com/Com_as_Bug_l_arm',
        'left_arm/Com/Com_as_Chainsaw_l_arm',
        'left_arm/Com/Com_as_Chemist_l_arm',
        'left_arm/Com/Com_as_Engineer_l_arm',
        'left_arm/Com/Com_as_Mecha_l_arm',
        'left_arm/Com/Com_as_Meow_l_arm',
        'left_arm/Com/Com_as_Phantom_l_arm',
        'left_arm/Com/Com_as_Pirate_l_arm',
        'left_arm/Com/Com_as_Ramen_l_arm',
      ],
      right_arm: [
        'right_arm/Com/Com_as_Bonesaw_r_arm',
        'right_arm/Com/Com_as_Bug_r_arm',
        'right_arm/Com/Com_as_Chainsaw_r_arm',
        'right_arm/Com/Com_as_Chemist_r_arm',
        'right_arm/Com/Com_as_Engineer_r_arm',
        'right_arm/Com/Com_as_Mecha_r_arm',
        'right_arm/Com/Com_as_Meow_r_arm',
        'right_arm/Com/Com_as_Phantom_r_arm',
        'right_arm/Com/Com_as_Pirate_r_arm',
        'right_arm/Com/Com_as_Ramen_r_arm',
      ],
      face: [
        'face/Com/Com_as_Bonesaw_face',
        'face/Com/Com_as_Bug_face',
        'face/Com/Com_as_Chainsaw_face',
        'face/Com/Com_as_Chemist_face',
        'face/Com/Com_as_Engineer_face',
        'face/Com/Com_as_Mecha_face',
        'face/Com/Com_as_Meow_face',
        'face/Com/Com_as_Phantom_face',
        'face/Com/Com_as_Pirate_face',
        'face/Com/Com_as_Ramen_face',
      ],
    },
    {
      body: [
        'body/Un/Un_as_Clown_body',
        'body/Un/Un_as_Food_body',
        'body/Un/Un_as_Gambler_body',
        'body/Un/Un_as_Space_body',
        'body/Un/Un_as_Surgeon_body',
      ],
      sub: [
        'sub_body/Un/Un_as_Clown_sub_body',
        'sub_body/Un/Un_as_Food_sub_body',
        'sub_body/Un/Un_as_Gambler_sub_body',
        'sub_body/Un/Un_as_Space_sub_body',
        'sub_body/Un/Un_as_Surgeon_sub_body',
      ],
      head: [
        'hat/Un/Un_as_Clown_hat',
        'hat/Un/Un_as_Food_hat',
        'hat/Un/Un_as_Gambler_hat',
        'hat/Un/Un_as_Space_hat',
        'hat/Un/Un_as_Surgeon_hat',
      ],
      left_arm: [
        'left_arm/Un/Un_as_Clown_l_arm',
        'left_arm/Un/Un_as_Food_l_arm',
        'left_arm/Un/Un_as_Gambler_l_arm',
        'left_arm/Un/Un_as_Space_l_arm',
        'left_arm/Un/Un_as_Surgeon_l_arm',
      ],
      right_arm: [
        'right_arm/Un/Un_as_Clown_r_arm',
        'right_arm/Un/Un_as_Food_r_arm',
        'right_arm/Un/Un_as_Gambler_r_arm',
        'right_arm/Un/Un_as_Space_r_arm',
        'right_arm/Un/Un_as_Surgeon_r_arm',
      ],
      face: [
        'face/Un/Un_as_Clown_face',
        'face/Un/Un_as_Food_face',
        'face/Un/Un_as_Gambler_face',
        'face/Un/Un_as_Space_face',
        'face/Un/Un_as_Surgeon_face',
      ],
    },
    {
      body: [
        'body/R/R_as_Bee_ninja_body',
        'body/R/R_as_Leaf_ninja_body',
        'body/R/R_as_Pixel_Dagger_body',
        'body/R/R_as_Scythe_body',
      ],
      sub: [
        'sub_body/R/R_as_Bee_ninja_sub_body',
        'sub_body/R/R_as_Leaf_ninja_sub_body',
        'sub_body/R/R_as_Pixel_Dagger_sub_body',
        'sub_body/R/R_as_Scythe_sub_body',
      ],
      head: [
        'hat/R/R_as_Bee_ninja_hat',
        'hat/R/R_as_Leaf_ninja_hat',
        'hat/R/R_as_Pixel_Dagger_hat',
        'hat/R/R_as_Scythe_hat',
      ],
      left_arm: [
        'left_arm/R/R_as_Bee_ninja_l_arm',
        'left_arm/R/R_as_Leaf_ninja_l_arm',
        'left_arm/R/R_as_Pixel_Dagger_l_arm',
        'left_arm/R/R_as_Scythe_l_arm',
      ],
      right_arm: [
        'right_arm/R/R_as_Bee_ninja_r_arm',
        'right_arm/R/R_as_Leaf_ninja_r_arm',
        'right_arm/R/R_as_Pixel_Dagger_r_arm',
        'right_arm/R/R_as_Scythe_r_arm',
      ],
      face: [
        'face/R/R_as_Bee_ninja_face',
        'face/R/R_as_Leaf_ninja_face',
        'face/R/R_as_Pixel_Dagger_face',
        'face/R/R_as_Scythe_face',
      ],
    },
    {
      body: [
        'body/E/E_as_WolfFang_body',
        'body/E/E_as_TimeThief_body',
        'body/E/E_as_FlyingBlade_body',
      ],
      sub: [
        'sub_body/E/E_as_WolfFang_sub_body',
        'sub_body/E/E_as_TimeThief_sub_body',
        'sub_body/E/E_as_FlyingBlade_sub_body',
      ],
      head: ['hat/E/E_as_WolfFang_hat', 'hat/E/E_as_TimeThief_hat', 'hat/E/E_as_FlyingBlade_hat'],
      left_arm: [
        'left_arm/E/E_as_WolfFang_l_arm',
        'left_arm/E/E_as_TimeThief_l_arm',
        'left_arm/E/E_as_FlyingBlade_l_arm',
      ],
      right_arm: [
        'right_arm/E/E_as_WolfFang_r_arm',
        'right_arm/E/E_as_TimeThief_r_arm',
        'right_arm/E/E_as_FlyingBlade_r_arm',
      ],
      face: [
        'face/E/E_as_WolfFang_face',
        'face/E/E_as_TimeThief_face',
        'face/E/E_as_FlyingBlade_face',
      ],
    },
    {
      body: ['body/L/L_as_ViperDagger_body', 'body/L/L_as_SamuraiGhost_body'],
      sub: ['sub_body/L/L_as_ViperDagger_sub_body', 'sub_body/L/L_as_SamuraiGhost_sub_body'],
      head: ['hat/L/L_as_ViperDagger_hat', 'hat/L/L_as_SamuraiGhost_hat'],
      left_arm: ['left_arm/L/L_as_ViperDagger_l_arm', 'left_arm/L/L_as_SamuraiGhost_l_arm'],
      right_arm: ['right_arm/L/L_as_ViperDagger_r_arm', 'right_arm/L/L_as_SamuraiGhost_r_arm'],
      face: ['face/L/L_as_ViperDagger_face', 'face/L/L_as_SamuraiGhost_face'],
    },
  ],
  [
    {
      body: [
        'body/Com/Com_st_Arbiter_body',
        'body/Com/Com_st_Athen_body',
        'body/Com/Com_st_Gamble_body',
        'body/Com/Com_st_Guardian_body',
        'body/Com/Com_st_Majin_body',
        'body/Com/Com_st_Oracle_body',
        'body/Com/Com_st_Owl_body',
        'body/Com/Com_st_Paladin_light_body',
        'body/Com/Com_st_Priest_body',
        'body/Com/Com_st_Sun_body',
      ],
      sub: [
        'sub_body/Com/Com_st_Arbiter_sub_body',
        'sub_body/Com/Com_st_Athen_sub_body',
        'sub_body/Com/Com_st_Gamble_sub_body',
        'sub_body/Com/Com_st_Guardian_sub_body',
        'sub_body/Com/Com_st_Majin_sub_body',
        'sub_body/Com/Com_st_Oracle_sub_body',
        'sub_body/Com/Com_st_Owl_sub_body',
        'sub_body/Com/Com_st_Paladin_light_sub_body',
        'sub_body/Com/Com_st_Priest_sub_body',
        'sub_body/Com/Com_st_Sun_sub_body',
      ],
      head: [
        'hat/Com/Com_st_Arbiter_hat',
        'hat/Com/Com_st_Athen_hat',
        'hat/Com/Com_st_Gamble_hat',
        'hat/Com/Com_st_Guardian_hat',
        'hat/Com/Com_st_Majin_hat',
        'hat/Com/Com_st_Oracle_hat',
        'hat/Com/Com_st_Owl_hat',
        'hat/Com/Com_st_Paladin_light_hat',
        'hat/Com/Com_st_Priest_hat',
        'hat/Com/Com_st_Sun_hat',
      ],
      left_arm: [
        'left_arm/Com/Com_st_Arbiter_l_arm',
        'left_arm/Com/Com_st_Athen_l_arm',
        'left_arm/Com/Com_st_Gamble_l_arm',
        'left_arm/Com/Com_st_Guardian_l_arm',
        'left_arm/Com/Com_st_Majin_l_arm',
        'left_arm/Com/Com_st_Oracle_l_arm',
        'left_arm/Com/Com_st_Owl_l_arm',
        'left_arm/Com/Com_st_Paladin_light_l_arm',
        'left_arm/Com/Com_st_Priest_l_arm',
        'left_arm/Com/Com_st_Sun_l_arm',
      ],
      right_arm: [
        'right_arm/Com/Com_st_Arbiter_r_arm',
        'right_arm/Com/Com_st_Athen_r_arm',
        'right_arm/Com/Com_st_Gamble_r_arm',
        'right_arm/Com/Com_st_Guardian_r_arm',
        'right_arm/Com/Com_st_Majin_r_arm',
        'right_arm/Com/Com_st_Oracle_r_arm',
        'right_arm/Com/Com_st_Owl_r_arm',
        'right_arm/Com/Com_st_Paladin_light_r_arm',
        'right_arm/Com/Com_st_Priest_r_arm',
        'right_arm/Com/Com_st_Sun_r_arm',
      ],
      face: [
        'face/Com/Com_st_Arbiter_face',
        'face/Com/Com_st_Athen_face',
        'face/Com/Com_st_Gamble_face',
        'face/Com/Com_st_Guardian_face',
        'face/Com/Com_st_Majin_face',
        'face/Com/Com_st_Oracle_face',
        'face/Com/Com_st_Owl_face',
        'face/Com/Com_st_Paladin_light_face',
        'face/Com/Com_st_Priest_face',
        'face/Com/Com_st_Sun_face',
      ],
    },
    {
      body: [
        'body/Un/Un_st_FoodSaint_body',
        'body/Un/Un_st_MeowSaint_body',
        'body/Un/Un_st_NurseSaint_body',
        'body/Un/Un_st_PuppetSaint_body',
        'body/Un/Un_st_Swan_body',
      ],
      sub: [
        'sub_body/Un/Un_st_FoodSaint_sub_body',
        'sub_body/Un/Un_st_MeowSaint_sub_body',
        'sub_body/Un/Un_st_NurseSaint_sub_body',
        'sub_body/Un/Un_st_PuppetSaint_sub_body',
        'sub_body/Un/Un_st_Swan_sub_body',
      ],
      head: [
        'hat/Un/Un_st_FoodSaint_hat',
        'hat/Un/Un_st_MeowSaint_hat',
        'hat/Un/Un_st_NurseSaint_hat',
        'hat/Un/Un_st_PuppetSaint_hat',
        'hat/Un/Un_st_Swan_hat',
      ],
      left_arm: [
        'left_arm/Un/Un_st_FoodSaint_l_arm',
        'left_arm/Un/Un_st_MeowSaint_l_arm',
        'left_arm/Un/Un_st_NurseSaint_l_arm',
        'left_arm/Un/Un_st_PuppetSaint_l_arm',
        'left_arm/Un/Un_st_Swan_l_arm',
      ],
      right_arm: [
        'right_arm/Un/Un_st_FoodSaint_r_arm',
        'right_arm/Un/Un_st_MeowSaint_r_arm',
        'right_arm/Un/Un_st_NurseSaint_r_arm',
        'right_arm/Un/Un_st_PuppetSaint_r_arm',
        'right_arm/Un/Un_st_Swan_r_arm',
      ],
      face: [
        'face/Un/Un_st_FoodSaint_face',
        'face/Un/Un_st_MeowSaint_face',
        'face/Un/Un_st_NurseSaint_face',
        'face/Un/Un_st_PuppetSaint_face',
        'face/Un/Un_st_Swan_face',
      ],
    },
    {
      body: [
        'body/R/R_st_Flower_body',
        'body/R/R_st_Machine_body',
        'body/R/R_st_Moonlight_body',
        'body/R/R_st_PixelClover_body',
      ],
      sub: [
        'sub_body/R/R_st_Flower_sub_body',
        'sub_body/R/R_st_Machine_sub_body',
        'sub_body/R/R_st_Moonlight_sub_body',
        'sub_body/R/R_st_PixelClover_sub_body',
      ],
      head: [
        'hat/R/R_st_Flower_hat',
        'hat/R/R_st_Machine_hat',
        'hat/R/R_st_Moonlight_hat',
        'hat/R/R_st_PixelClover_hat',
      ],
      left_arm: [
        'left_arm/R/R_st_Flower_l_arm',
        'left_arm/R/R_st_Machine_l_arm',
        'left_arm/R/R_st_Moonlight_l_arm',
        'left_arm/R/R_st_PixelClover_l_arm',
      ],
      right_arm: [
        'right_arm/R/R_st_Flower_r_arm',
        'right_arm/R/R_st_Machine_r_arm',
        'right_arm/R/R_st_Moonlight_r_arm',
        'right_arm/R/R_st_PixelClover_r_arm',
      ],
      face: [
        'face/R/R_st_Flower_face',
        'face/R/R_st_Machine_face',
        'face/R/R_st_Moonlight_face',
        'face/R/R_st_PixelClover_face',
      ],
    },
    {
      body: ['body/E/E_st_Bard_body', 'body/E/E_st_Fairy_body', 'body/E/E_st_Windy_body'],
      sub: [
        'sub_body/E/E_st_Bard_sub_body',
        'sub_body/E/E_st_Fairy_sub_body',
        'sub_body/E/E_st_Windy_sub_body',
      ],
      head: ['hat/E/E_st_Bard_hat', 'hat/E/E_st_Fairy_hat', 'hat/E/E_st_Windy_hat'],
      left_arm: [
        'left_arm/E/E_st_Bard_l_arm',
        'left_arm/E/E_st_Fairy_l_arm',
        'left_arm/E/E_st_Windy_l_arm',
      ],
      right_arm: [
        'right_arm/E/E_st_Bard_r_arm',
        'right_arm/E/E_st_Fairy_r_arm',
        'right_arm/E/E_st_Windy_r_arm',
      ],
      face: ['face/E/E_st_Bard_face', 'face/E/E_st_Fairy_face', 'face/E/E_st_Windy_face'],
    },
    {
      body: ['body/L/L_st_AngelMaiden_body', 'body/L/L_st_StarLight_body'],
      sub: ['sub_body/L/L_st_AngelMaiden_sub_body', 'sub_body/L/L_st_StarLight_sub_body'],
      head: ['hat/L/L_st_AngelMaiden_hat', 'hat/L/L_st_StarLight_hat'],
      left_arm: ['left_arm/L/L_st_AngelMaiden_l_arm', 'left_arm/L/L_st_StarLight_l_arm'],
      right_arm: ['right_arm/L/L_st_AngelMaiden_r_arm', 'right_arm/L/L_st_StarLight_r_arm'],
      face: ['face/L/L_st_AngelMaiden_face', 'face/L/L_st_StarLight_face'],
    },
  ],
  [
    {
      body: [
        'body/Com/Com_sha_AudioShaman_body',
        'body/Com/Com_sha_BomberShaman_body',
        'body/Com/Com_sha_BookShaman_body',
        'body/Com/Com_sha_FoodShaman_body',
        'body/Com/Com_sha_JunkShaman_body',
        'body/Com/Com_sha_MummyShaman_body',
        'body/Com/Com_sha_MushroomShaman_body',
        'body/Com/Com_sha_OceanShaman_body',
        'body/Com/Com_sha_TropicaShaman_body',
        'body/Com/Com_sha_VoodooShaman_body',
      ],
      sub: [
        'sub_body/Com/Com_sha_AudioShaman_sub_body',
        'sub_body/Com/Com_sha_BomberShaman_sub_body',
        'sub_body/Com/Com_sha_BookShaman_sub_body',
        'sub_body/Com/Com_sha_FoodShaman_sub_body',
        'sub_body/Com/Com_sha_JunkShaman_sub_body',
        'sub_body/Com/Com_sha_MummyShaman_sub_body',
        'sub_body/Com/Com_sha_MushroomShaman_sub_body',
        'sub_body/Com/Com_sha_OceanShaman_sub_body',
        'sub_body/Com/Com_sha_TropicaShaman_sub_body',
        'sub_body/Com/Com_sha_VoodooShaman_sub_body',
      ],
      head: [
        'hat/Com/Com_sha_AudioShaman_hat',
        'hat/Com/Com_sha_BomberShaman_hat',
        'hat/Com/Com_sha_BookShaman_hat',
        'hat/Com/Com_sha_FoodShaman_hat',
        'hat/Com/Com_sha_JunkShaman_hat',
        'hat/Com/Com_sha_MummyShaman_hat',
        'hat/Com/Com_sha_MushroomShaman_hat',
        'hat/Com/Com_sha_OceanShaman_hat',
        'hat/Com/Com_sha_TropicaShaman_hat',
        'hat/Com/Com_sha_VoodooShaman_hat',
      ],
      left_arm: [
        'left_arm/Com/Com_sha_AudioShaman_l_arm',
        'left_arm/Com/Com_sha_BomberShaman_l_arm',
        'left_arm/Com/Com_sha_BookShaman_l_arm',
        'left_arm/Com/Com_sha_FoodShaman_l_arm',
        'left_arm/Com/Com_sha_JunkShaman_l_arm',
        'left_arm/Com/Com_sha_MummyShaman_l_arm',
        'left_arm/Com/Com_sha_MushroomShaman_l_arm',
        'left_arm/Com/Com_sha_OceanShaman_l_arm',
        'left_arm/Com/Com_sha_TropicaShaman_l_arm',
        'left_arm/Com/Com_sha_VoodooShaman_l_arm',
      ],
      right_arm: [
        'right_arm/Com/Com_sha_AudioShaman_r_arm',
        'right_arm/Com/Com_sha_BomberShaman_r_arm',
        'right_arm/Com/Com_sha_BookShaman_r_arm',
        'right_arm/Com/Com_sha_FoodShaman_r_arm',
        'right_arm/Com/Com_sha_JunkShaman_r_arm',
        'right_arm/Com/Com_sha_MummyShaman_r_arm',
        'right_arm/Com/Com_sha_MushroomShaman_r_arm',
        'right_arm/Com/Com_sha_OceanShaman_r_arm',
        'right_arm/Com/Com_sha_TropicaShaman_r_arm',
        'right_arm/Com/Com_sha_VoodooShaman_r_arm',
      ],
      face: [
        'face/Com/Com_sha_AudioShaman_face',
        'face/Com/Com_sha_BomberShaman_face',
        'face/Com/Com_sha_BookShaman_face',
        'face/Com/Com_sha_FoodShaman_face',
        'face/Com/Com_sha_JunkShaman_face',
        'face/Com/Com_sha_MummyShaman_face',
        'face/Com/Com_sha_MushroomShaman_face',
        'face/Com/Com_sha_OceanShaman_face',
        'face/Com/Com_sha_TropicaShaman_face',
        'face/Com/Com_sha_VoodooShaman_face',
      ],
    },
    {
      body: [
        'body/Un/Un_sha_CookieShaman_body',
        'body/Un/Un_sha_JungleShaman_body',
        'body/Un/Un_sha_RubikShaman_body',
        'body/Un/Un_sha_ShamanDoctor_body',
        'body/Un/Un_sha_SpaceShaman_body',
      ],
      sub: [
        'sub_body/Un/Un_sha_CookieShaman_sub_body',
        'sub_body/Un/Un_sha_JungleShaman_sub_body',
        'sub_body/Un/Un_sha_RubikShaman_sub_body',
        'sub_body/Un/Un_sha_ShamanDoctor_sub_body',
        'sub_body/Un/Un_sha_SpaceShaman_sub_body',
      ],
      head: [
        'hat/Un/Un_sha_CookieShaman_hat',
        'hat/Un/Un_sha_JungleShaman_hat',
        'hat/Un/Un_sha_RubikShaman_hat',
        'hat/Un/Un_sha_ShamanDoctor_hat',
        'hat/Un/Un_sha_SpaceShaman_hat',
      ],
      left_arm: [
        'left_arm/Un/Un_sha_CookieShaman_l_arm',
        'left_arm/Un/Un_sha_JungleShaman_l_arm',
        'left_arm/Un/Un_sha_RubikShaman_l_arm',
        'left_arm/Un/Un_sha_ShamanDoctor_l_arm',
        'left_arm/Un/Un_sha_SpaceShaman_l_arm',
      ],
      right_arm: [
        'right_arm/Un/Un_sha_CookieShaman_r_arm',
        'right_arm/Un/Un_sha_JungleShaman_r_arm',
        'right_arm/Un/Un_sha_RubikShaman_r_arm',
        'right_arm/Un/Un_sha_ShamanDoctor_r_arm',
        'right_arm/Un/Un_sha_SpaceShaman_r_arm',
      ],
      face: [
        'face/Un/Un_sha_CookieShaman_face',
        'face/Un/Un_sha_JungleShaman_face',
        'face/Un/Un_sha_RubikShaman_face',
        'face/Un/Un_sha_ShamanDoctor_face',
        'face/Un/Un_sha_SpaceShaman_face',
      ],
    },
    {
      body: [
        'body/R/R_sha_BugMaster_body',
        'body/R/R_sha_PixelShaman_body',
        'body/R/R_sha_PuppetMaster_body',
        'body/R/R_sha_SpiderMaster_body',
      ],
      sub: [
        'sub_body/R/R_sha_BugMaster_sub_body',
        'sub_body/R/R_sha_PixelShaman_sub_body',
        'sub_body/R/R_sha_PuppetMaster_sub_body',
        'sub_body/R/R_sha_SpiderMaster_sub_body',
      ],
      head: [
        'hat/R/R_sha_BugMaster_hat',
        'hat/R/R_sha_PixelShaman_hat',
        'hat/R/R_sha_PuppetMaster_hat',
        'hat/R/R_sha_SpiderMaster_hat',
      ],
      left_arm: [
        'left_arm/R/R_sha_BugMaster_l_arm',
        'left_arm/R/R_sha_PixelShaman_l_arm',
        'left_arm/R/R_sha_PuppetMaster_l_arm',
        'left_arm/R/R_sha_SpiderMaster_l_arm',
      ],
      right_arm: [
        'right_arm/R/R_sha_BugMaster_r_arm',
        'right_arm/R/R_sha_PixelShaman_r_arm',
        'right_arm/R/R_sha_PuppetMaster_r_arm',
        'right_arm/R/R_sha_SpiderMaster_r_arm',
      ],
      face: [
        'face/R/R_sha_BugMaster_face',
        'face/R/R_sha_PixelShaman_face',
        'face/R/R_sha_PuppetMaster_face',
        'face/R/R_sha_SpiderMaster_face',
      ],
    },
    {
      body: [
        'body/E/E_sha_CrowQueen_body',
        'body/E/E_sha_DarkWitch_body',
        'body/E/E_sha_TotemMaster_body',
      ],
      sub: [
        'sub_body/E/E_sha_CrowQueen_sub_body',
        'sub_body/E/E_sha_DarkWitch_sub_body',
        'sub_body/E/E_sha_TotemMaster_sub_body',
      ],
      head: [
        'hat/E/E_sha_CrowQueen_hat',
        'hat/E/E_sha_DarkWitch_hat',
        'hat/E/E_sha_TotemMaster_hat',
      ],
      left_arm: [
        'left_arm/E/E_sha_CrowQueen_l_arm',
        'left_arm/E/E_sha_DarkWitch_l_arm',
        'left_arm/E/E_sha_TotemMaster_l_arm',
      ],
      right_arm: [
        'right_arm/E/E_sha_CrowQueen_r_arm',
        'right_arm/E/E_sha_DarkWitch_r_arm',
        'right_arm/E/E_sha_TotemMaster_r_arm',
      ],
      face: [
        'face/E/E_sha_CrowQueen_face',
        'face/E/E_sha_DarkWitch_face',
        'face/E/E_sha_TotemMaster_face',
      ],
    },
    {
      body: ['body/L/L_sha_GhostSummoner_body', 'body/L/L_sha_NineTails_body'],
      sub: ['sub_body/L/L_sha_GhostSummoner_sub_body', 'sub_body/L/L_sha_NineTails_sub_body'],
      head: ['hat/L/L_sha_GhostSummoner_hat', 'hat/L/L_sha_NineTails_hat'],
      left_arm: ['left_arm/L/L_sha_GhostSummoner_l_arm', 'left_arm/L/L_sha_NineTails_l_arm'],
      right_arm: ['right_arm/L/L_sha_GhostSummoner_r_arm', 'right_arm/L/L_sha_NineTails_r_arm'],
      face: ['face/L/L_sha_GhostSummoner_face', 'face/L/L_sha_NineTails_face'],
    },
  ],
];
