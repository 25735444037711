import { useCallback, useState } from 'react';
import { sleep } from 'src/common/utils';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import * as marketService from 'src/services/market';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/globals/constants';

const useRemoveNFTFromFixedPriceSaleMarket = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [isRemovingNFTFromFixedPriceSale, setIsRemovingNFTFromFixedPriceSale] = useState<
    boolean | undefined
  >();
  const queryClient = useQueryClient();
  const onRemoveNFTFromFixedPriceSaleMarket = useCallback(
    (saleId: string, onCancelSuccess?: (txid: string) => void) => {
      setError(undefined);
      setIsRemovingNFTFromFixedPriceSale(true);
      marketService
        .removeSaleFromFixedSaleMarket(coreUtils.getMarketContractByNftType(nftType), saleId)
        .then(async (hash) => {
          await sleep(5000);
          if (onCancelSuccess) {
            onCancelSuccess(hash);
          }
          queryClient.invalidateQueries([QueryKeys.availableNftForSale]);
          queryClient.invalidateQueries([QueryKeys.myNfts]);
          queryClient.invalidateQueries([QueryKeys.myNftDetails]);
          queryClient.invalidateQueries([QueryKeys.currentSellingNft]);
          queryClient.invalidateQueries([QueryKeys.userActivities]);
        }) // For delay confirmation
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsRemovingNFTFromFixedPriceSale(false);
        });
    },
    [nftType, queryClient]
  );

  return [isRemovingNFTFromFixedPriceSale, onRemoveNFTFromFixedPriceSaleMarket, error];
};

export default useRemoveNFTFromFixedPriceSaleMarket;
