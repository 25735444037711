import { useQuery } from '@tanstack/react-query';
import BN from 'bn.js';
import { QueryKeys } from 'src/globals/constants';
import * as balanceService from 'src/services/balance';

const useFTCBalanceQuery = (address?: string) => {
  const { isFetching, data, error } = useQuery<BN, string>(
    [QueryKeys.ftcBalanceQueryKey, address],
    async () => {
      if (!address) throw new Error('Address is required');
      return balanceService.getFTCBalanceWagmi(address);
    },
    {
      keepPreviousData: false,
      enabled: !!address,
    }
  );

  return { isLoading: isFetching, data, error };
};

export default useFTCBalanceQuery;
