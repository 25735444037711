import { Box, Flex, Spinner } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Flex height="calc(100vh - 75px)" alignItems="center" justifyContent="center">
      <Box textAlign="center" py={12}>
        <Spinner size="xl" color="teal.500" />
      </Box>
    </Flex>
  );
};

export default Loading;
