import { Box, HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { Stat } from 'src/gql/types';
import { Colors } from '../../../../shared';
import { GeneClassLabel, RarityLabel } from '../../../../components';

interface PartPropsType {
  title: string;
  klass: number;
  rarity: number;
  displayMode: 'percentage' | 'point';
  def?: Stat;
  atk?: Stat;
  hp?: Stat;
  critDmg?: Stat;
  block?: Stat;
  crit?: Stat;
  evade?: Stat;
}

const Part = ({ title, klass, rarity, displayMode, ...props }: PartPropsType) => {
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const backgroundColor = useColorModeValue(Colors.light.bg, Colors.dark.bg);

  const getPercentage = (value: Stat) => {
    return value.value;
  };

  const getDisplayValue = (value: Stat): number | string => {
    if (displayMode === 'percentage') {
      return value.value + '%';
    }
    return Math.ceil(value.points);
  };

  return (
    <Box p={2} mt={2} rounded={5} backgroundColor={backgroundColor}>
      <VStack align="start" mt="10px">
        <HStack justify="space-between" w="100%">
          <Text color={descriptionColor} fontWeight="bold">
            {title}
          </Text>
          <GeneClassLabel geneClass={klass} />
          <RarityLabel rarity={rarity} />
        </HStack>
        <Box shadow="md" rounded={5} width="100%">
          <HStack justify="space-around" w="100%" gap={0}>
            {props.atk?.value && (
              <Box width={`${getPercentage(props.atk)}%`} backgroundColor={Colors.dark.atk}>
                <VStack gap={0} py={1}>
                  <span style={{ fontSize: 10 }}>ATK</span>
                  <span style={{ fontSize: 10, marginTop: 0 }}>{getDisplayValue(props.atk)}</span>
                </VStack>
              </Box>
            )}
            {props.def?.value && (
              <Box width={`${getPercentage(props.def)}%`} backgroundColor={Colors.dark.def}>
                <VStack gap={0} py={1}>
                  <span style={{ fontSize: 10 }}>DEF</span>
                  <span style={{ fontSize: 10, marginTop: 0 }}>{getDisplayValue(props.def)}</span>
                </VStack>
              </Box>
            )}
            {props.hp?.value && (
              <Box width={`${getPercentage(props.hp)}%`} backgroundColor={Colors.dark.hp}>
                <VStack gap={0} py={1}>
                  <span style={{ fontSize: 10 }}>HP</span>
                  <span style={{ fontSize: 10, marginTop: 0 }}>{getDisplayValue(props.hp)}</span>
                </VStack>
              </Box>
            )}
            {props.critDmg?.value && (
              <Box width={`${getPercentage(props.critDmg)}%`} backgroundColor={Colors.dark.critDmg}>
                <VStack gap={0} py={1}>
                  <span style={{ fontSize: 10 }}>CRITDMG</span>
                  <span style={{ fontSize: 10, marginTop: 0 }}>
                    {getDisplayValue(props.critDmg)}
                  </span>
                </VStack>
              </Box>
            )}
            {props.block?.value && (
              <Box width={`${getPercentage(props.block)}%`} backgroundColor={Colors.dark.block}>
                <VStack gap={0} py={1}>
                  <span style={{ fontSize: 10 }}>BLOCK</span>
                  <span style={{ fontSize: 10, marginTop: 0 }}>{getDisplayValue(props.block)}</span>
                </VStack>
              </Box>
            )}
            {props.crit?.value && (
              <Box width={`${getPercentage(props.crit)}%`} backgroundColor={Colors.dark.crit}>
                <VStack gap={0} py={1}>
                  <span style={{ fontSize: 10 }}>CRIT</span>
                  <span style={{ fontSize: 10, marginTop: 0 }}>{getDisplayValue(props.crit)}</span>
                </VStack>
              </Box>
            )}
            {props.evade?.value && (
              <Box width={`${getPercentage(props.evade)}%`} backgroundColor={Colors.dark.evade}>
                <VStack gap={0} py={1}>
                  <span style={{ fontSize: 10 }}>EVADE</span>
                  <span style={{ fontSize: 10, marginTop: 0 }}>{getDisplayValue(props.evade)}</span>
                </VStack>
              </Box>
            )}
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default Part;
