import React, { memo } from 'react';
import get from 'lodash/get';
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/shared';
import { appUtils, timeUtils } from 'src/common';
import { FixedPriceSaleLabel } from 'src/components';
import { ISaleInfoFromMetaMask } from 'src/metamask-provider/type';

export interface NFTItemProps {
  item: ISaleInfoFromMetaMask;
  rootPath: string;
}

const FixedPriceSaleNFTItem = (props: NFTItemProps) => {
  const { t } = useTranslation();
  const { item, rootPath } = props;
  const hoverBg = useColorModeValue(Colors.light.cardBg, Colors.dark.cardBg);
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  const saleId = get(item, 'saleId', '0');
  const amount = get(item, 'amount', 1); // amount not use for ERC-721
  const sellTime = get(item, 'sellTime', 0);
  const originPrice = get(item, 'price', '0');
  const originalCurrency = 'FTC'; // TODO: currently we have a currency, we need to support get currency info from metamask info when we having multiple currency on system

  const linkTo = appUtils.getNFTDetailUrl(saleId, rootPath);
  return (
    <Box w="full" overflow="hidden">
      <Link to={linkTo}>
        <Flex
          w="full"
          p={[2, 4]}
          rounded="md"
          overflow="hidden"
          bg={'transparent'}
          _hover={{
            bg: hoverBg,
          }}
          alignItems="center"
        >
          <HStack alignItems="flex-start" flex={1} pr={2}>
            <Flex pos="relative" p={0} flex={0.9} flexDirection="column">
              <Text color={descriptionColor} pb={2}>
                {t('Component:OpenSaleOrderList.Header.Order')}
              </Text>
              <Text color={textColor} pb={2}>
                {saleId}
              </Text>
            </Flex>
            <Flex pos="relative" p={0} flex={0.9} flexDirection="column">
              <Text color={descriptionColor} pb={2}>
                {t('Component:OpenSaleOrderList.Header.Quantity')}
              </Text>
              <Text color={textColor} pb={2}>
                {amount}
              </Text>
            </Flex>
            <Flex pos="relative" p={0} flex={1.2} flexDirection="column" alignItems={'flex-end'}>
              <FixedPriceSaleLabel
                data={{
                  price: originPrice,
                  priceCurrency: originalCurrency,
                }}
                pb={2}
              />
              <Text
                color={textColor}
                pb={2}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {timeUtils.displayTimeFromNow(sellTime)}
              </Text>
            </Flex>
          </HStack>
          <ChevronRightIcon h={8} w={8} color={descriptionColor} mr={[-2, -4]} />
        </Flex>
      </Link>
    </Box>
  );
};

export default memo(FixedPriceSaleNFTItem);
