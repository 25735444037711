import { Flex } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { coreUtils } from 'src/common';
import { INFT, ISlimeMetadata } from '../gql/types';
import { SLIME_SPINE_RESOURCE_URL } from '../globals/configs';
import Spinner from './Spinner';

interface SlimeSpinePreviewProps {
  slime?: INFT | null;
  renderKey?: string;
  isLoading?: boolean;
}

const DefaultSkin = 'x_full_skin/Com/Com_as_Bonesaw';

const getImageWithDummy = (imageUrl: string, updatedDate?: number) => {
  const shouldForceLoadNew = updatedDate && new Date().getTime() / 1000 - updatedDate < 60 * 60;
  const dummy = shouldForceLoadNew ? `?dummy=${Math.random()}` : '';
  return `${imageUrl}${dummy}`;
};

const SlimeSpinePreview = ({ renderKey, slime, isLoading }: SlimeSpinePreviewProps) => {
  const slimeSpinePreviewRef = useRef<any | null>();
  const [isSpinePlayerReady, setIsSpinePlayerReady] = useState(false);

  useEffect(() => {
    if (slime && slimeSpinePreviewRef.current) {
      const skins = coreUtils.getSlimeSkinsFromSlimeData(slime);
      const slimeMetadata = slime?.metadata as ISlimeMetadata;
      const originCustomized = {
        head: slimeMetadata?.headDetails.customImage,
        leftArm: slimeMetadata?.leftArmDetails.customImage,
        rightArm: slimeMetadata?.rightArmDetails.customImage,
      };

      Object.keys(originCustomized).forEach((key: string) => {
        // @ts-ignore
        if (originCustomized[key]) {
          // @ts-ignore
          originCustomized[key] = getImageWithDummy(originCustomized[key], slime?.updatedAt);
        }
      });

      if (originCustomized.leftArm) {
        const idx = skins.findIndex((s) => s.startsWith('left_arm/'));
        if (idx >= 0) skins.splice(idx, 1);
      }
      if (originCustomized.rightArm) {
        const idx = skins.findIndex((s) => s.startsWith('right_arm/'));
        if (idx >= 0) skins.splice(idx, 1);
      }
      if (originCustomized.head) {
        const idx = skins.findIndex((s) => s.startsWith('hat/'));
        if (idx >= 0) skins.splice(idx, 1);
      }

      const spineSkin = coreUtils.getSpineSkin(skins, slimeSpinePreviewRef.current);

      const customizeAiPart = async (
        imageUrl: string,
        skinName: string,
        slotName: string,
        attachmentName: string
      ) => {
        return new Promise((resolve) => {
          const skeleton = slimeSpinePreviewRef.current.skeleton;
          const slotIndex = skeleton.findSlotIndex(slotName);
          const attachment = skeleton.data
            .findSkin(skinName)
            .getAttachment(slotIndex, attachmentName);
          slimeSpinePreviewRef.current.assetManager.loadTexture(
            imageUrl,
            (path: string, img: any) => {
              // @ts-ignore
              const texture = new spine.webgl.GLTexture(slimeSpinePreviewRef.current.context, img);
              // @ts-ignore
              const region = Object.assign(new spine.TextureAtlasRegion(), {
                name: 'ai-image',
                index: -1,
                degrees: 0,
                width: texture.getImage().width,
                height: texture.getImage().height,
                offsetX: 0,
                offsetY: 0,
                originalWidth: texture.getImage().width,
                originalHeight: texture.getImage().height,
                rotate: false,
                texture,
                u: 0,
                v: 0,
                u2: 1,
                v2: 1,
                x: 0,
                y: 0,
                // @ts-ignore
                page: Object.assign(new spine.TextureAtlasRegion(), {
                  width: texture.getImage().width,
                  height: texture.getImage().height,
                  texture,
                  name: 'ai-image',
                  minFilter: 9729,
                  magFilter: 9729,
                  uWrap: 33071,
                  vWrap: 33071,
                }),
              });
              region.renderObject = region;
              if (attachment.updateUVs) {
                attachment.region = region;
                attachment.updateUVs();
              } else {
                attachment.setRegion(region);
              }
              spineSkin.setAttachment(slotIndex, attachmentName, attachment);
              resolve(true);
            }
          );
        });
      };

      let promises = [];
      if (originCustomized) {
        if (originCustomized.leftArm) {
          promises.push(
            customizeAiPart(
              originCustomized.leftArm,
              'left_arm/l_arm_generated',
              'l_arm_AI',
              'l_arm_AI'
            )
          );
        }
        if (originCustomized.rightArm) {
          promises.push(
            customizeAiPart(
              originCustomized.rightArm,
              'right_arm/r_arm_generated',
              'r_arm_AI',
              'r_arm_AI'
            )
          );
        }
        if (originCustomized.head) {
          promises.push(
            customizeAiPart(originCustomized.head, 'hat/hat_generated', 'hat_AI', 'hat_AI')
          );
        }
      }

      if (promises.length > 0) {
        Promise.all(promises).then(() => {
          coreUtils.setSlimeSpineSkin(slimeSpinePreviewRef.current, spineSkin);
        });
      } else {
        coreUtils.setSlimeSpineSkin(slimeSpinePreviewRef.current, spineSkin);
      }
    }
  }, [isSpinePlayerReady, slime]);

  const isShowLoading = useMemo(
    () => isLoading || !isSpinePlayerReady,
    [isLoading, isSpinePlayerReady]
  );

  const renderKeyUsed = useMemo(
    () => `slime-spine-preview-${renderKey || Math.random().toString()}`,
    []
  );

  useEffect(() => {
    // @ts-ignore
    new spine.SpinePlayer(renderKeyUsed, {
      jsonUrl: `${SLIME_SPINE_RESOURCE_URL}/Slime.json`,
      atlasUrl: `${SLIME_SPINE_RESOURCE_URL}/Slime.atlas`,
      showControls: false,
      skin: DefaultSkin,
      animation: 'idle',
      alpha: true,
      backgroundColor: '#00000000',
      success: (player: any) => {
        slimeSpinePreviewRef.current = player;
        setIsSpinePlayerReady(true);
      },
      error: (player: any) => {
        console.error('Load spine error');
        slimeSpinePreviewRef.current = player;
        setIsSpinePlayerReady(true);
      },
      preserveDrawingBuffer: false,
    });
  }, []);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="relative"
      width="100%"
      height="100%"
      minHeight={300}
      maxHeight={600}
    >
      <Flex
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        visibility={isShowLoading ? 'hidden' : 'visible'}
      >
        <div style={{ minHeight: 300 }} id={renderKeyUsed} />
      </Flex>
      {isShowLoading && (
        <div>
          <Spinner />
        </div>
      )}
    </Flex>
  );
};

export default SlimeSpinePreview;
