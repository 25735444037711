import { NFTMenuLabel, NFTType } from 'src/globals/constants';
import { TNFTType } from 'src/gql/types';

export interface INFTTab {
  label: string;
  nftType: TNFTType;
}

export const NFTTabs: INFTTab[] = [
  // {
  //   label: NFTMenuLabel.MINING_TOOL,
  //   nftType: NFTType.MINING_TOOL,
  // },
  // {
  //   label: NFTMenuLabel.TOOL_MATERIAL,
  //   nftType: NFTType.TOOL_MATERIAL,
  // },
  // {
  //   label: NFTMenuLabel.GENE,
  //   nftType: NFTType.GENE,
  // },
  {
    label: NFTMenuLabel.SLIME,
    nftType: NFTType.SLIME,
  },
  // {
  //   label: NFTMenuLabel.GACHA_BOX,
  //   nftType: NFTType.GACHA,
  // },
  // {
  //   label: NFTMenuLabel.LAND,
  //   nftType: NFTType.LAND,
  // },
];
