import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as accountServiceService from '../../../services/accountServices';
import MetamaskProvider from '../../../metamask-provider';
import { AuthBindingPayload, BindingType } from '../../../types/AccountServiceClient';

const useBindToAccountByWallet = (type: BindingType, walletAddress?: string) => {
  const queryClient = useQueryClient();
  const { isLoading: isBinding, mutate } = useMutation({
    mutationFn: async (payload: AuthBindingPayload) => {
      if (walletAddress) {
        const { nonce } = await accountServiceService.getWalletNonce(walletAddress);
        const signature = await MetamaskProvider.signMessage(nonce, walletAddress);
        await accountServiceService.bindByWallet({
          bindingType: type,
          walletAddress,
          signature,
          payload,
        });
      } else throw new Error('Wallet address is not provided');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['accountInformation']);
    },
  });
  return { isBinding, bind: mutate };
};

export default useBindToAccountByWallet;
